import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '../../../components/hoc';
import FactoryHeader from '$fcomponents/header';
import { TableView2, Header as FixedHeader } from '$gcomponents/widgets';
import OptionModel from '$gbusiness/models/option';
import { ORDER_STATUS } from '$fbusiness/enums/options/orderStatus';

import { ORDER_TABLE_CONFIG, ORDER_TABLE } from './ordersTable';
import { IonPageWrapper } from './styles';
import { orderActions } from '$fbusiness/redux/order';
import OrderDetailsModal from './orderDetailsModal';
import OrderModel from '$fbusiness/models/order';
import PATH from '$fbusiness/enums/paths';
import FactoryModel from '$fbusiness/models/factory';
import CurrentStateModel from '$fbusiness/models/currentState';
import { configs } from '$configs';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';
import ReportFilter from '$fcomponents/filters/reportFilter';
import { excludeTabs } from '$gcomponents/reusables/table/utils';

interface OrdersScreenProps {
  history;
  factory: FactoryModel;
  currentState: CurrentStateModel;
  order: OrderModel;
  cleanOrder: Function;
  fetchOrder: Function;
  updateOrder: Function;
  stores;
}

const OrdersScreen: React.FC<OrdersScreenProps> = ({
  history,
  factory,
  order,
  currentState,
  fetchOrder,
  updateOrder,
  cleanOrder,
  stores,
}) => {
  const [filter, setFilter] = useState<any>({ query: '', tableTabIndex: 0, forceRefresh: false });
  const [showDetails, setShowDetails] = useState(false);
  const [summary, setSummary] = useState<Array<any>>([]);
  const { settings } = factory;
  const { hidePrice, isSalesman } = currentState;

  const TTABS_TEMP: Array<OptionModel> = [
    { label: 'SCREEN.ORDERS.STATUS.ALL', value: '' },
    { label: 'SCREEN.ORDERS.STATUS.PREPARING', value: 'PREPARING' },
    ...(settings?.pickupOnly
      ? [
          { label: 'SCREEN.ORDERS.STATUS.READY', value: 'READY' },
          { label: 'SCREEN.ORDERS.STATUS.COMPLETED', value: 'COMPLETED' },
        ]
      : [
          { label: 'SCREEN.ORDERS.STATUS.DELIVERED', value: 'DELIVERED' },
          { label: 'SCREEN.ORDERS.STATUS.SHIPPED', value: 'SHIPPED' },
        ]),
  ];

  const TTABS = excludeTabs(TTABS_TEMP, 'orders');

  const updateFilter = (newFilter) => {
    setFilter({
      ...filter,
      ...newFilter,
      forceRefresh: !filter.forceRefresh,
    });
  };

  const tableTabs = {
    TABS: TTABS.map((t, i): OptionModel => {
      return {
        ...t,
        count: summary[i],
      };
    }),
    hasBadge: true,
    index: filter?.tableTabIndex,
    onChange: (newTableIndex) => {
      updateFilter({ tableTabIndex: newTableIndex, status: TTABS[newTableIndex].value });
    },
    variant: 'outlined',
    color: 'primary' as 'primary' | 'secondary',
    itemWidth: '60px',
  };

  const onDetails = async (row) => {
    await fetchOrder(row.id);
    setShowDetails(true);
  };

  const onChangeStatus = async (row, newValue) => {
    if (newValue === ORDER_STATUS.DELIVERED || newValue === ORDER_STATUS.COMPLETED) {
      await updateOrder({ id: row.id, status: newValue });
      updateFilter({});
    }
  };

  const onChangeStore = (store) => {
    updateFilter({ storeId: store?.id || '' });
  };

  const onReceiving = async (row) => {
    history.push(PATH.RECEIVING);
  };

  const onCloseModal = async () => {
    await cleanOrder();
    setShowDetails(false);
  };

  const onTableLoad = (totalSize, newLength, response) => {
    const summary: any = response?.summary;
    if (!summary) {
      setSummary([]);
      return;
    }
    setSummary(TTABS.map((tab) => Number(summary[tab.value.toString().toLowerCase()])));
  };

  const Header =
    configs.display.layout === 'FIXED_HEADER' ? (
      <FactoryHeader title="SCREEN.ORDERS.TITLE" />
    ) : (
      <FixedHeader title="SCREEN.ORDERS.TITLE" />
    );

  return (
    <IonPageWrapper>
      {Header}
      <IonContent>
        <TableView2
          filter={filter}
          onTableLoad={onTableLoad}
          tableConfig={ORDER_TABLE_CONFIG}
          TABLE={ORDER_TABLE({
            onDetails,
            onChangeStatus,
            onReceiving,
            isSalesman,
            settings: factory?.settings,
            hidePrice,
          })}
          tabs={tableTabs}>
          <FilterSection title={null}>
            <ReportFilter
              excludeDates
              stores={isSalesman ? stores : undefined}
              parentFilter={filter}
              onChangeStore={onChangeStore}
              onSearch={updateFilter}
              placeholder="INPUT.PLACEHOLDER.ORDER_SEARCH"
            />
          </FilterSection>
        </TableView2>
      </IonContent>
      <OrderDetailsModal
        currentState={currentState}
        order={order}
        factory={factory}
        show={order && showDetails}
        onClose={onCloseModal}
      />
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  resetOnRoute: true,
  order: state.order.order,
  stores: state.store.stores,
});

const mapDispatchToProps = {
  cleanOrder: orderActions.dehydrate,
  fetchOrder: orderActions.fetchOrder,
  updateOrder: orderActions.updateOrder,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(OrdersScreen));
