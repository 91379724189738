export const TOP_KEYS = {
  TOP10: 'TOP10',
  TOP20: 'TOP20',
  TOP30: 'TOP30',
};

export const BOTTOM_KEYS = {
  BOTTOM10: 'BOTTOM10',
  BOTTOM20: 'BOTTOM20',
  BOTTOM30: 'BOTTOM30',
};

const convertToOption = (item, type = 'TOP') => ({
  label: `${type}_REPORT.${item}`,
  value: item,
});

const LIST = Object.keys(TOP_KEYS);
const LIST2 = Object.keys(BOTTOM_KEYS);

export const TOP_OPTIONS = LIST.map((i) => convertToOption(i, 'TOP'));
export const BOTTOM_OPTIONS = LIST2.map((i) => convertToOption(i, 'BOTTOM'));

export default TOP_OPTIONS;
