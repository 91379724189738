export const KEYS = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETE: 'COMPLETE',
};

export const TICKET_STATUS = KEYS;

export const TICKET_STATUSES = {
  ...KEYS,
};

const convertToOption = (item) => ({
  label: `TICKET_STATUS.${item}`,
  value: item,
});

export const TIKET_LIST = Object.keys(KEYS);

export const TICKET_STATUS_OPTIONS = TIKET_LIST.map(convertToOption);
