import { mergeObjectStrict } from '$gbusiness/helpers/util';
import { getItemPrice, getTaxPercent } from '../redux/cart/utils';
import OrderInvoiceItemModel, {
  deriveRawToOrderInvoiceItem,
  initialOrderInvoiceItem,
  recalculateItemPrice,
} from './orderInvoiceItem';
import TaxModel from './tax';
import VendorModel, { deriveRawToVendor } from './vendor';

export default interface InvoiceItemModel extends OrderInvoiceItemModel {
  invoiceItemId: number;
  orderId?: number;
  taxRule?: TaxModel;
  qtyOrdered?: number;
  qtySent?: number;
  qtyReceived?: number;
  totalSent?: number;
  totalReceived?: number;
  vendor?: VendorModel;
  categoryStr: string;
  serials?: Array<string>;
  requireSerial?: boolean;
  deptStr: string;
}

export const deriveRawToInvoiceItem = (raw, isInvoice = false) => {
  return {
    ...deriveRawToOrderInvoiceItem(raw, isInvoice),
    orderId: raw.orderId,
    taxRule: raw.taxRule,
    invoiceItemId: raw.id,
    qtyOrdered: raw.qtyOrdered || raw.pricing.qty || 0,
    qtySent: raw.qtySent || 0,
    qty: raw.qty,
    qtyReceived: raw.qtyReceived || 0,
    totalSent: Number(raw.totalSent) || 0,
    totalReceived: Number(raw.totalReceived) || 0,
    serials: raw.serials || [],
    requireSerial: raw.requireSerial || false,
    categoryStr: (raw.categories || []).map((d) => d.name).join(', '),
    deptStr: (raw.departments || []).map((d) => d.name).join(', '),
    ...(raw.vendor && { vendor: deriveRawToVendor(raw.vendor) }),
  };
};

export const initialInvoiceItem: InvoiceItemModel = {
  ...initialOrderInvoiceItem,
  invoiceItemId: 0,
  qtySent: 0,
  qtyOrdered: 0,
  qtyReceived: 0,
  totalSent: 0,
  totalReceived: 0,
  categoryStr: '',
  deptStr: '',
};

export const deriveItemToInvoiceItem = (item, store: any = null) => {
  const newItem: any = mergeObjectStrict(initialInvoiceItem, item);
  const qtySent = newItem?.qtySent || newItem?.qty || 0;

  const taxRate = store ? getTaxPercent(item.taxRule, store) : 0;
  const unitPrice = store ? getItemPrice(item, store, item.invoiceItemId || item.id || 0) : 0;

  return {
    ...newItem,
    rawCost: item.rawCost,
    wholesalePrice: item.wholesalePrice,
    retailPrice: item.retailPrice,
    taxRule: item.taxRule,
    serials: item.serials || [],
    qtySent,
    requireSerial: item?.requireSerial || false,
    disableDiscount: item.disableDiscount ? true : false,
    totalSent: qtySent,
    ...(store && {
      taxRate,
      unitPrice,
    }),
  };
};

export const deriveRawItemToInvoiceItem = (item, store, dcRate = 0) => {
  const taxRate = getTaxPercent(item.tax, store);
  const unitPrice = getItemPrice(item, store);
  const disableDiscount = !!item.settings?.disableDiscount;
  const discountRate = disableDiscount ? 0 : dcRate;
  const order = {
    ...item,
    id: undefined,
    invoiceItemId: 0,
    unitPrice,
    qty: 1,
    discountRate,
    taxRate,
  };

  return mergeObjectStrict(
    {
      ...initialInvoiceItem,
      rawCost: item.rawCost,
      wholesalePrice: item.wholesalePrice,
      retailPrice: item.retailPrice,
      taxRule: item.tax,
      disableDiscount,
      requireSerial: item.settings?.requireSerial || false,
      quantity: item.quantity,
    },
    {
      ...recalculateItemPrice(order),
      qtySent: order.qty,
      totalSent: order.qty,
      barcode: item.barcode || '',
      itemName: item.name,
      itemId: item.id,
    },
  );
};
