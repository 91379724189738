export const KEYS = {
  ACCURAL: 'ACCURAL',
  CASH: 'CASH',
};

export const REPORT_TYPE = KEYS;

const convertToOption = (item) => ({
  label: `REPORT_TYPE.${item}`,
  value: item,
});

const LIST = Object.keys(KEYS);

export default LIST.map(convertToOption);

export type REPORT_TYPE_TYPES = typeof LIST[number] | undefined;
