export const TODAY = 'TODAY';
export const YESTERDAY = 'YESTERDAY';
export const THIS_WEEK = 'THIS_WEEK';
export const THIS_MONTH = 'THIS_MONTH';
export const THIS_YEAR = 'THIS_YEAR';
export const LAST_7_DAYS = 'LAST_7_DAYS';
export const LAST_30_DAYS = 'LAST_30_DAYS';
export const LAST_365_DAYS = 'LAST_365_DAYS';
export const ALL_TIME = 'ALL_TIME';
export const CUSTOM = 'CUSTOM';
