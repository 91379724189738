export const KEYS = {
  DEFAULT: 'DEFAULT',
  CUSTOM: 'CUSTOM',
};

export const INVOICE_NUMBER = KEYS;

const LIST = Object.keys(KEYS);

export default LIST.map((item) => ({
  label: `INVOICE_NO.${item}`,
  value: item,
}));

export type INVOICE_NUMBER_TYPE = typeof LIST[number] | undefined;
