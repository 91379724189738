export const KEYS = {
  PREPARING: 'PENDING',
  PROCESSED: 'PROCESSED',
  CANCELLED: 'CANCELLED',
};

export const REFUND_STATUS = KEYS;

const convertToOption = (item) => ({
  label: `REFUND_STATUS.${item}`,
  value: item,
});

const LIST = Object.keys(KEYS);

export default LIST.map(convertToOption);

export type REFUND_STATUS_TYPE = typeof LIST[number] | undefined;
