import { currency } from '$gbusiness/helpers/util';
import { Email, Phone } from '@mui/icons-material';

export const reportCurrency = (num, shouldRound = false) => {
  return <span className={num === 0 ? 'o70' : ''}>{currency(num, shouldRound)}</span>;
};

export const emailLink = (email, asString = false, includeIcon = false): any => {
  if (!email) return asString ? '' : null;
  return asString ? (
    `${includeIcon ? <Email color="primary" /> : ''} <a href=${`mailto:${email}`}>${email}</a>`
  ) : (
    <>
      {includeIcon && <Email color="primary" />}
      <a href={`mailto:${email}`}>{email}</a>
    </>
  );
};
export const phoneLink = (phone, asString = false, includeIcon = false): any => {
  if (!phone) return '';
  return asString ? (
    `${includeIcon ? <Phone color="primary" /> : ''} <a href="${`tel:${phone}`}">${phone}</a>`
  ) : (
    <>
      {includeIcon && <Phone color="primary" />}
      <a href={`tel:${phone}`}>{phone}</a>
    </>
  );
};
