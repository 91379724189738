import _ from 'lodash';
import React, { useState } from 'react';
import intl from '$gintl';
import {
  FormControlProps,
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { Field, FastField } from 'formik';
import { FormControlWrapper2, BSLabel } from './styles';

interface QuantityEditProps {
  name: string;
  defaultValue: number;
  label?: string;
  labelText?: string;
  position?: FormControlProps['variant'];
  inputSize?: TextFieldProps['size'];
  onChange?: Function;
  className?: string;
  helperText?: string;
  formik?: any;
  size?: string;
  disabled?: boolean;
  readonly?: boolean;
  placeholder?: string;
  disableFastField: boolean;
  shrink?: boolean;
  handleChange?: (e) => void;
  handleBlur?: Function;
  x?: boolean;
}

const QuantityInput: React.FC<QuantityEditProps> = ({
  inputSize = 'medium',
  defaultValue = 0,
  label = '',
  labelText,
  name,
  className,
  position,
  formik,
  size,
  disabled = false,
  readonly = false,
  placeholder,
  helperText,
  disableFastField,
  handleChange,
  shrink,
  handleBlur,
  x = true,
  ...rest
}) => {
  const defaultValue2 = _.get(formik?.values, name) || defaultValue;
  const [textMode, setTextMode] = useState(defaultValue2 > 9);
  const oOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const options = oOptions.map((i) => {
    return (
      <MenuItem value={i} key={i}>
        {i === 0 && labelText ? labelText : i === 10 ? '10+' : i}
      </MenuItem>
    );
  });

  const onChangePickerValue = (e) => {
    const val = e.target.value;
    if (val > 9) setTextMode(true);
    if (formik.handleChange) formik.handleChange(e);
    if (handleChange) handleChange(e);
  };
  const onKeyPress = (e) => {
    const charCode = e.which ? e.which : e.keyCode;
    if (charCode === 13) {
      e.preventDefault();
      formik.handleSubmit();
    }
    if (
      (e.keyCode === 65 || e.keyCode === 86 || e.keyCode === 67) &&
      (e.ctrlKey === true || e.metaKey === true)
    )
      return;
    if (
      charCode !== 46 &&
      charCode > 31 &&
      (charCode < 48 || charCode > 57) &&
      (charCode < 96 || charCode > 105)
    ) {
      e.preventDefault();
    }
    return true;
  };

  const onBlur = (e) => {
    const val = parseInt(e.target.value);
    formik.setFieldValue(name, val);
    if (val < 10) {
      setTextMode(false);
    }
  };

  const errorKey = _.get(formik.errors, name);
  const isTouched = _.get(formik.touched, name) !== undefined;
  const hasError = isTouched && errorKey !== undefined;
  const errorMsg = hasError ? intl(`INPUT.ERROR.${errorKey}`) : undefined;

  const startAdornment = null;
  const FormField = disableFastField ? Field : FastField;

  const labelProps =
    position || shrink
      ? {
          label: intl(label),
          ...(shrink && { input: <OutlinedInput name={name} label={intl(label)} notched /> }),
        }
      : {};
  const variant = position || 'outlined';

  return (
    <FormField name={name}>
      {({ form }) => {
        const value = _.get(form.values, name) || 0;
        return (
          <FormControlWrapper2 className={className} variant={variant} size={inputSize} fullWidth>
            {!textMode && value < 10 ? (
              <>
                {label && (
                  <BSLabel id={`label_${name}`} className={!position && 'bs-label'} shrink={shrink}>
                    {intl(label)}
                  </BSLabel>
                )}
                <Select
                  {...labelProps}
                  labelId={`label_${name}`}
                  name={name}
                  className="select-input"
                  disabled={disabled}
                  placeholder={intl(placeholder) || ''}
                  onChange={onChangePickerValue}
                  displayEmpty
                  value={_.get(form.values, name) || 0}>
                  {options}
                </Select>
              </>
            ) : (
              <TextField
                InputLabelProps={{ shrink: true }}
                size={inputSize}
                variant={variant}
                label={intl(label)}
                InputProps={{
                  startAdornment,
                }}
                onBlur={onBlur}
                placeholder={intl(placeholder) || ''}
                fullWidth
                name={name}
                value={value || ''}
                onInput={onChangePickerValue}
                onKeyDown={onKeyPress}
              />
            )}
            <FormHelperText>{errorMsg || helperText}</FormHelperText>
          </FormControlWrapper2>
        );
      }}
    </FormField>
  );
};

export default QuantityInput;
