import {
  CLEAN_THREAD,
  CREATE_THREAD_SUCCESS,
  DELETE_THREAD_SUCCESS,
  ThreadActionTypes,
  ThreadReducerType,
  THREAD_INIT_STATE,
  FETCH_THREAD_SUCCESS,
  FETCH_THREADS_SUCCESS,
  REPLY_THREAD_SUCCESS,
} from './types';

export default function threadReducer(
  state: ThreadReducerType = THREAD_INIT_STATE,
  action: ThreadActionTypes,
): ThreadReducerType {
  switch (action.type) {
    case REPLY_THREAD_SUCCESS:
    case FETCH_THREAD_SUCCESS:
      return {
        ...state,
        thread: action.thread,
      };
    case FETCH_THREADS_SUCCESS:
      return {
        ...state,
        threads: action.threads,
      };
    case CREATE_THREAD_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };

    case DELETE_THREAD_SUCCESS:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case CLEAN_THREAD:
      return {
        ...THREAD_INIT_STATE,
        threads: state.threads,
        refresh: state.refresh,
      };
    default:
      return state;
  }
}
