import { configs } from '$configs';
import { Select } from '@mui/material';

import { SORT, COLORS, DATE_FORMATS } from '$gbusiness/enums';
import CellModel from '$gbusiness/models/cell';
import { format } from '$gbusiness/helpers/date';
import { defaultDataSource, defaultStyles, TableModel } from '$gbusiness/models/table';

import { generateSelectOptions } from '$gcomponents/utils/input';
import { deriveRawToThread } from '$fbusiness/models/thread';
import { TICKET_STATUS_OPTIONS } from '$fbusiness/enums/options/ticketStatus';
import { Attachment } from '@mui/icons-material';
import { StyledBadge } from './styles';
import { getUnreads } from './utils';
import intl from '$gintl';

export const MESSAGE_TABLE_CONFIG = (onClick, isFactory): TableModel => ({
  dataSource: {
    ...defaultDataSource,
    endpoint: configs.api.thread.general,
    deriveToModel: deriveRawToThread,
    method: 'GET',
    defaultSortKey: 'updated_at',
    defaultSortOrder: SORT.DSC,
    shouldVirtualize: false,
    pageSize: 20,
    onClickRow: onClick,
    autoRefreshInterval: 30,
  },
  styles: {
    ...defaultStyles,
    color: COLORS.LIGHT,
    fontSize: '1.05em',
    minWidth: 300,
    maxWidth: 1100,
    rowHeight: 32,
    cellPadding: ` 8px 4px`,
    borderColor: 'var(--ion-border-color)',
    responsive: true,
    setRowClass: (row) => {
      if (getUnreads(row, isFactory) > 0) return 'unread';
      return '';
      // return row.id === 2 ? 'unread' : '';
    },
  },
  filter: {
    color: COLORS.PRIMARY,
  },
});

export const MESSAGE_TABLE = (onChangeStatus, isFactory): Array<CellModel> => [
  {
    label: 'SCREEN.MESSAGES.COLS.TICKETNO',
    value: 'id',
    component: (row) => row.ticketNumber || '',
    alwaysShow: true,
    width: 150,
  },
  {
    label: 'SCREEN.MESSAGES.COLS.STORE',
    value: 'store.name',
    component: (row) => (
      <div>
        <div>{row.store.name || ''}</div>
        {row.contact?.name && <div className="contact-name">{row.contact.name}</div>}
      </div>
    ),
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: 'SCREEN.MESSAGES.COLS.TITLE',
    value: 'title',
    alwaysShow: true,
    sortable: SORT.ASC,
    width: 300,
  },
  {
    label: 'SCREEN.MESSAGES.COLS.REPLIES',
    value: 'replies',
    align: 'center',
    component: (row) => (
      <div>
        <StyledBadge color="primary" badgeContent={getUnreads(row, isFactory)}>
          {row.replies}
        </StyledBadge>
      </div>
    ),
    className: 'desktop',
    sortable: SORT.DSC,
    width: 100,
  },
  {
    label: 'SCREEN.MESSAGES.COLS.STATUS',
    value: 'status',
    alwaysShow: true,
    component: (row) => {
      if (isFactory)
        return (
          <Select
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            variant="outlined"
            className="xsmall"
            value={row.status}
            onChange={(e) => onChangeStatus(row, e.target.value)}>
            {generateSelectOptions(TICKET_STATUS_OPTIONS, row.status)}
          </Select>
        );
      else return intl('INPUT.OPTION.TICKET_STATUS.' + row.status);
    },
    sortable: SORT.ASC,
    width: 200,
  },
  {
    label: '',
    value: 'files',
    className: 'desktop',
    component: (row) => <>{row.hasAttachment ? <Attachment style={{ color: '#999' }} /> : <></>}</>,
    width: 80,
  },
  {
    label: 'SCREEN.MESSAGES.COLS.DATE',
    value: 'updated_at',
    component: (row) => <>{format(row.updatedAt, DATE_FORMATS.DATETIME)}</>,
    sortable: SORT.DSC,
    width: 200,
  },
];
