import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import { input } from '$gbusiness/helpers';

export const REPLY_FORM = (isFactory = false) => [
  {
    items: [
      {
        input: INPUT.TEXT,
        label: 'SCREEN.CONTACT.NAME',
        value: 'name',
        size: 'small',
        validation: [input.requiredValidation],
        gridSize: 6,
      },
      {
        ...INPUT_TYPES.EMAIL,
        label: 'SCREEN.CONTACT.EMAIL',
        value: 'email',
        size: 'small',
        validation: [input.requiredValidation],
        disabled: isFactory,
        gridSize: 6,
      },
      {
        input: INPUT.TEXTAREA,
        label: 'SCREEN.CONTACT.BODY',
        value: 'body',
        size: 'small',
        validation: [input.requiredValidation],
        rows: 8,
        gridSize: 12,
      },
    ],
  },
];

export default REPLY_FORM;
