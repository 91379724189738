import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';

export const STORE_FILTER_FORM = ({
  stores,
  onChangeStore,
  options = { size: 'medium', gridSize: 6 },
}): Array<InputRowModel> => [
  {
    items: [
      {
        input: INPUT.AUTOCOMPLETE,
        label: 'SCREEN.STORES.COLS.STORE',
        disableFastField: true,
        value: 'store',
        config: {
          optionProp: {
            label: 'name',
            value: 'id',
          },
          data: stores,
        },
        onChange: ({ formValues, newValue }) => onChangeStore(formValues, newValue),
        ...options,
      },
    ],
  },
];

export default STORE_FILTER_FORM;
