import { toLocal } from '$gbusiness/helpers/date';
import DepartmentModel from './department';

export default interface CategoryModel {
  id: number;
  breadcrumbs?: Array<any>;
  name: string;
  depts?: Array<DepartmentModel>;
  deptIds?: Array<number>;
  email?: string;
  image?: any;
  rank: number;
  taxId?: boolean;
  hide?: boolean;
  collapsed?: boolean;
  parentId?: number;
  level?: number;
  children?: Array<CategoryModel>;
  createdAt?: string;
  updatedAt?: string;
}

export const deriveRawToCategory = (raw) => {
  return {
    id: raw.id,
    name: raw.name,
    ...(raw.breadcrumbs && { breadcrumbs: raw.breadcrumbs }),
    depts: (raw.departments || []).map((d) => ({ id: d.id, name: d.name })),
    deptIds: (raw.departments || []).map((d) => d.id),
    email: raw.email || '',
    image: raw.image,
    rank: raw.rank || 0,
    hide: raw.hide,
    taxId: raw.taxId || undefined,
    parentId: raw.parentId,
    collapsed: false,
    children: (raw.children || raw.categoryTree || []).map(deriveRawToCategory),
    createdAt: toLocal(raw.createdAt),
    updatedAt: toLocal(raw.updatedAt),
  };
};

export const getCategoriesFromDepts = (categories, deptIds) => {
  return categories.filter((c) => {
    if (!c.deptIds || !c.deptIds.length) return false;
    return c.deptIds.some((d) => deptIds.indexOf(d) >= 0);
  });
};

export const initialValue = {
  id: 0,
  name: '',
  rank: 1,
  email: '',
  depts: [],
  deptIds: [],
};
