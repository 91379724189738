import { configs } from '$configs';
import { DATE_FORMATS } from '$gbusiness/enums';
import { datetimeToString, dateToString, format, timestamp, today } from '$gbusiness/helpers/date';
import {
  subDays,
  startOfWeek,
  startOfMonth,
  startOfToday,
  startOfYear,
  //endOfYesterday,
  startOfYesterday,
  // addDays,
  addBusinessDays,
  addMonths,
  getDate,
  getYear,
  getMonth,
  getDaysInMonth,
  parseISO,
  addDays,
  nextDay,
  subMonths,
  //endOfToday,
} from 'date-fns';
import { TAX_TYPE } from '../enums/options/taxType';
import { PAYMENT_FREQ, KEYS } from '../enums/options/paymentFreq';

import * as PERIODS from '../enums/periods';
import DateFilterModel from '../models/dateFilter';
import { LIST as PAYMENT_METHODS } from '../enums/options/paymentType';
import { INVOICE_NUMBER } from '../enums/options/invoiceNumber';
import { INVOICE, REFUND_CREDIT } from '../enums/transactionType';
import { toNumber } from '$gbusiness/helpers/util';

export const getInvoice = (row) => {
  const type = row?.type || INVOICE;
  if (type !== INVOICE) return '';
  return row?.invoice || row;
};

export function getCommissionRate(invoice, commission) {
  if (!commission || !invoice) return 0;
  if (invoice.totalDiscount > 0) {
    const { settings } = commission;
    if (!invoice.discountRate) return toNumber(commission.discountRate);
    if (settings && settings.enableDiscount) {
      const { commission1, discountRate1, commission2, discountRate2 } = settings;
      if (!invoice.discountRate) return commission.rate;
      if (invoice.discountRate <= discountRate1) return toNumber(commission1);
      if (invoice.discountRate >= discountRate2) return toNumber(commission2);
    }
    return toNumber(commission.discountRate);
  }
  return toNumber(commission.rate);
}

export const deriveTransactionType = (row) => {
  let type = row.type;
  if (type === REFUND_CREDIT) type = 'REFUND CREDIT';
  // if (row.refund?.isCredit) type = row.type + '/ CREDIT';
  if (row.invoice?.charge) {
    type = row.invoice?.charge.description || 'CHARGE';
  }
  return type;
};

export const deriveDate = (row) => {
  return row ? format(row.date || row.createdAt, DATE_FORMATS.DATETIME) || '' : '';
};

export const getDateRangeFromPeriod = (period) => {
  switch (period) {
    case PERIODS.TODAY:
      return [startOfToday(), startOfToday()];
    case PERIODS.YESTERDAY:
      return [startOfYesterday(), startOfYesterday()];
    case PERIODS.LAST_7_DAYS:
      const last7 = subDays(startOfToday(), 7);
      return [last7, null];
    case PERIODS.LAST_30_DAYS:
      const last30 = subDays(startOfToday(), 30);
      return [last30, null];
    case PERIODS.LAST_365_DAYS:
      const last365 = subDays(startOfToday(), 365);
      return [last365, null];
    case PERIODS.THIS_WEEK:
      return [startOfWeek(startOfToday()), null];
    case PERIODS.THIS_MONTH:
      return [startOfMonth(startOfToday()), null];
    case PERIODS.THIS_YEAR:
      return [startOfYear(startOfToday()), null];
    case PERIODS.ALL_TIME:
      return [null, null];
    default:
      return [null, null];
  }
};

export const getDeliveryDate = (days, format = DATE_FORMATS.DATE) => {
  return datetimeToString(addBusinessDays(new Date(), days || 3), format);
};

export const getFactoryLogo = (factory, defer = false) => {
  const logoPath = factory?.logo || configs.display.logoPath;
  if (defer && !factory.factoryId) return undefined;
  return `${logoPath}?${timestamp(factory?.updatedAt)}`;
};

export const deriveDefaultDateFilter = (rangeKey = PERIODS.LAST_7_DAYS) => {
  const [startDate, endDate] = getDateRangeFromPeriod(rangeKey);

  return {
    datePeriod: rangeKey,
    dateStart: datetimeToString(startDate, DATE_FORMATS.DATE),
    dateEnd: datetimeToString(endDate, DATE_FORMATS.DATE),
  };
};

export const initialDateFilter: DateFilterModel = {
  ...deriveDefaultDateFilter(PERIODS.LAST_30_DAYS),
};

export const updatedFilter = (filter, newFilter = {}) => {
  return {
    ...filter,
    ...newFilter,
    forceRefresh: !filter.forceRefresh,
  };
};

export const getTaxRate = (store, factory) => {
  const { taxes } = factory;
  const mainTax = taxes.length ? taxes[0] : null;
  const storeTax = store?.settings?.taxPercent || 0;
  if (mainTax?.taxType === TAX_TYPE.UNIVERSAL) return Number(mainTax.amount) || 0;
  return Number(storeTax) || 0;
};

export const subPeriodLag = (factory, periodDate, customLag: any = null) => {
  const { settings } = factory;
  const { commPay } = settings;
  if (!commPay) return null;
  const { freq, lag: factoryLag } = commPay;
  const actualFactoryLag = freq === KEYS.SEMI_MONTHLY ? factoryLag * 2 : factoryLag;
  const lag = customLag !== null ? customLag : actualFactoryLag || 4;

  const period = parseISO(periodDate);
  let avgPeriodDays;

  if (freq === PAYMENT_FREQ.SEMI_MONTHLY) {
    avgPeriodDays = Math.floor(365 / 24);
  } else if (freq === PAYMENT_FREQ.MONTHLY) {
    avgPeriodDays = Math.floor(365 / 12);
  } else if (freq === PAYMENT_FREQ.WEEKLY) {
    avgPeriodDays = 7;
  }
  let medianLaggedDate = period;
  if (lag > 0) medianLaggedDate = subDays(period, avgPeriodDays * (lag - 0) + Math.ceil(avgPeriodDays / 2));
  if (lag < 0)
    medianLaggedDate = addDays(period, avgPeriodDays * ((lag + 0) * -1) + Math.ceil(avgPeriodDays / 2));
  return getCurrentPeriodDate(factory, medianLaggedDate);
};

export const getPeriodReportRange = (factory, date = today()) => {
  const { settings } = factory;
  const { commPay } = settings;
  if (!commPay) return [null, null];
  const { lag: temp, freq } = commPay;
  const lag = freq === KEYS.SEMI_MONTHLY ? temp * 2 : temp;
  const periodDate = getCurrentPeriodDate(factory, parseISO(date));
  if (!periodDate) return [null, null];

  const endDate: any = subPeriodLag(factory, periodDate, lag);
  const startDate: any = subPeriodLag(factory, periodDate, lag + 1);
  return [dateToString(addDays(parseISO(startDate), 1)), endDate];
};

export const getClawbackRange = (factory, date = today()) => {
  const { settings } = factory;
  const { commPay } = settings;
  if (!commPay) return [null, null];
  const { commissionLag: temp, freq } = commPay;
  const lag = freq === KEYS.SEMI_MONTHLY ? (temp || 12) * 2 : temp || 12;
  const periodDate = getCurrentPeriodDate(factory, parseISO(date));
  if (!periodDate) return [null, null];

  const endDate: any = subPeriodLag(factory, periodDate, lag);
  const startDate: any = subPeriodLag(factory, periodDate, lag + 1);
  return [dateToString(addDays(parseISO(startDate), 1)), endDate];
};

export const getCurrentPeriodDate = (factory, now = new Date(), getPayDay = false) => {
  const { settings } = factory;
  const { commPay } = settings;
  if (!commPay) return null;
  const { freq, startDay, payDay, payDay2 } = commPay;
  let year, month, day;

  const currentYear = getYear(now);
  const currentDay = getDate(now);
  const currentMonth = getMonth(now) + 1;
  const currentMonthDays = getDaysInMonth(now);

  if (freq === PAYMENT_FREQ.SEMI_MONTHLY) {
    const { endDay: temp } = commPay;
    const endDay = parseInt(temp);
    if (endDay === 0) {
      day = currentDay > startDay ? getDaysInMonth(now) : startDay;
      month = currentMonth;
      year = currentYear;
    } else if (currentDay > endDay) {
      day = startDay;
      month = getMonth(addMonths(now, 1)) + 1;
      year = getYear(addMonths(now, 1));
    } else {
      month = currentMonth;
      year = currentYear;
      day = currentDay > startDay ? endDay : startDay;
    }
    if (getPayDay) {
      if (endDay && currentDay > endDay) {
        day = payDay;
        if (payDay > endDay) {
          month = currentMonth;
          year = currentYear;
        }
      } else {
        day = currentDay > startDay ? payDay2 : payDay;
        if (endDay && payDay > endDay) {
          month = getMonth(subMonths(now, 1)) + 1;
          year = getYear(subMonths(now, 1));
        }
      }
    }
    day = Math.min(day, currentMonthDays);
    return dateToString(new Date(year, month - 1, day));
  } else if (freq === PAYMENT_FREQ.MONTHLY) {
    const { endDay: temp } = commPay;
    const endDay = parseInt(temp);

    const pastStartDay = currentDay > startDay;
    const nextMonth = addMonths(now, 1);

    month = pastStartDay ? getMonth(nextMonth) + 1 : currentMonth;
    year = pastStartDay ? getYear(nextMonth) : currentYear;
    day = Math.min(getDaysInMonth(pastStartDay ? nextMonth : now), startDay);
    if (getPayDay) {
      day = payDay;
      month = payDay > endDay ? getMonth(now) + 1 : currentMonth;
      year = payDay > endDay ? getYear(now) : currentYear;
    }
    return dateToString(new Date(year, month - 1, day));
  } else if (freq === PAYMENT_FREQ.WEEKLY) {
    return dateToString(nextDay(subDays(now, 1), startDay));
  }
  return null;
};

export const getFuturePeriod = (factory) => {
  const { settings } = factory;
  const { commPay } = settings;
  if (!commPay) return [null, null];
  const { lag, freq } = commPay;
  const customLag = freq === PAYMENT_FREQ.SEMI_MONTHLY ? lag * 2 * -1 : lag * -1;

  const futurePeriod = subPeriodLag(factory, today(), customLag || 0);
  return futurePeriod ? futurePeriod : today();
};

export const generatePeriodDates = (factory, records: any = [], numPeriods = 10) => {
  const todayDate = today();

  const futurePeriod = getFuturePeriod(factory);
  const currentPeriod: any = futurePeriod; // getCurrentPeriodDate(factory, addMonths(new Date(), 2)) || '';
  const record = records.find(
    (r) => currentPeriod && r.periodDate >= currentPeriod && r.periodStartDate <= currentPeriod,
  );
  const periods: Array<any> =
    currentPeriod !== record?.periodDate
      ? [
          {
            period: currentPeriod,
            id: 0,
            isFuture: currentPeriod > todayDate,
            range: getPeriodReportRange(factory, currentPeriod),
            payDate: getCurrentPeriodDate(factory, parseISO(currentPeriod), true),
          },
        ]
      : [
          {
            period: record.periodDate,
            id: record.id,
            isFuture: record.periodDate > todayDate,
            range: [record.reportStartDate, record.reportEndDate],
            payDate: record.payDate,
          },
        ];

  for (let i = 1; i < numPeriods; i++) {
    const newPeriod = subPeriodLag(factory, currentPeriod, i);
    const record = records.find(
      (r) => newPeriod && r.periodDate >= newPeriod && r.periodStartDate <= newPeriod,
    );
    if (newPeriod && newPeriod !== record?.periodDate)
      if (periods.find((p) => p.period !== newPeriod)) {
        periods.push({
          period: newPeriod,
          id: 0,
          isFuture: newPeriod > todayDate,
          range: getPeriodReportRange(factory, newPeriod),
          payDate: getCurrentPeriodDate(factory, parseISO(newPeriod), true),
        });
      }
    if (record && !periods.find((p) => p.id === record.id))
      periods.push({
        period: record.periodDate,
        id: record.id,
        isFuture: record.periodDate > todayDate,
        range: [record.reportStartDate, record.reportEndDate],
        payDate: record.payDate,
      });
  }
  return periods;
};

export const getPeriodRange = (factory, period) => {
  if (!period) return [null, null];
  const { settings } = factory;
  const { commPay } = settings;
  if (!commPay) return [null, null];
  const periodDate = getCurrentPeriodDate(factory, parseISO(period));
  if (!periodDate) return [null, null];

  const startDate: any = subPeriodLag(factory, periodDate, 1);
  return [dateToString(addDays(parseISO(startDate), 1)), periodDate];
};

export const getPayDate = (factory, myDate = today()) => {
  if (!myDate) return null;
  const dateTime = parseISO(myDate);
  if (!dateTime) return null;
  return getCurrentPeriodDate(factory, dateTime, true);
};

export const getSalesman = (row) => {
  const inv = getInvoice(row);
  if (inv?.order?.isSalesman) return inv.order.userName;
  return inv?.user?.other?.isSalesman ? inv.user.name || '' : '';
};

export const getPaymentMethod = (payments) => {
  return (payments || []).reduce((acc, p) => {
    if (!PAYMENT_METHODS.includes(p.type) || acc.includes(p.type)) return acc;
    acc.push(p.type);
    return acc;
  }, []);
};

export const generateRandomOrderId = () => {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const letter = letters.charAt(Math.floor(Math.random() * letters.length));
  const number = Math.floor(Math.random() * 9999);
  return `${letter}${number}`;
};

export const generateInvoiceNo = (param, revision = '', invoiceStarting = '') => {
  const { storeId, orderId, factory } = param;
  const nextInvoiceNo = invoiceStarting || factory.settings?.invoiceStarting;

  if (factory?.settings?.invoiceStyle === INVOICE_NUMBER.CUSTOM) {
    const invoiceNo = `${factory.settings?.invoiceSuffix}-${nextInvoiceNo}`;
    return invoiceNo;
  }

  const datePart = today('yyMMdd');
  const revisionPart = revision ? `-${revision}` : '';
  return `${storeId}-${orderId || generateRandomOrderId()}-${datePart}${revisionPart}`;
};
