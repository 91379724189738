import CurrentStateModel from '$fbusiness/models/currentState';
import { cartActions } from '$fbusiness/redux/cart';
import { itemActions } from '$fbusiness/redux/item';
import { ItemDetailsModal } from '$fcomponents';
import { menuController } from '@ionic/core/components';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface ItemListContainerProps {
  children: any;
  currentState?: CurrentStateModel;
}

const ItemListContainer: React.FC<ItemListContainerProps> = ({ currentState, children }) => {
  const dispatch = useDispatch();
  const item = useSelector((state: any) => state.item.item);

  // useEffect(() => {
  //   onViewItem(213);
  // }, []);

  const onAddCart = async (selectedItem, qty = 1) => {
    menuController.toggle('end');
    console.log('ADD CART', selectedItem, qty);
    const { rawCost, retailPrice } = selectedItem;
    dispatch(
      cartActions.addProduct(
        {
          ...selectedItem,
          price: currentState?.isWholesale ? rawCost : retailPrice,
        },
        qty,
      ),
    );
    onCloseItem();
  };
  const onViewItem = (itemId) => {
    dispatch(itemActions.fetchItem(itemId));
  };
  const onCloseItem = () => {
    dispatch(itemActions.dehydrate());
  };
  return (
    <>
      {children(onAddCart, onViewItem, currentState?.isWholesale || false)}
      <ItemDetailsModal
        currentState={currentState}
        item={item}
        show={!!item}
        onClose={onCloseItem}
        onAddCart={onAddCart}
      />
    </>
  );
};

export default ItemListContainer;
