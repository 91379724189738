import { toLocal } from '$gbusiness/helpers/date';
import { deriveRawToPrice, PriceModel } from './price';

export default interface OrderInvoice extends PriceModel {
  id: number;
  storeId: number;
  refundId?: number;
  userId: number;
  storeName: string;
  transactionId?: number;
  quantity?: any;
  outOfStock?: boolean;
  qty: number;
  status: string;
  userName: string;
  note: string;
  isSalesman?: boolean;
  createdAt?: string;
  updatedAt?: string;
}

export const deriveRawToOrderInvoice = (raw) => {
  if (!raw) return null;

  return {
    id: raw.id,
    ...(raw.refundId && { refundId: raw.refundId }),
    ...(raw.pricing && deriveRawToPrice(raw.pricing)),
    storeId: raw.storeId,
    userId: raw.userId,
    quantity: raw.quantity,
    outOfStock: raw.quantity !== null && raw.quantity <= 0 ? true : false,
    storeName: raw.storeName,
    status: raw.status,
    note: raw.note,
    userName: raw.userName,
    isSalesman: raw.isSalesman,
    createdAt: toLocal(raw.createdAt),
    updatedAt: toLocal(raw.updatedAt || raw.createdAt),
  };
};
