import { toLocal } from '$gbusiness/helpers/date';
import ItemModel, { deriveRawToItem } from './item';
import UserModel from './user';
import { deriveRawToUser } from './user';

export default interface HistoryModel {
  id: number;
  item?: ItemModel;
  title: string;
  content: string;
  settings?: any;
  status: string;
  author: UserModel;
  createdAt?: string;
}

export const deriveContent = (raw) => {
  const poundObjects = ['Batch', 'Serial'];
  if (poundObjects.find((o) => raw.title.includes(o))) return '#' + raw.content || '';
  return raw.content || '';
};

export const deriveRawToHistory = (raw) => {
  return {
    id: raw.id,
    title: raw.title || '',
    content: deriveContent(raw),
    status: raw.status || '',
    ...(raw.item && { item: deriveRawToItem(raw.item) }),
    ...(raw.settings && { settings: raw.settings }),
    ...(raw.author && { author: deriveRawToUser(raw.author) }),
    createdAt: toLocal(raw.createdAt),
  };
};
