import React from 'react';
import intl from '$intl';
import { OrderSummaryWrapper } from './styles';
import { currency } from '$gbusiness/helpers/util';

interface InvoicePaymentSummaryProps {
  invoicePayment: any;
  refundCredit?: number;
}

const InvoicePaymentSummary: React.FC<InvoicePaymentSummaryProps> = ({ invoicePayment, refundCredit }) => {
  const { balance, creditAmount, paidAmount, paymentDiscount, totals } = invoicePayment;
  const finalBalance = totals?.finalBalance || balance;
  return (
    <OrderSummaryWrapper>
      <div className="box">
        {/* <div className="line">
          <div className="label">{intl('SCREEN.INVOICES.COLS.TOTAL')}</div>
          <div className="value">{currency(total || 0)}</div>
        </div> */}
        {paidAmount !== undefined && (
          <div className="line">
            <div className="label">{intl('SCREEN.INVOICES.COLS.PAID_AMOUNT')}</div>
            <div className="value">-{currency(paidAmount || 0)}</div>
          </div>
        )}
        {paymentDiscount !== undefined && (
          <div className="line">
            <div className="label">{intl('SCREEN.INVOICE_PAYMENT.DISCOUNT_AMOUNT')}</div>
            <div className="value">-{currency(paymentDiscount || 0)}</div>
          </div>
        )}
        {creditAmount !== undefined && (
          <div className="line">
            <div className="label">{intl('SCREEN.INVOICE_PAYMENT.CREDIT_AMOUNT')}</div>
            <div className="value">-{currency(creditAmount || 0)}</div>
          </div>
        )}
        {refundCredit !== undefined && (
          <div className="line">
            <div className="label">{intl('SCREEN.ORDER.SUMMARY.REFUND_CREDITS')}</div>
            <div className="value">-{currency(refundCredit || 0)}</div>
          </div>
        )}
        <div className="line total">
          <div className="label">{intl('SCREEN.INVOICES.COLS.BALANCE')}</div>
          <div className="value">{currency(finalBalance || 0)}</div>
        </div>
      </div>
    </OrderSummaryWrapper>
  );
};

export default InvoicePaymentSummary;
