import React from 'react';

import intl from '$intl';
import { ScreenTitle } from '$styles/general';
import { SPACE, WEIGHT } from '$gstyles';
import { useHistory } from 'react-router';
import { ArrowBack } from '@mui/icons-material';
import { Flex } from '$gstyles/wrapper';

interface HeaderProps {
  title?: string;
  titleParam?: object;
  titleText?: string;
  [x: string]: any;
  backRoute?: string;
}

const Header: React.FC<HeaderProps> = ({ title, titleText, backRoute, titleParam = {}, ...rest }) => {
  const history = useHistory();
  const derivedTitle = intl(title, titleParam) || titleText || '';
  const titleComponent = derivedTitle || intl('COMMON.APP_TITLE');

  const onClickBack = () => {
    if (history.action !== 'POP') {
      history.goBack();
      return;
    }
    history.push(backRoute || '');
  };

  return (
    <Flex>
      {backRoute && <ArrowBack onClick={onClickBack} />}
      <ScreenTitle
        padding={SPACE.LARGE}
        fontSize="24px"
        textTransform="uppercase"
        fontWeight={WEIGHT.SEMI_BOLD}
        {...rest}>
        {titleComponent}
      </ScreenTitle>
    </Flex>
  );
};

export default Header;
