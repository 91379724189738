import { TICKET_TITLE_OPTIONS } from '$fbusiness/enums/options/ticketTitle';
import { INPUT, INPUT_TYPES } from '$gbusiness/enums';
import { input } from '$gbusiness/helpers';

export const NEW_MESSAGE_FORM = (stores, isFactory = false) => {
  return [
    {
      items: [
        ...(isFactory
          ? [
              {
                input: INPUT.AUTOCOMPLETE,
                label: 'SCREEN.STORES.COLS.STORE',
                disableFastField: true,
                value: 'store',
                require: true,
                size: 'small',
                className: 'store-selector',
                config: {
                  optionProp: {
                    label: 'name',
                    value: 'id',
                  },
                  data: stores,
                },
                onChange: ({ formValues, setFieldValue, newValue }) => {
                  setFieldValue('name', newValue?.name || '');
                  setFieldValue('email', newValue?.email || '');
                  setFieldValue('phone', newValue?.phone || '');
                },
              },
            ]
          : []),
        {
          input: INPUT.TEXT,
          label: 'SCREEN.CONTACT.NAME',
          value: 'name',
          size: 'small',
          validation: [input.requiredValidation],
          gridSize: 12,
        },
        {
          ...INPUT_TYPES.EMAIL,
          label: 'SCREEN.CONTACT.EMAIL',
          value: 'email',
          size: 'small',
          validation: [input.requiredValidation],
          gridSize: 12,
        },
        {
          ...INPUT_TYPES.PHONE,
          label: 'SCREEN.CONTACT.PHONE',
          value: 'phone',
          size: 'small',
          validation: [input.requiredValidation],
          gridSize: 12,
        },
        {
          input: INPUT.SELECT,
          label: 'SCREEN.CONTACT.SUBJECT',
          value: 'title',
          size: 'small',
          options: TICKET_TITLE_OPTIONS,
          validation: [input.requiredValidation],
          gridSize: 12,
        },
        {
          input: INPUT.TEXTAREA,
          label: 'SCREEN.CONTACT.BODY',
          value: 'body',
          size: 'small',
          validation: [input.requiredValidation],
          rows: 8,
          gridSize: 12,
        },
      ],
    },
  ];
};

export default NEW_MESSAGE_FORM;
