import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import intl from '$intl';
import { IonContent } from '@ionic/react';
import { Formik } from 'formik';

import { PageWrapper } from '$gstyles/wrapper';
import { Button } from '$gcomponents/primitives';
import { FormSection } from '$gcomponents/reusables';
import { input } from '$gbusiness/helpers';
import Footer from '$gcomponents/widgets/footer';
import { storeActions } from '$fbusiness/redux/store';
import { deriveDatetime } from '$gbusiness/helpers/date';
import StoreModel from '$fbusiness/models/store';

import { CREDIT_FORM } from './creditForm';
import { toNumber } from '$gbusiness/helpers/util';
import RefundModel from '$fbusiness/models/refund';

interface CreditDetailsProps {
  onSaved: Function;
  store: StoreModel;
  credit: RefundModel;
}

const CreditDetails: React.FC<CreditDetailsProps> = ({ credit, store, onSaved }) => {
  const dispatch = useDispatch();
  const [initialCredit] = useState(credit);

  const creditForm = CREDIT_FORM(initialCredit);

  const onSubmit = async (values) => {
    const { total, balance, memo: note, creditDate } = values;
    const param = {
      total: toNumber(total),
      balance: toNumber(balance),
      note,
      refundDate: deriveDatetime(creditDate),
      storeId: store.id,
      isCredit: 1,
      simple: 1,
      id: credit.id || 0,
    };
    // console.log('PARAM', param);
    // return;
    await dispatch(storeActions.createCredit(param));
    onSaved();
  };

  const validateForm = (values) => input.validateError(creditForm, values);

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...credit,
        creditDate: new Date(),
      }}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <PageWrapper>
              <FormSection FORM={creditForm} formik={formik} />
            </PageWrapper>
          </IonContent>
          <Footer justifyContent="center">
            <Button
              className="half"
              onClick={formik.handleSubmit}
              disabled={!(formik.isValid && formik.dirty)}>
              {intl('BUTTON.SUBMIT')}
            </Button>
          </Footer>
        </>
      )}
    </Formik>
  );
};

export default CreditDetails;
