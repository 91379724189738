export const KEYS = {
  PREPARING: 'PREPARING',
  READY: 'READY',
  COMPLETED: 'COMPLETED',
  SHIPPED: 'SHIPPED',
  FLAGGED: 'FLAGGED',
  CLOSED: 'CLOSED',
  CANCELLED: 'CANCELLED',
  DELIVERED: 'DELIVERED',
  RESHIP: 'RESHIP',
};

export const ORDER_STATUS = KEYS;

const convertToOption = (item) => ({
  label: `ORDER_STATUS.${item}`,
  value: item,
});

export const DELIVERED_KEYS = [KEYS.SHIPPED, KEYS.DELIVERED, KEYS.COMPLETED];
export const DELIVERED_OPTIONS = DELIVERED_KEYS.map(convertToOption);

export const DELIVERED_KEYS2 = [KEYS.READY, KEYS.DELIVERED, KEYS.COMPLETED];
export const DELIVERED_OPTIONS2 = DELIVERED_KEYS2.map(convertToOption);

export const PREPARING_KEYS = [KEYS.PREPARING, KEYS.SHIPPED, KEYS.CANCELLED];
export const PREPARING_OPTIONS = PREPARING_KEYS.map(convertToOption);

export const PREPARING_KEYS2 = [KEYS.PREPARING, KEYS.READY, KEYS.COMPLETED, KEYS.CANCELLED];
export const PREPARING_OPTIONS2 = PREPARING_KEYS2.map(convertToOption);

export const FLAGGED_KEYS = [KEYS.FLAGGED, KEYS.SHIPPED, KEYS.CLOSED, KEYS.CANCELLED];
export const FLAGGED_OPTIONS = FLAGGED_KEYS.map(convertToOption);

export const SHIPPED_KEYS = [KEYS.SHIPPED, KEYS.DELIVERED, KEYS.COMPLETED, KEYS.CANCELLED];
export const SHIPPED_OPTIONS = SHIPPED_KEYS.map(convertToOption);

export const CLOSED_KEYS = [KEYS.CLOSED, KEYS.CANCELLED];
export const CLOSED_OPTIONS = CLOSED_KEYS.map(convertToOption);

const LIST = Object.keys(KEYS);

export default LIST.map(convertToOption);

export type ORDER_STATUS_TYPE = typeof LIST[number] | undefined;
