import { combineReducers } from 'redux';

import { loadingReducer } from '$gbusiness/redux/loading';
import { routerReducer } from '$gbusiness/redux/router';
import { cuserReducer } from '$gbusiness/redux/currentUser';
import { userReducer } from '$fbusiness/redux/user';
import { toasterReducer } from '$gbusiness/redux/toaster';
import { localStorageReducer } from '$gbusiness/redux/localStorage';
import { factoryReducer } from '$fbusiness/redux/factory';
import { departmentReducer } from '$fbusiness/redux/department';
import { categoryReducer } from '$fbusiness/redux/category';
import { cartReducer } from '$fbusiness/redux/cart';
import { itemReducer } from '$fbusiness/redux/item';
import { orderReducer } from '$fbusiness/redux/order';
import { invoiceReducer } from '$fbusiness/redux/invoice';
import { storeReducer } from '$fbusiness/redux/store';
import { threadReducer } from '$fbusiness/redux/thread';

import { initReducer } from './init';

const appReducer = combineReducers({
  init: initReducer,
  loading: loadingReducer,
  localStorage: localStorageReducer,
  routing: routerReducer,
  currentUser: cuserReducer,
  user: userReducer,
  toaster: toasterReducer,
  factory: factoryReducer,
  department: departmentReducer,
  category: categoryReducer,
  cart: cartReducer,
  item: itemReducer,
  order: orderReducer,
  invoice: invoiceReducer,
  store: storeReducer,
  thread: threadReducer,
});

export default appReducer;
