import React from 'react';

import { Modal } from '$gcomponents/reusables';
import DailyReportItemModel from '$fbusiness/models/dailyReportItem';
import DailyReportItemDetails from './dailyReportItemDetails';
import { itemText } from '$gbusiness/helpers/util';
import StoreModel from '$fbusiness/models/store';
import OptionModel from '$gbusiness/models/option';

interface DailyReportItemModalProps {
  show: boolean;
  onClose: Function;
  onSaveItem: Function;
  item: DailyReportItemModel;
  regionOptions: Array<OptionModel>;
  storeOptions: Array<OptionModel>;
  store: StoreModel;
}

const DailyReportItemModal: React.FC<DailyReportItemModalProps> = ({
  item,
  show,
  regionOptions,
  storeOptions,
  store,
  onSaveItem,
  onClose,
}) => {
  const titleText = item?.id ? itemText('EDIT', 'DAILY_REPORT') : itemText('ADD', 'DAILY_REPORT');
  return (
    <Modal titleText={titleText} show={show} onClose={() => onClose()} useCustom>
      {item && (
        <DailyReportItemDetails
          storeOptions={storeOptions}
          regionOptions={regionOptions}
          item={item}
          store={store}
          onClose={onClose}
          onSaveItem={onSaveItem}
        />
      )}
    </Modal>
  );
};

export default DailyReportItemModal;
