import UserModel from '$gbusiness/models/user';

import { SET_CUSER, CLEAN_CUSER, CUSER_INIT_STATE } from './types';
import { LOGIN_SUCCESS, REGISTER_SUCCESS } from '../auth/types';

export default function currentUserReducer(state: UserModel = CUSER_INIT_STATE, action): UserModel {
  switch (action.type) {
    case SET_CUSER:
      document.body.classList.remove('access_2', 'access_1', 'access_3');
      document.body.classList.add('access_' + (action?.user?.userLevel || ''));
      return action.user;
    case CLEAN_CUSER:
      document.body.classList.remove('access_' + (action?.user?.userLevel || ''));
      return CUSER_INIT_STATE;
    case REGISTER_SUCCESS:
    case LOGIN_SUCCESS:
      document.body.classList.remove('access_2', 'access_1', 'access_3');
      document.body.classList.add('access_' + (action?.user?.userLevel || ''));
      return action.user;
    default:
      return state;
  }
}
