import ThreadModel from '../../models/thread';

export const THREAD_FAILURE = 'THREAD_FAILURE';
export const FETCH_THREAD_SUCCESS = 'FETCH_THREAD_SUCCESS';
export const FETCH_THREADS_SUCCESS = 'FETCH_THREADS_SUCCESS';
export const CREATE_THREAD_SUCCESS = 'SAVE_THREAD_SUCCESS';
export const REPLY_THREAD_SUCCESS = 'REPLY_THREAD_SUCCESS';
export const DELETE_THREAD_SUCCESS = 'DELETE_THREAD_SUCCESS';
export const CLEAN_THREAD = 'CLEAN_THREAD';

// Action Types
export interface typeOnlyType {
  type: typeof CREATE_THREAD_SUCCESS | typeof CLEAN_THREAD | typeof DELETE_THREAD_SUCCESS;
}

export interface failureType {
  type: typeof THREAD_FAILURE;
  err: string;
}

export interface FetchThreadSuccessType {
  type: typeof FETCH_THREAD_SUCCESS | typeof REPLY_THREAD_SUCCESS;
  thread: ThreadModel;
}

export interface FetchThreadsSuccessType {
  type: typeof FETCH_THREADS_SUCCESS;
  threads: Array<ThreadModel>;
}

export interface ThreadReducerType {
  threads: Array<ThreadModel>;
  thread: ThreadModel | null;
  isFinished: boolean;
  refresh: boolean;
}

export type ThreadActionTypes = typeOnlyType | failureType | FetchThreadSuccessType | FetchThreadsSuccessType;

export const THREAD_INIT_STATE: ThreadReducerType = {
  threads: [],
  thread: null,
  isFinished: false,
  refresh: true,
};
