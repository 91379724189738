import React from 'react';
import intl from '$intl';

import { Wrapper } from './styles';
import { Link } from '$gcomponents/primitives';
import { useSelector } from 'react-redux';
import PATH from '$fbusiness/enums/paths';

interface ComponentProps {
  children: React.ReactNode;
  footer?: React.ReactNode;
  [x: string]: any;
}

const IonContentWrapper: React.FC<ComponentProps> = ({ children, footer, ...rest }) => {
  const user: any = useSelector((state: any) => state.currentUser);

  const isGuestUser = user?.firstName === 'Guest' && user?.userLevel === 2;

  const defaultFooter = (
    <div className="footer-content">
      <Link route={PATH.TOS}>{intl('COMMON.TOS')}</Link>
      {!isGuestUser && <Link route={PATH.CONTACT_US}>{intl('SCREEN.CONTACT.TITLE')}</Link>}
    </div>
  );
  return (
    <Wrapper {...rest} className="">
      {children}
      {footer || defaultFooter}
    </Wrapper>
  );
};

export default IonContentWrapper;
