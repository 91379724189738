export const PATH = {
  LOGIN: '/login',
  FORGOT: '/forgot',
  REGISTER: '/register',
  REGISTER_COMPLETE: '/register-complete',
  RESET_PASSWORD: '/resetpassword',
  USERS: '/users',
  VERIFY_EMAIL: '/verify-email',
  VERIFY_TOKEN: '/token',
  EMPTY: '',
};

export default PATH;
