import intl from '$gintl';

const loader = (params) => {
  const loading = document.createElement('ion-loading');
  loading.message = intl(params.message, params.key) || intl('ERROR.UNKNOWN');
  loading.cssClass = params.cssClass || '';

  const rootEl = document.getElementById('root-app');

  if (rootEl) {
    rootEl.appendChild(loading);
    setTimeout(() => {
      loading.present();
    }, 30);
    return loading;
  }
};

export default loader;
