export const KEYS = {
  CUSTOM: 'CUSTOM',
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  THIS_WEEK: 'THIS_WEEK',
  THIS_MONTH: 'THIS_MONTH',
  THIS_YEAR: 'THIS_YEAR',
  LAST_7_DAYS: 'LAST_7_DAYS',
  LAST_30_DAYS: 'LAST_30_DAYS',
  LAST_365_DAYS: 'LAST_365_DAYS',
  ALL_TIME: 'ALL_TIME',
};

export const DATE_RANGE = KEYS;

const LIST = Object.keys(KEYS);

export default LIST.map((item) => ({
  label: `DATE_RANGE.${item}`,
  value: item,
}));

export type DATE_RANGE_TYPE = typeof LIST[number] | undefined;
