import React from 'react';
import CategoryModel from '$fbusiness/models/category';
import { IonItem, IonLabel, IonList, IonMenuToggle } from '@ionic/react';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { Div } from '$gstyles';
import PATH from '$business/enums/paths';

interface CategoryMenuProps {
  categories: Array<CategoryModel>;
  currentRoute: string;
  disableToggle?: boolean;
}

const CategoryMenu: React.FC<CategoryMenuProps> = ({ categories, disableToggle, currentRoute }) => {
  const ToggleWrapper = disableToggle ? Div : IonMenuToggle;

  const renderItem = (cat, i, level = 0, isSelected = false, isAccordion = false) => {
    const rootClass = level === 0 ? 'root-category' : '';
    return (
      <ToggleWrapper
        {...(isAccordion && { onClick: (e) => e.stopPropagation() })}
        key={i}
        autoHide={false}
        className={`cat-item ${rootClass}`}>
        <IonItem
          className={isSelected ? 'selected pointer' : 'pointer'}
          routerLink={`${PATH.MENU}/${cat.id}`}
          routerDirection="none"
          lines="none"
          detail={false}>
          <IonLabel>{cat.name}</IonLabel>
        </IonItem>
      </ToggleWrapper>
    );
  };
  return (
    <IonList>
      {categories
        .filter((c) => !c.hide)
        .map((cat, index) => {
          const hasChildren = cat.children?.length;
          const isSelected = `${PATH.MENU}/${cat.id}` === currentRoute;
          const childrenEl = cat.children?.map((subcat, j) =>
            renderItem(subcat, j, 1, `${PATH.MENU}/${subcat.id}` === currentRoute),
          );
          return hasChildren ? (
            <Accordion key={index} className={`outer ${isSelected && 'selected'}`} defaultExpanded>
              <AccordionSummary onClick={() => {}} expandIcon={<ExpandMore className="expand-icon" />}>
                {renderItem(cat, index, 0, isSelected, true)}
              </AccordionSummary>
              <AccordionDetails>{childrenEl}</AccordionDetails>
            </Accordion>
          ) : (
            <div key={index} className="outer">
              {renderItem(cat, index, 0, isSelected)}
            </div>
          );
        })}
    </IonList>
  );
};

export default CategoryMenu;
