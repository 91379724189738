import React from 'react';
import { connect } from 'react-redux';
import { IonContent, IonSplitPane } from '@ionic/react';
import { Redirect, Route } from 'react-router-dom';

import { authActions } from '$gbusiness/redux/auth';
import { ROUTES } from '$business/enums';

import { configs } from '$configs';
import Menu from '../../sideMenu';

import AppOutlet from '$product/_init/appOutlet';
import CategoryModel from '$fbusiness/models/category';
import { ORDER_LIST_ROUTES } from '$business/enums/routes';
import MainHeader from '../../mainHeader';
import FactoryModel from '$fbusiness/models/factory';
import { getFactoryLogo } from '$fbusiness/helpers/util';
import { IonReactRouter } from '@ionic/react-router';
import Receipt from '../../receipt';
import CurrentStateModel, { initialCurrentState } from '$fbusiness/models/currentState';
import UserModel from '$gbusiness/models/user';
import StoreModel from '$fbusiness/models/store';

interface RouterProps {
  factory: FactoryModel;
  store?: StoreModel;
  categories: Array<CategoryModel>;
  isLoggedIn: boolean;
  currentRoute: string;
  onLogout: Function;
  user?: UserModel;
}

const Router: React.FC<RouterProps> = ({
  factory,
  store,
  categories,
  isLoggedIn,
  currentRoute,
  user,
  onLogout,
}) => {
  if (!factory) return null;
  const redirectRoute = ROUTES.DEFAULT_APP_PATH;
  const isOrderRoute = !!ORDER_LIST_ROUTES.find((r) => currentRoute.includes(r));

  const currentState: CurrentStateModel = {
    ...initialCurrentState,
    isLoggedIn,
    user,
    isFactory: false,
    isGuest: user?.firstName === 'Guest' && user?.userLevel === 2,
    isWholesale: isLoggedIn && store?.settings?.wholesale === '1' ? true : false,
    hidePrice: !!factory.settings?.hidePrice,
    ebrochure: !isLoggedIn,
    settings: factory.settings,
  };

  return (
    <IonReactRouter basename={process.env.REACT_APP_PUBLIC_URL}>
      <Menu
        isOrderRoute={isOrderRoute}
        categories={categories}
        currentRoute={currentRoute}
        onLogout={onLogout}
        isLoggedIn={isLoggedIn}
        menuId="start"
        logo={getFactoryLogo(factory, true)}
      />
      <MainHeader currentState={currentState} logo={getFactoryLogo(factory, true)} />
      <IonContent>
        <IonContent>
          <IonSplitPane contentId="main" when={`(min-width: ${configs.display.splitPaneThreshold})`}>
            <Menu
              isOrderRoute={isOrderRoute}
              categories={categories}
              currentRoute={currentRoute}
              onLogout={onLogout}
              isLoggedIn={isLoggedIn}
              menuId="start"
              hiddenMobile={true}
            />
            <AppOutlet currentState={currentState} user={user} />
          </IonSplitPane>
          <Route path="/" render={() => <Redirect to={redirectRoute} />} exact />
        </IonContent>
      </IonContent>
      <Receipt currentRoute={currentRoute} currentState={currentState} />
    </IonReactRouter>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  store: state.store?.store || state.factory?.store,
  categories: state.category.categories,
  isLoggedIn: state.localStorage.accessToken !== '',
  currentRoute: state.routing.currentRoute,
  user: state.currentUser,
});

const mapDispatchToProps = {
  onLogout: authActions.logout,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(Router);
