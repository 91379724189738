import React, { useState } from 'react';
import intl from '$intl';
import { useDropzone } from 'react-dropzone';

import { dialog } from '$gcomponents/reusables';
import { toFileSize, toNumber } from '$gbusiness/helpers/util';
import { Delete } from '@mui/icons-material';
import { Flex } from '$gstyles/wrapper';
import { AttachmentWrapper } from './styles';

interface AttachmentFormProps {
  maxSize?: number;
  onChange: Function;
  files?: Array<any>;
  className?: string;
}

const AttachmentForm: React.FC<AttachmentFormProps> = ({
  maxSize: maxAtachmentSize,
  files: ofiles,
  onChange,
  className,
}) => {
  const MAX_ATTACHMENT_SIZE = 5000000000;

  const maxSize = maxAtachmentSize || MAX_ATTACHMENT_SIZE;
  const [cfiles, setFiles] = useState<Array<any>>([]);

  const files = ofiles || cfiles;

  const currentAttachmentSize: number = files.reduce((acc, file) => acc + toNumber(file.fileSize), 0);
  const isFileOver = currentAttachmentSize > maxSize;

  const onDeleteRow = (index) => {
    const newFiles = files.filter((f, i) => index !== i);
    setFiles(newFiles);
    onChange(newFiles);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 5,
    onDrop: (acceptedFiles: File[]) => {
      if (!acceptedFiles.length) {
        dialog.alert({
          text: intl('ERROR.FILE_UPLOAD'),
        });
        return;
      }
      const newFiles = acceptedFiles.map((file) => ({
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        file,
      }));

      const newFiles2 = [...files, ...newFiles];
      setFiles(newFiles2);
      onChange(newFiles2);
    },
  });

  return (
    <AttachmentWrapper className={className}>
      <h4>
        {intl('SCREEN.CONTACT.ATTACHMENTS')}
        <span className="caption">
          {intl('SCREEN.CONTACT.ATTACHMENT_CAPTION', {
            limit: toFileSize(maxSize),
          })}
        </span>
      </h4>
      <div className="dropzone-wrapper">
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          <p>{intl('MESSAGE.FILE_UPLOAD_LABEL')}</p>
        </div>
      </div>
      <div>
        {files.map((file, i) => (
          <Flex key={i} className="attachment">
            <div className="file-name">{file.fileName}</div>
            <div>{toFileSize(file.fileSize)}</div>
            <Delete className="pointer" onClick={() => onDeleteRow(i)} />
          </Flex>
        ))}
      </div>
      <div className="attachment-message">
        {isFileOver ? (
          <div className="error">
            {intl('SCREEN.CONTACT.ATTACHMENT_OVER_MESSAGE', {
              limit: toFileSize(maxSize),
            })}
          </div>
        ) : (
          <div className="hide">Max Attachemts: {toFileSize(maxSize)}</div>
        )}
      </div>
    </AttachmentWrapper>
  );
};

export default AttachmentForm;
