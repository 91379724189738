export const KEYS = {
  TECH: 'TECH',
  BILLING: 'BILLING',
  OTHER: 'OTHER',
};

export const TICKET_TITLE = KEYS;

export const TICKET_TITLES = {
  ...KEYS,
};

const convertToOption = (item) => ({
  label: `TICKET_TITLE.${item}`,
  value: item,
});

export const TIKET_TITLE_LIST = Object.keys(KEYS);

export const TICKET_TITLE_OPTIONS = TIKET_TITLE_LIST.map(convertToOption);
