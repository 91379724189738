import { initialItem } from '../../models/item';
import {
  CLEAN_ITEM,
  CREATE_ITEM_SUCCESS,
  DELETE_ITEM_SUCCESS,
  ItemActionTypes,
  ItemReducerType,
  ITEM_INIT_STATE,
  FETCH_ITEM_SUCCESS,
  UPDATE_ITEM_SUCCESS,
  UPDATE_MODIFIER_GROUP,
  DELETE_MODIFIER_GROUP,
  FETCH_ITEMS_SUCCESS,
  FETCH_MG_SUCCESS,
  REORDER_ITEM,
  TOGGLE_PROP,
  TOGGLE_SETTING_PROP,
  FETCH_MOCK_ITEMS_SUCCESS,
  CLEAN_MOCK,
  FETCH_LIST_SUCCESS,
  ADD_TO_ITEM_LIST,
} from './types';

const updateModifierGroup = (modifierGroups, modifierGroup, index) => {
  if (index < 0) return [...modifierGroups, modifierGroup];

  return modifierGroups.map((mg, i) => {
    if (i !== index) return mg;
    return modifierGroup;
  });
};

const deleteModifierGroup = (modifierGroups, index) => {
  const { id } = modifierGroups[index];
  if (!id) return modifierGroups.filter((mg, i) => index !== i);
  return modifierGroups.map((mg, i) => (index !== i ? mg : { ...mg, deleted: true }));
};

const reorderItems = (items, itemId, rank) => {
  const newItems = items.map((d) => {
    if (d.id !== itemId) return d;
    return {
      ...d,
      rank,
    };
  });
  newItems.sort((a, b) => parseFloat(a.rank) - parseFloat(b.rank));
  return newItems;
};

export default function itemReducer(
  state: ItemReducerType = ITEM_INIT_STATE,
  action: ItemActionTypes,
): ItemReducerType {
  switch (action.type) {
    case FETCH_ITEM_SUCCESS:
      return {
        ...state,
        item: action.item,
      };
    case FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        items: action.items,
      };
    case FETCH_LIST_SUCCESS:
      return {
        ...state,
        list: action.list,
      };
    case ADD_TO_ITEM_LIST:
      return {
        ...state,
        list: [...state.list, action.item],
      };
    case FETCH_MOCK_ITEMS_SUCCESS:
      return {
        ...state,
        mockItems: action.items,
      };
    case FETCH_MG_SUCCESS:
      return {
        ...state,
        modifierGroups: action.modifierGroups,
      };
    case UPDATE_ITEM_SUCCESS:
    case CREATE_ITEM_SUCCESS:
      return {
        ...state,
        isFinished: true,
      };
    case DELETE_ITEM_SUCCESS:
      return {
        ...state,
        refresh: !state.refresh,
      };
    case CLEAN_ITEM:
      return {
        ...state,
        item: null,
        isFinished: false,
        refresh: state.refresh,
      };
    case UPDATE_MODIFIER_GROUP:
      return {
        ...state,
        item: {
          ...(state.item || initialItem),
          modifierGroups: updateModifierGroup(state.item?.modifierGroups, action.modifierGroup, action.index),
        },
      };
    case DELETE_MODIFIER_GROUP:
      return {
        ...state,
        item: {
          ...(state.item || initialItem),
          modifierGroups: deleteModifierGroup(state.item?.modifierGroups, action.index),
        },
      };
    case REORDER_ITEM:
      return {
        ...state,
        items: reorderItems(state.items, action.itemId, action.rank),
      };
    case TOGGLE_PROP:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id !== action.id) return item;
          return {
            ...item,
            ...action.value,
          };
        }),
      };
    case TOGGLE_SETTING_PROP:
      return {
        ...state,
        items: state.items.map((item) => {
          if (item.id !== action.id) return item;
          return {
            ...item,
            settings: {
              ...item.settings,
              ...action.value,
            },
          };
        }),
      };
    case CLEAN_MOCK:
      return {
        ...state,
        mockItems: [],
      };
    default:
      return state;
  }
}
