import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import {
  CLEAN_ORDER,
  CREATE_ORDER_SUCCESS,
  CREATE_REFUND_SUCCESS,
  DELETE_ORDER_SUCCESS,
  FETCH_ORDER_SUCCESS,
  FETCH_REFUND_SUCCESS,
  orderActionTypes,
  ORDER_FAILURE,
  UPDATE_ORDER_SUCCESS,
} from './types';
import { deriveRawToOrder } from '../../models/order';
import { FETCH_INVOICE_SUCCESS } from '../invoice/types';
import { deriveRawToInvoice } from '../../models/invoice';
import { deriveRawToRefund } from '../../models/refund';

export function fetchOrder(id): any {
  return async (dispatch: Dispatch) => {
    if (!id) return;
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.order.general + '/' + id,
      method: 'GET',
      // mockData: mockOrderDetails,
    });

    if (!response || !response?.data) {
      handleApiFail(dispatch, ORDER_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    if (response.invoice) {
      dispatch({
        type: FETCH_INVOICE_SUCCESS,
        invoice: deriveRawToInvoice(response.invoice),
      });
    }

    dispatch({
      type: FETCH_ORDER_SUCCESS,
      order: deriveRawToOrder(response.data),
    });
  };
}

export function fetchOrderSummary(): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.order.summary,
      method: 'GET',
      // mockData: mockOrderDetails,
    });

    if (!response || !response?.data) {
      handleApiFail(dispatch, ORDER_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_ORDER_SUCCESS,
      order: deriveRawToOrder(response.data),
    });
  };
}

export function adjustItemQuantities(items, silent = false): any {
  return async (dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.order.itemsUpdate,
      param: {
        items,
      },
      method: 'POST',
    });

    if (silent) return;

    if (!response || !response?.success) {
      handleApiFail(dispatch, ORDER_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, null, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function updateOrder(order, reload = false, finish = false): any {
  return async (dispatch) => {
    if (finish) dispatchLoading(dispatch, 'PROGRESS.SAVING');
    const param = {
      ...(order.status && { status: order.status }),
      ...(order.note && { note: order.note }),
      ...(order.totalAdjusted && { totalAdjusted: order.totalAdjusted }),
      ...(order.detailedData && { detailedData: order.detailedData }),
    };

    const response = await fetchApi({
      url: configs.api.order.general + '/' + order.id,
      param,
      method: 'PUT',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, ORDER_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      if (finish) handleApiSuccess(dispatch, UPDATE_ORDER_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
      if (reload) dispatch(fetchOrder(order.id)); // reload
    }
  };
}

export function saveOrder(id, order): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.order.general + (id ? '/' + id : ''),
      param: {
        ...(id && { ...id }),
        ...order,
      },
      method: id ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, ORDER_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      dispatch({
        type: CREATE_ORDER_SUCCESS,
        order: deriveRawToOrder(response.data),
      });
      // handleApiSuccess(null, null, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function fetchRefund(id): any {
  return async (dispatch: Dispatch, getState) => {
    if (!id) return;

    const store = getState();
    if (store.order.refund) return;

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: 'refunds/' + id,
      method: 'GET',
    });

    if (!response || !response?.data) {
      handleApiFail(dispatch, ORDER_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_REFUND_SUCCESS,
      refund: deriveRawToRefund(response.data),
    });
  };
}

export function requestRefund(id, order, showMessage = false): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: 'refunds' + (id ? '/' + id : ''),
      param: {
        ...(id && { ...id }),
        ...order,
      },
      method: id ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, ORDER_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      const refund = deriveRawToRefund(response.data);
      dispatch({
        type: CREATE_REFUND_SUCCESS,
        refund,
      });
      if (showMessage) handleApiSuccess(dispatch, null, 'MESSAGE.SAVE_SUCCESS', 'small');
      return Promise.resolve(refund);
    }
  };
}

export function deleteOrder(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.order.general + (id ? '/' + id : ''),
      method: 'DELETE',
    });

    if (response?.err) {
      handleApiFail(dispatch, ORDER_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_ORDER_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
    }
  };
}

export function dehydrate(): orderActionTypes {
  return { type: CLEAN_ORDER };
}
