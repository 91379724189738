// PENDING, CONFIRMED, SHIPPED, RECEIVED, APPROVED, COMPLETE
import { toLocal } from '$gbusiness/helpers/date';
import { toNumber } from '$gbusiness/helpers/util';
import { getMainImage } from './item';
import { deriveRawToItemPrice, initialItemPrice, ItemPriceModel } from './price';

export default interface OrderInvoiceItemModel extends ItemPriceModel {
  id: number;
  itemId: number;
  itemName: string;
  rawCost?: number;
  wholesalePrice?: number;
  retailPrice?: number;
  image: string;
  sku: string;
  barcode: string;
  settings?: any;
  note?: string;
  modifiers: Array<CartModifierModel>;
  createdAt?: string;
  updatedAt?: string;
}

export const deriveRawToOrderInvoiceItem = (raw, isInvoice = false) => {
  return {
    id: isInvoice ? raw.id : raw.id,
    itemId: raw.itemId,
    itemName: raw.itemName,
    image: getMainImage(raw.files),
    ...(raw.pricing && deriveRawToItemPrice(raw.pricing)),
    sku: raw.sku,
    barcode: raw.barcode,
    note: raw.note,
    modifiers: (raw.modifiers || []).map(deriveRawToCartModifier),
    settings: raw.Settings ? raw.Settings : {},
    createdAt: toLocal(raw.createdAt),
    updatedAt: toLocal(raw.updatedAt || raw.createdAt),
  };
};

export const deriveCartToOrderItem = (cartItem) => {
  const { id, qty, price, pricing, modifiers, name, instruction } = cartItem;
  return {
    id: 0,
    itemId: id,
    itemName: name,
    qty,
    note: instruction,
    subtotal: toNumber(price.subtotal),
    tax: price.tax,
    total: price.totalSum,
    ...(pricing ? pricing : {}),
    modifiers: modifiers.map(deriveCartToOrderModifier),
  };
};

export const deriveCartToOrderModifier = (cartModifier) => {
  const { mgId, modifierId, name, label, qty, price } = cartModifier;
  return {
    id: 0,
    mgId,
    modifierId,
    label,
    name,
    qty,
    price: toNumber(price),
  };
};

export interface CartModifierModel {
  mgId: number;
  id: number;
  name: string;
  label: string;
  qty: number;
  qtySent?: number;
  qtyReceived?: number;
  subtotal: number;
  tax: number;
  total: number;
  totalAdjusted?: number;
}

export const deriveRawToCartModifier = (raw) => {
  return {
    mgId: raw.mgId,
    id: raw.id,
    name: raw.name,
    label: raw.label,
    qty: raw.qty,
    price: raw.price,
    subtotal: raw.subtotal || raw.price * raw.quantity,
  };
};

export const initialOrderInvoiceItem: OrderInvoiceItemModel = {
  id: 0,
  itemId: 0,
  itemName: '',
  image: '',
  sku: '',
  barcode: '',
  modifiers: [],
  ...initialItemPrice,
};

export const recalculateItemPrice = (order, fixedSubtotal: any = null, fixedQty = false, noTax = false) => {
  const {
    unitPrice,
    taxRate: initialTaxRate,
    disableDiscount,
    discountRate: dcRate,
    qty = 0,
    qtySent,
  } = order;
  const taxRate = noTax ? 0 : initialTaxRate || 0;
  const discountRate = disableDiscount ? 0 : dcRate;
  const unitTax = noTax ? 0 : Math.round(unitPrice * taxRate) / 100;
  const finalQty = fixedQty ? qtySent || qty : qty;
  // const taxRate = (unitTax / (unitPrice - discount / finalQty)) * 100;
  const subtotal = fixedSubtotal === null ? unitPrice * finalQty : toNumber(fixedSubtotal);
  const newDiscount = Math.round(subtotal * discountRate) / 100;
  const taxable = subtotal - newDiscount;
  const tax = Math.round(taxable * taxRate) / 100;
  const total = taxable + tax;
  return {
    ...order,
    unitPrice,
    unitTax,
    discountRate,
    taxRate: initialTaxRate,
    subtotal,
    discount: newDiscount,
    taxable,
    tax,
    total,
  };
};
