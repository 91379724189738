import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { FONT, WEIGHT } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .content {
    .attachment {
      color: #666;
    }
  }
  .MuiTableRow-root.unread {
    * {
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
  }
  .contact-name {
    font-size: ${FONT.XSMALL};
    opacity: 0.8;
  }
  .filter-submit {
    padding: 8px 12px;
    top: 6px;
  }
`;

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -10,
    top: 0,
    minWidth: 18,
    height: 18,
    width: 18,
    padding: '0 0px',
  },
}));
