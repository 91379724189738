import React, { useRef } from 'react';
import { connect } from 'react-redux';
import { IonContent } from '@ionic/react';

import { screen } from '../../../components/hoc';

import { IonPageWrapper } from './styles';
import { Div, SPACE } from '$gstyles';
import { phone as formatPhone } from '$gbusiness/helpers/mask';
import UserModel from '$gbusiness/models/user';
import { Form, Formik } from 'formik';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';
import { input } from '$gbusiness/helpers';

import { PROFILE_FORM } from './profileForm';
import { cuserActions } from '$gbusiness/redux/currentUser';
import CardModel from '$gbusiness/models/card';
import FactoryHeader from '$fcomponents/header';
import { Header as FixedHeader } from '$gcomponents/widgets';
import { userActions } from '$gbusiness/redux/user';
import intl from '$gintl';
import { configs } from '$configs';

interface ProfileScreenProps {
  user: UserModel;
  cards: Array<CardModel>;
  editProfile: Function;
  deleteCard: Function;
  saveUser: Function;
}

const ProfileScreen: React.FC<ProfileScreenProps> = ({ user, saveUser, editProfile }) => {
  const formRef = useRef<any>();
  const { userId, firstName, lastName, email, other } = user;

  const onSubmit = (values) => {
    const { firstName, lastName, newPassword: password } = values;
    editProfile({
      userId: user.userId,
      firstName,
      lastName,
      ...(password && { password }),
    });
  };
  const inititalValues = {
    userId,
    firstName,
    lastName,
    email,
    mobile: formatPhone((other?.mobile || '').toString()),
  };

  const validateForm = (values) => input.validateError(PROFILE_FORM(formRef.current.values), values);

  const Header =
    configs.display.layout === 'FIXED_HEADER' ? (
      <FactoryHeader title="SCREEN.PROFILE.TITLE" />
    ) : (
      <FixedHeader title="SCREEN.PROFILE.TITLE" />
    );

  return (
    <IonPageWrapper>
      {Header}
      <IonContent>
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={inititalValues}
          validate={validateForm}
          onSubmit={(values) => {
            onSubmit(values);
          }}>
          {(formik) => (
            <Div padding={SPACE.LARGE} maxWidth="800px">
              <Form>
                <FormSection FORM={PROFILE_FORM(formik.values)} formik={formik} />
                <Button
                  className="filled primary submit"
                  onClick={formik.handleSubmit}
                  disabled={!(formik.isValid && formik.dirty)}>
                  {intl('BUTTON.SUBMIT')}
                </Button>
              </Form>
            </Div>
          )}
        </Formik>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.currentUser,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  editProfile: cuserActions.editProfile,
  saveUser: userActions.saveUser,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ProfileScreen));
