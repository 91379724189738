import _ from 'lodash';
import React, { useCallback } from 'react';
import { FormControl, TextFieldProps } from '@mui/material';
import { configs } from '$configs';
import intl from '$gintl';

import { TextFieldWrapper, AutoCompleteWrapper } from './styles';
import { fetchApi } from '$gbusiness/services/api';
import { FastField, Field } from 'formik';
import { generateGetParam } from '$gbusiness/helpers/util';

interface AcConfigModel {
  endpoint?: string;
  initialList?: Array<any>;
  apiParam?: any;
  data?: Array<any>;
  throttle?: number;
  minChar?: number;
  actualVar?: string;
  maxItems?: number;
  optionProp: {
    label: string;
    value: string;
  };
}

export const defaultAcConfig: AcConfigModel = {
  data: [],
  throttle: 500,
  minChar: 2,
  maxItems: 20,
  initialList: [],
  optionProp: {
    label: 'name',
    value: 'id',
  },
};

interface AutoCompleteInputProps {
  label?: string;
  labelText?: string;
  name: string;
  clearValue: any;
  disabled?: boolean;
  placeholder?: string;
  position?: TextFieldProps['variant'];
  fullWidth?: boolean;
  formik?: any;
  className?: string;
  size?: string;
  shrink?: boolean;
  helperText?: string;
  handleChange?: Function;
  config?: any;
  disableFastField?: boolean;
}

const AutoCompleteInput: React.FC<AutoCompleteInputProps> = ({
  label = '',
  labelText = '',
  name = '',
  clearValue = null,
  disabled = false,
  placeholder = '',
  className = '',
  helperText = '',
  position = undefined,
  fullWidth = true,
  formik = {},
  size = configs.display.inputSize,
  // shrink = undefined,
  handleChange = (e) => {},
  disableFastField = false,
  config: originalConfig = defaultAcConfig,
}) => {
  const AcConfig = {
    ...defaultAcConfig,
    ...originalConfig,
    initialList:
      originalConfig.data.length < (originalConfig.initialThres || 50)
        ? originalConfig.data
        : originalConfig.initialList || [],
  };
  const noOptionsText = !AcConfig.data?.length && !AcConfig.endpoint ? 'No Result' : 'Type to Search...';
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<Array<any>>(AcConfig?.data || []);

  const [inputValue, setInputValue] = React.useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    _.debounce(async (value) => {
      const { optionProp, endpoint, apiParam } = AcConfig;
      const param = {
        ...apiParam,
        query: value,
      };
      const response = await fetchApi({
        url: endpoint + '?' + generateGetParam(param),
        method: 'GET',
      });
      if (!response || response.list) {
        setOptions([]);
        return;
      }
      setOptions([
        ...(response?.list || []).map((d) => ({
          [optionProp.label]: d[optionProp.label],
          [optionProp.value]: d[optionProp.value],
        })),
      ]);
    }, 500),
    [],
  );

  React.useEffect(() => {
    setOptions(AcConfig.data || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const { endpoint, data, maxItems, optionProp } = AcConfig;
    if (inputValue?.length < 2) {
      setOptions([]);
      return undefined;
    }

    if (!data.length && endpoint) {
      debouncedSearch(inputValue);
    } else {
      const filteredList = data.reduce((acc, d) => {
        if (acc.length > maxItems) return acc;
        const found = (optionProp.labelText ? optionProp.labelText(d) : d[optionProp.label] || '')
          .toLowerCase()
          .includes(inputValue.toLowerCase());
        if (found) {
          acc.push({
            [optionProp.label]: optionProp.labelText ? optionProp.labelText(d) : d[optionProp.label],
            [optionProp.value]: d[optionProp.value],
          });
        }
        return acc;
      }, []);
      setOptions(filteredList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  React.useEffect(() => {
    setOptions(AcConfig.initialList);
    // const { data, maxItems } = AcConfig;
    // setOptions(data.slice(0, Math.min(maxItems, data.length)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onChangeInputValue = (e) => {
    setInputValue(e.target.value);
  };
  const onSelectItem = (e, val) => {
    if (!val) {
      setInputValue('');
      formik.setFieldValue(name, clearValue);
      if (handleChange) handleChange(e, val);
      return;
    }
    formik.setFieldValue(name, val);
    if (handleChange) handleChange(e, val);
  };

  const FormField = disableFastField ? Field : FastField;
  const value = _.get(formik.values, name) || '';
  const errorKey = _.get(formik.errors, name);
  const isTouched = _.get(formik.touched, name) !== undefined;
  const hasError = isTouched && errorKey !== undefined;
  const errorMsg = hasError ? intl(`INPUT.ERROR.${errorKey}`) : undefined;
  const inputSize = size === 'small' || size === 'xsmall' ? 'small' : 'medium';
  const labelProps = {
    label: labelText || intl(label),
  };
  const variant = position || 'outlined';

  return (
    <AutoCompleteWrapper
      key={name}
      noOptionsText={noOptionsText}
      fullWidth={fullWidth}
      getOptionLabel={(option) => {
        if (AcConfig.optionProp?.labelText) {
          return AcConfig.optionProp?.labelText(option) || '';
        }
        if (option) {
          return option[AcConfig.optionProp.label] || '';
        }
        return '';
      }}
      filterOptions={(x) => x}
      autoSelect
      options={options}
      clearIcon={AcConfig.disableClear ? null : undefined}
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={onSelectItem}
      className={`${className} ${size === 'xsmall' && 'xsmall'}`}
      autoComplete
      disabled={disabled}
      value={value || null}
      renderInput={(params) => {
        const more = {
          ...params,
          size: inputSize,
        };
        return (
          <FormField name={name}>
            {({ form }) => (
              <FormControl size={inputSize} fullWidth={fullWidth}>
                <TextFieldWrapper
                  {...labelProps}
                  type="text"
                  variant={variant}
                  size={inputSize}
                  className={inputSize}
                  placeholder={intl(placeholder) || undefined}
                  error={hasError}
                  onInput={onChangeInputValue}
                  value={inputValue || ''}
                  helperText={errorMsg || helperText}
                  {...more}
                />
              </FormControl>
            )}
          </FormField>
        );
      }}
    />
  );
};

export default AutoCompleteInput;
