export interface SimpleModel {
  id: number;
  name?: string;
  description?: string;
}

export const initialSimple = {
  id: 0,
  name: '',
};

export const deriveRawToSimple = (raw) => ({
  id: raw.id,
  name: raw.name,
  ...(raw.description && { description: raw.description }),
});
