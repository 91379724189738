import { INVOICE_PAYMENTS, PAYMENT_TYPE } from '$fbusiness/enums/options/paymentType';
import { toCreditId } from '$fbusiness/models/credit';
import { currency } from '$gbusiness/helpers/mask';
import { PaymentInfo } from './styles';

export const displayPaymentInfo = (row) => {
  let html: any = null;
  switch (row.type) {
    case INVOICE_PAYMENTS.CREDITS:
      html = toCreditId(row?.id);
      break;
    case PAYMENT_TYPE.CASH:
      html = row.settings?.refNo ? <div className="check-no">Ref #{row.settings?.refNo}</div> : null;
      break;
    case PAYMENT_TYPE.CHECK:
    case PAYMENT_TYPE.ACH:
      const bankName = row.bank && row.bank.name ? <div className="bank-name">{row.bank.name}</div> : null;
      const checkNo = row.settings?.checkNo ? (
        <div className="check-no">Check #{row.settings?.checkNo}</div>
      ) : null;
      const originalAmt = row.settings?.originalAmt ? (
        <div className="original">Original Amt: {currency(row.settings?.originalAmt)}</div>
      ) : null;
      html = (
        <>
          {bankName}
          {checkNo}
          {originalAmt}
        </>
      );
      break;
    case PAYMENT_TYPE.CREDIT:
      html = (
        <div>
          <span className="card-type">{row.settings?.cardType} </span>
          {row.settings?.cardNumber && <span className="xsmall">xxx{row.settings?.cardNumber}</span>}
        </div>
      );
      break;
    default:
      html = null;
      break;
  }
  return (
    <PaymentInfo>
      {html}
      {row.memo && <div className="xsmall">"{row.memo}"</div>}
    </PaymentInfo>
  );
};
