import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  IonContent,
  IonHeader,
  IonToolbar,
  IonFooter,
  IonTitle,
  IonButtons,
  IonMenuToggle,
  IonButton,
} from '@ionic/react';
import { menuController } from '@ionic/core';
import DeleteIcon from '@mui/icons-material/Delete';
import { RouteComponentProps, useHistory, withRouter } from 'react-router-dom';

import { Wrapper } from './styles';
import { Div } from '$gstyles';
import { AppModel } from '$business/redux';
import { Button } from '$gcomponents/primitives';
import intl from '$intl';
import { cartActions } from '$fbusiness/redux/cart';
import MuiModal from '$gcomponents/reusables/modal/material';
import { TextField } from '@mui/material';
import { ROUTES } from '$business/enums';
import { dialog } from '$gcomponents/reusables';
import { canAccess, currency } from '$gbusiness/helpers/util';
import CartList from '../cartList';
import { Flex } from '$gstyles/wrapper';
import CurrentStateModel from '$fbusiness/models/currentState';
import Close from '@mui/icons-material/Close';
import { OrderSummary } from '$fcomponents';
import PATH from '$fbusiness/enums/paths';
import { getEl } from '$gbusiness/helpers/input';

interface ReceiptProps extends RouteComponentProps {
  currentState: CurrentStateModel;
  currentRoute: string;
}

const Receipt: React.FC<ReceiptProps> = ({ currentRoute, currentState, ...rest }) => {
  const { ebrochure, hidePrice } = currentState;
  const dispatch = useDispatch();
  const history = useHistory();
  const [showSaveCartModal, setShowSaveCartModal] = useState(false);
  const [cartName, setCartName] = useState('');
  const cart = useSelector((state: AppModel) => state.localStorage.cart);
  const user = useSelector((state: AppModel) => state.currentUser);
  const isFinished = useSelector((state: AppModel) => state.cart.isFinished);
  const isCheckout = currentRoute === ROUTES.PATH.CHECKOUT;
  const {
    products: items,
    pricing: { po },
  } = cart;
  const { subtotal } = po;
  const isThankyou = currentRoute.includes(ROUTES.PATH.THANKYOU);

  useEffect(() => {
    if (isFinished) {
      setShowSaveCartModal(false);
      dispatch(cartActions.cleanCart());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  const onClearCart = () => {
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.DELETE_WARNING',
      cssClass: 'large',
      onPress: () => {
        dispatch(cartActions.resetCart());
      },
    });
  };
  const onSaveCart = () => {
    dispatch(cartActions.saveCart(items, 0, cartName));
  };
  const onCancel = () => {
    history.push(ROUTES.DEFAULT_APP_PATH);
  };
  const onCheckout = () => {
    history.push(ROUTES.PATH.CHECKOUT);
    menuController.close();
  };
  const onUpdateQuantity = (i, qty) => {
    if (qty === 0) {
      dispatch(cartActions.removeProduct(i));
      return;
    }
    dispatch(cartActions.changeProductQuantity(i, qty)).then((q) => {
      if (!q) return;
      const inputEl = getEl('.cart-list input[name="item_' + i + '"]');
      if (inputEl) inputEl.value = q;
      dispatch(cartActions.changeProductQuantity(i, q));
    });
  };
  const navCart = () => history.push(PATH.CARTS);

  return (
    <Wrapper
      contentId="main"
      side="end"
      disabled={ebrochure || currentRoute === ROUTES.PATH.CHECKOUT}
      isProd={process.env.REACT_APP_ENV === 'prod'}
      {...rest}>
      <IonHeader>
        <IonToolbar color="tertiary">
          <IonTitle>
            {intl('SCREEN.CART.TITLE')}
            {/* {items?.length > 0 && <span className="item-count">{`${items.length} items`}</span>} */}
          </IonTitle>
          <IonButtons slot="end">
            <IonMenuToggle menu="end">
              <IonButton className="link">
                <Close className="cart-close" />
              </IonButton>
            </IonMenuToggle>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      {isThankyou ? (
        <IonContent />
      ) : (
        <>
          <IonContent class="outer-content">
            <CartList
              items={items}
              pointerIndex={cart.pointerIndex}
              hidePrice={hidePrice}
              onUpdateQuantity={isCheckout ? undefined : onUpdateQuantity}
            />
            {isCheckout && !hidePrice ? (
              <OrderSummary price={cart.price} includeTitle />
            ) : (
              items?.length > 0 && (
                <div className="center">
                  <Button
                    onClick={onClearCart}
                    className="clear-cart"
                    variant="text"
                    startIcon={<DeleteIcon />}>
                    {intl('BUTTON.CLEAR_CART')}
                  </Button>
                </div>
              )
            )}
          </IonContent>
          <IonFooter>
            {isCheckout ? (
              <Div>
                <Button
                  onClick={onCancel}
                  className="cancel-button"
                  fullWidth
                  size="large"
                  variant="text"
                  color="secondary">
                  {intl('BUTTON.CANCEL')}
                </Button>
              </Div>
            ) : (
              <Div>
                {items && items.length > 0 ? (
                  <>
                    {!hidePrice && (
                      <Flex className="total-section">
                        <div className="label">{intl('SCREEN.CART.SUBTOTAL')}:</div>
                        <div className="subtotal price semi-bold">{currency(subtotal)}</div>
                      </Flex>
                    )}
                    <Button
                      onClick={() => setShowSaveCartModal(true)}
                      fullWidth
                      size="large"
                      variant="outlined">
                      {intl('BUTTON.SAVE_CART')}
                    </Button>
                  </>
                ) : (
                  <Button onClick={navCart} fullWidth size="large" variant="outlined">
                    {intl('BUTTON.GO_TO_CART')}
                  </Button>
                )}
                <Button
                  onClick={onCheckout}
                  disabled={!items.length || !canAccess(2, user?.userLevel)}
                  fullWidth
                  className="checkout-button"
                  size="large">
                  {intl('BUTTON.CHECKOUT')}
                </Button>
              </Div>
            )}
          </IonFooter>
          <MuiModal
            minWidth="400px"
            open={showSaveCartModal}
            title={intl('SCREEN.CART.SAVE_TITLE')}
            footer={<Button onClick={onSaveCart}>{intl('BUTTON.SUBMIT')}</Button>}
            onClose={() => setShowSaveCartModal(false)}>
            <TextField
              fullWidth
              label={intl('INPUT.LABEL.CART_NAME')}
              variant="outlined"
              onChange={(e) => setCartName(e.target.value.toString())}
              value={cartName}
            />
          </MuiModal>
        </>
      )}
    </Wrapper>
  );
};

export default withRouter(Receipt);
