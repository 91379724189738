import styled from 'styled-components';
import { FONT, SPACE, WEIGHT } from '$gstyles';
import { Modal } from '$gcomponents/reusables';
import { mobileStyle } from '$gstyles/utils';

export const ModalWrapper = styled(Modal)`
  .MuiTableCell-head {
    font-weight: ${WEIGHT.SEMI_BOLD};
  }
  .logo {
    position: absolute;
    top: 32px;
    right: 32px;
    width: 150px;
    height: 80px;
    img {
      width: 100%;
    }
  }
  .inv-status {
    text-transform: uppercase;
  }
  .paid {
    color: var(--ion-color-success);
  }
  .void {
    color: var(--ion-color-danger);
  }
  .qty {
    width: 60px;
    text-align: center;
    border-radius: 4px;
    border-width: 1px;
  }
  .content-wrapper {
    padding: ${SPACE.XLARGE};
  }
  ${mobileStyle(`
    .content-wrapper {
      padding: ${SPACE.MEDIUM};
    }
  `)}

  .invoice-header {
    font-size: ${FONT.SMALL};
    font-weight: ${WEIGHT.NORMAL};
  }

  .notes-label {
    font-weight: ${WEIGHT.SEMI_BOLD};
    margin: ${SPACE.LARGE} 0 ${SPACE.MEDIUM} ${SPACE.SMALL};
  }
  textarea {
    resize: none;
    width: 100%;
    height: 120px;
    padding: ${SPACE.MEDIUM};
  }

  .fromto {
    .title {
      font-weight: ${WEIGHT.SEMI_BOLD};
      padding: ${SPACE.MEDIUM} 0;
      text-transform: uppercase;
    }
    .info {
    }
    margin-bottom: ${SPACE.LARGE};
  }

  .summary-wrapper {
    width: 300px;
  }

  .line {
    display: flex;
    justify-content: flex-end;
    height: 24px;
    max-width: 300px;
    .label {
      width: 110px;
      padding: ${SPACE.SMALL};
      font-weight: ${WEIGHT.SEMI_BOLD};
    }
    .value {
      width: 110px;
      padding: ${SPACE.SMALL};
    }
  }

  .packing-print {
    display: none;
  }

  @media print {
    .logo {
      position: absolute;
      width: 100px;
      height: auto;
      top: 24px;
      right: 30px;
    }
    .col {
      width: inherit;
    }
    .title {
      padding: 0 0 ${SPACE.MEDIUM};
    }
    ion-content.invoice-mode {
      .packing-print {
        display: none;
      }
      .invoice-print {
        display: flex;
      }
      .invoice-print-block {
        display: block;
      }
    }
    ion-content.packing-mode {
      .packing-print {
        display: block;
      }
      .invoice-print-block,
      .invoice-print {
        display: none;
      }
    }
  }
`;
