import styled from 'styled-components';
import { IonPage } from '@ionic/react';
import { Div, FONT, SPACE } from '$gstyles';

export const IonPageWrapper = styled(IonPage)`
  .content {
  }
`;

export const AttachmentWrapper = styled(Div)`
  padding-bottom: ${SPACE.XXLARGE};
  .caption {
    margin-left: ${SPACE.LARGE};
    font-size: ${FONT.SMALL};
  }
  padding-top: 4px;
  .attachment {
    padding: 4px;
    > * {
      width: 60px;
    }
    .file-name {
      flex: 1;
      width: auto;
    }
  }

  .attachment-message {
    padding-top: 10px;
  }
`;
