import React, { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { IonFooter } from '@ionic/react';
import { Form, Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { screen } from '$gcomponents/hoc';
import Header from '$fcomponents/header';
import { userActions } from '$gbusiness/redux/user';
import { Col, Div, Row, SPACE } from '$gstyles';
import { Button } from '$gcomponents/primitives';
import { Flex } from '$gstyles/wrapper';

import { IonPageWrapper } from './styles';
import { ADDRESS_FORM, PAYMENT_FORM, ADDITIONAL_FORM, getShippingForm } from './checkoutForm';
import intl from '$intl';
import { currency } from '$gbusiness/helpers/util';
import { orderActions } from '$fbusiness/redux/order';
import CartModel from '$fbusiness/models/cart';
import FactoryModel from '$fbusiness/models/factory';
import StoreModel from '$fbusiness/models/store';
import { cartActions } from '$fbusiness/redux/cart';
import { KEYS } from '$fbusiness/enums/options/orderStatus';
import { KEYS as PAYMENT_METHODS } from '$fbusiness/enums/options/paymentMethod';
import { DATE_FORMATS } from '$gbusiness/enums';
import { getDeliveryDate } from '$fbusiness/helpers/util';
import CartList from '$components/cartList';
import { OrderSummary } from '$fcomponents';
import { ScreenTitle } from '$styles/general';
import UserModel, { getFullName, getMobile } from '$gbusiness/models/user';
import CurrentStateModel from '$fbusiness/models/currentState';
import IonContentWrapper from '$fcomponents/ionContentWrapper';
import PATH from '$business/enums/paths';
import { deriveCartToOrderItem } from '$fbusiness/models/orderInvoiceItem';

interface CheckoutScreenProps {
  history;
  store: StoreModel;
  factory: FactoryModel;
  currentState: CurrentStateModel;
  cart: CartModel;
  isFinished: boolean;
  placeOrder: Function;
  user: UserModel;
  resetCart: Function;
  updateShipping: Function;
}

const CheckoutScreen: React.FC<CheckoutScreenProps> = ({
  history,
  cart,
  user,
  store,
  factory,
  currentState,
  isFinished,
  placeOrder,
  resetCart,
  updateShipping,
}) => {
  const formRef = useRef<any>();
  const { shippings, settings } = factory;
  const { isLoggedIn, hidePrice } = currentState;
  const shippingOptions = shippings.map((s) => ({
    labelText: `${s.name} ${s.description || ''} (${currency(s.deliveryCost)}) - ${
      s.name.toLowerCase().includes('pick') ? 'Ready By' : 'Deliver'
    } @ ${getDeliveryDate(s.days, DATE_FORMATS.DAY_WEEKDAY)}`,
    value: s.id,
  }));

  const shippingRef = useRef<any>(shippings[0]);
  const initialValues = {
    formatted: store?.formatted,
    paymentMethod: settings?.pickupOnly ? PAYMENT_METHODS.PICKUP : PAYMENT_METHODS.INVOICE,
    shippingOption: shippingRef.current.id,
    name: getFullName(user),
    mobile: store?.phone || getMobile(user),
    email: user?.email,
  };

  useEffect(() => {
    updateShipping(shippingRef.current.deliveryCost);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shippings]);

  useEffect(() => {
    onOrderSuccess();
  }, [isFinished]); // eslint-disable-line react-hooks/exhaustive-deps

  const onOrderSuccess = async () => {
    if (!isFinished) return;
    await resetCart();
    history.push(PATH.THANKYOU + '/0');
  };

  const onSubmit = (values) => {
    const { subtotal, tax, total, delivery: deliveryCost } = cart.pricing.po;
    const { shippingOption: shippingId, additionalMessage, formatted, name: contact, email, mobile } = values;
    const newValues = {
      qty: cart.products.reduce((acc, p) => acc + Number(p.qty), 0),
      items: cart.products.map((p) => deriveCartToOrderItem(p)),
      paymentTypeId: 0,
      shippingId,
      storeId: store.id,
      subtotal,
      status: KEYS.PREPARING,
      deliveryCost,
      tax,
      total,
      deliveryAddress: formatted,
      contact: contact,
      email,
      mobile,
      deliveryDate: getDeliveryDate(shippingRef.current.days),
      note: additionalMessage,
      textConfirmation: store?.settings?.textConfirmation || false,
      emailConfirmation: store?.settings?.emailConfirmation || false,
    };
    placeOrder(0, newValues);
  };

  const onChangeShipping = (result) => {
    const { value } = result;
    const newValue = parseInt(value);
    shippingRef.current = shippings.find((s) => s.id === newValue);
    updateShipping(shippingRef.current?.deliveryCost);
  };

  const SHIPPING_FORM = getShippingForm({
    shippingOptions,
    onChange: onChangeShipping,
    shipping: shippingRef.current,
  });

  const addressForm = ADDRESS_FORM(!!settings?.disableCustomer);

  const paymentForm = PAYMENT_FORM(factory?.settings?.pickupOnly || false);

  const validateForm = (values) =>
    input.validateError([...addressForm, ...paymentForm, ...ADDITIONAL_FORM, ...SHIPPING_FORM], values);

  if (!isLoggedIn) return <IonPageWrapper />;

  return (
    <IonPageWrapper>
      <Formik
        innerRef={formRef}
        enableReinitialize={true}
        initialValues={initialValues}
        validate={validateForm}
        onSubmit={(values) => {
          onSubmit(values);
        }}>
        {(formik) => (
          <>
            <IonContentWrapper>
              <Header padding={SPACE.LARGE} title="SCREEN.CHECKOUT.TITLE" />
              <Row className="split-container">
                <Col gridSize={4} className="order-summary">
                  <ScreenTitle padding={`${SPACE.LARGE}`}>
                    {intl('SCREEN.CHECKOUT.ORDER_SUMMARY.TITLE')}
                  </ScreenTitle>
                  <CartList items={cart.products} pointerIndex={cart.pointerIndex} hidePrice={hidePrice} />
                  {!hidePrice && <OrderSummary price={cart.pricing.po} includeTitle />}
                </Col>
                <Col gridSize={8} className="form-section">
                  <Div padding={`0 ${SPACE.LARGE}`} maxWidth="800px">
                    <Form>
                      <FormSection title="SCREEN.CHECKOUT.GENERAL_TITLE" FORM={addressForm} formik={formik} />
                      <FormSection title="SCREEN.CHECKOUT.PAYMENT_TITLE" FORM={paymentForm} formik={formik} />
                      <FormSection
                        title={`SCREEN.CHECKOUT.${settings?.pickupOnly ? 'PICKUP_TITLE' : 'SHIPPING_TITLE'}`}
                        FORM={SHIPPING_FORM}
                        formik={formik}
                      />
                      <FormSection
                        title="SCREEN.CHECKOUT.ADDITIONAL_TITLE"
                        FORM={ADDITIONAL_FORM}
                        formik={formik}
                      />
                    </Form>
                    {/* <Flex className="actions" padding={SPACE.MEDIUM} justifyContent="space-around">
                  <Button size="large" variant="text" onClick={onCancel}>
                    {intl('BUTTON.CANCEL')}
                  </Button>
                  <Button size="large" className="" onClick={onSubmit}>
                    {intl('BUTTON.PLACE_ORDER')}
                  </Button>
                </Flex> */}
                  </Div>
                </Col>
              </Row>
            </IonContentWrapper>

            <IonFooter className="actions">
              <Flex padding={SPACE.MEDIUM} justifyContent="center">
                <Button
                  size="large"
                  disabled={!formik.isValid}
                  className="third order-button"
                  onClick={formik.handleSubmit}>
                  {intl('BUTTON.PLACE_ORDER')}
                </Button>
              </Flex>
            </IonFooter>
          </>
        )}
      </Formik>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory.factory,
  store: state.factory.store,
  cart: state.localStorage.cart,
  isFinished: state.order.isFinished,
  resetOnRoute: true,
});

const mapDispatchToProps = {
  onHydrate: () => userActions.fetchUser,
  placeOrder: orderActions.saveOrder,
  resetCart: cartActions.resetCart,
  updateShipping: cartActions.updateShipping,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(CheckoutScreen));
