import { toLocal } from '$gbusiness/helpers/date';
import { deriveRawToPrice } from './price';

export default interface CreditModel {
  id: number;
  storeId: number;
  memo: string;
  creditDate: string | null;
  invoiceId?: number;
  amount: number;
  total?: number;
  balance: number;
  simple?: boolean;
  settings?: any;
  createdAt?: string | null;
  used?: boolean;
}

export const deriveRawToCredit = (raw) => {
  const pricing: any = deriveRawToPrice(raw.pricing);
  return {
    id: raw.id,
    storeId: raw.storeId,
    memo: raw.memo,
    creditDate: toLocal(raw.refundedAt),
    invoiceId: raw.invoiceId || 0,
    balance: raw.balance,
    amount: pricing.total,
    createdAt: toLocal(raw.createdAt),
    settings: {
      ...raw.settings,
    },
  };
};

export const deriveRawToAvailableCredit = (raw) => {
  return {
    id: raw.id,
    amount: raw.amount,
    balance: raw.balance,
    used: raw.used || 0,
    type: raw.paymentType,
    simple: raw.simple,
    total: raw.amount,
    display: raw.paymentType ? 'PAYMENT: ' + raw.paymentType : toCreditId(raw.id, raw.isRc),
    createdAt: toLocal(raw.createdAt),
    memo: raw.memo,
    settings: {
      ...raw.settings,
    },
  };
};

export const toCreditId = (id, isRc = false) => {
  if (id) return `${isRc ? 'RC' : 'C'}${id}`;
  return '';
};

export const toRefundCreditId = (id) => {
  if (id) return `RC${id}`;
  return '';
};

export const initialCredit: CreditModel = {
  id: 0,
  invoiceId: 0,
  storeId: 0,
  creditDate: null,
  memo: '',
  amount: 0,
  balance: 0,
  createdAt: null,
};
