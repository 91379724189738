import React from 'react';

import { configs } from '$configs';
import Img from '$gcomponents/primitives/img';

import { SliderWrapper } from './styles';

const HomeSlider: React.FC = () => {
  const sliderSetting = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    dots: true,
    swipeToSlide: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'home-slider',
  };

  const sliderImages = ['home_slider.jpg', 'home_slider2.jpg', 'home_slider3.jpg'];

  return (
    <SliderWrapper {...sliderSetting}>
      {sliderImages.map((fileName, i) => (
        <div className="banner-wrapper" key={i}>
          <Img src={configs.display.imagePath + fileName || ''} width="100%" height="100%" size="cover" />
        </div>
      ))}
    </SliderWrapper>
  );
};

export default HomeSlider;
