import React from 'react';
import { IonContent } from '@ionic/react';
import { connect } from 'react-redux';

import { screen } from '../../../components/hoc';
import { arReportExportSettings, AR_REPORT_TABLE, AR_REPORT_TABLE_CONFIG } from './arReportTable';
import { Header, TableView2 } from '$gcomponents/widgets';
import { IonPageWrapper } from '../styles';
import ExportButton from '$gcomponents/reusables/exportButton';

interface ArReportScreenProps {
  factory;
  history;
}

const ArReportScreen: React.FC<ArReportScreenProps> = ({ history }) => {
  const handleRowClick = (row) => {
    console.log('ROW', row);
  };

  const onTableLoad = (total, newTotal, response) => {
    console.log('ON TABLE LOAD AR', total, newTotal, response);
    // Display Summary info
  };

  return (
    <IonPageWrapper>
      <Header title="SCREEN.REPORT.TITLE_AR" />
      <IonContent>
        <TableView2
          header={
            <div className="right">
              <ExportButton
                variant="text"
                fileName="arReport"
                color="primary"
                exportSetting={arReportExportSettings}
              />
            </div>
          }
          onTableLoad={onTableLoad}
          tableConfig={AR_REPORT_TABLE_CONFIG}
          TABLE={AR_REPORT_TABLE(handleRowClick)}></TableView2>
      </IonContent>
    </IonPageWrapper>
  );
};

const mapStateToProps = (state) => ({
  factory: state.factory,
});

const mapDispatchToProps = {};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(ArReportScreen));
