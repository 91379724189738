import UserModel from '$gbusiness/models/user';
import StoreModel from './store';

export default interface CurrentStateModel {
  factoryId: number;
  hidePrice: boolean;
  isLoggedIn: boolean;
  ebrochure: boolean;
  isWholesale?: boolean;
  settings?: any;
  isFactory?: boolean;
  isGuest?: boolean;
  isSalesman?: boolean;
  user?: UserModel;
  store?: StoreModel;
}

export const initialCurrentState: CurrentStateModel = {
  factoryId: 0,
  hidePrice: true,
  isLoggedIn: false,
  isWholesale: false,
  ebrochure: true,
};
