import React from 'react';
import { connect } from 'react-redux';

import { screen } from '../../../components/hoc';
import OptionModel from '$gbusiness/models/option';

import CommonScreen from './common';
import CurrentStateModel from '$fbusiness/models/currentState';
import { configs } from '$configs';
import { excludeTabs } from '$gcomponents/reusables/table/utils';

interface InvoicesScreenProps {
  currentState: CurrentStateModel;
  columns;
}

const TTABS: Array<OptionModel> = [
  { label: 'SCREEN.INVOICES.TABS.OPEN', value: 'OPEN' },
  { label: 'SCREEN.INVOICES.TABS.PAID', value: 'PAID' },
  { label: 'SCREEN.INVOICES.TABS.VOID_PAYMENT', value: 'VOID' },
  { label: 'SCREEN.INVOICES.TABS.CANCELLED', value: 'CANCELLED' },
];

export const excludeColumns = (TABLE, styles) => {
  const exclusionKey = styles.exclusion;
  if (!exclusionKey) return TABLE;
  const exclusions = configs.excludeColumns ? configs.excludeColumns[exclusionKey] || [] : [];
  if (!exclusions.length) return TABLE;

  return TABLE.filter((cell) => !exclusions.includes(cell.value));
};

const InvoicesScreen: React.FC<InvoicesScreenProps> = ({ currentState, columns }) => {
  const TABS = excludeTabs(TTABS, 'invoices');
  return (
    <CommonScreen
      currentState={currentState}
      ttabs={TABS}
      columns={columns || []}
      defaultFilter={{
        query: '',
        tableTabIndex: 0,
        pickupOnly: currentState.settings?.pickupOnly,
        // confirmedAt: true,
        storeFlag: 1,
        status: TABS[0].value,
        received: 1,
        forceRefresh: false,
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  columns: state.localStorage.columns,
  resetOnRoute: true,
});

const connected = connect(mapStateToProps, null);

export default connected(screen(InvoicesScreen));
