import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { IonContent, IonPage } from '@ionic/react';

import { authActions } from '$gbusiness/redux/auth';
import { Header } from '$gcomponents/widgets';
import { screen } from '$gcomponents/hoc';
import { Div, FONT, SPACE } from '$gstyles';
import { sleep } from '$gbusiness/helpers/util';

interface VerifyTokenScreenProps {
  verifyToken: Function;
  screenState: string;
  currentState?: any;
  match: any;
  history: any;
}

const VerifyTokenScreen: React.FC<VerifyTokenScreenProps> = ({
  history,
  verifyToken,
  currentState = null,
  screenState,
  match,
}) => {
  const {
    params: { token, path: encodedPath },
  } = match;
  const path = decodeURIComponent(encodedPath);

  useEffect(() => {
    // if (!currentState?.isGuest) {
    //   console.log('IS GUEST', currentState);
    //   return;
    //   redirect(path);
    //   return;
    // }
    verifyTokenNow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyTokenNow = async () => {
    if (!token) return;
    await sleep(200);
    await verifyToken(token).then((response) => {
      if (response?.success) {
        redirect(path, true);
      }
    });
  };

  const redirect = (path, refresh = false) => {
    if (refresh) {
      window.location.href = path;
      return;
    }
    history.push(path);
  };

  const isLoaded = screenState !== '' && screenState !== undefined;

  // if (isLoaded && path) history.push(path);

  return (
    <IonPage id="forgot-screen">
      <Header title="SCREEN.VERIFY_EMAIL.TITLE" />
      <IonContent>
        <Div margin={SPACE.XXLARGE} fontSize={FONT.LARGE} className="center">
          {isLoaded && <>LOADED</>}
        </Div>
      </IonContent>
    </IonPage>
  );
};

const mapStateToProps = (state) => ({
  loading: state.loading,
  screenState: state.routing.screenState,
});

const mapDispatchToProps = {
  verifyToken: authActions.verifyToken,
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(VerifyTokenScreen));
