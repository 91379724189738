import { Dispatch } from 'redux';
import * as UpChunk from '@mux/upchunk';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import { deriveRawToFile, initialFile } from '../../models/file';
import {
  FileActionTypes,
  CLEAN_FILE,
  UPDATE_FILE_SUCCESS,
  FILE_FAILURE,
  DELETE_FILE_SUCCESS,
  RESET_FINISHED,
  FETCH_FILE_SUCCESS,
} from './types';
import loader from '$gcomponents/reusables/loader';
import intl from '$gintl';
import { sleep } from '$gbusiness/helpers/util';
import { toastDanger, toastSuccess, toastWarning } from '$gbusiness/redux/toaster/actions';

const CHUNK_THRESHOLD = 5120 * 4;
const CHUNK_SIZE_BIG = 2048 * 5;
const CHUNK_SIZE_MED = 2048 * 3;
const CHUNK_SIZE_SMALL = 2048;

export function fetchFile(id): any {
  return async (dispatch: Dispatch) => {
    if (!id || isNaN(id)) {
      dispatch({
        type: FETCH_FILE_SUCCESS,
        file: initialFile,
      });
      return;
    }

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.file.general + '/' + id,
      method: 'GET',
    });

    if (!response || !response?.data) {
      handleApiFail(dispatch, FILE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_FILE_SUCCESS,
      file: deriveRawToFile(response.data),
    });
  };
}

export function addFiles(files, moduleName = 'file'): any {
  return async (dispatch: Dispatch, getState) => {
    let current = 1;
    const max = files.length;
    const thisLoader = loader({ message: 'PROGRESS.FILE_UPLOAD', key: { current, max } });

    const url = `${process.env.REACT_APP_API_BASE}/${configs.api.file.general}`;
    const store = getState();
    const globalHeaders = store.init.headers;
    const { accessToken, userId } = store.localStorage;
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      ...(userId && { userId: userId?.toString() }),
      ...globalHeaders,
    };

    const succeeded: any = [];
    const failed: any = [];

    for (const fileData of files) {
      if (thisLoader) {
        thisLoader.message = intl('PROGRESS.FILE_UPLOAD', { current, max });
      }

      const formData = new FormData();

      const file: File = fileData.file;
      if (file && file.size > CHUNK_THRESHOLD * 1024) {
        await uploadLargeFile({
          file,
          data: {
            name: fileData.name,
            moduleName,
            description: fileData.description,
            storeIds: fileData.storeIds ? JSON.stringify(fileData.storeIds) : null,
          },
          headers,
          thisLoader,
          current,
          max,
        }).then((result: any) => {
          if (!result) failed.push(file.name);
          if (result?.id) succeeded.push(result.id);
        });
      } else {
        if (file) formData.append('file', file);
        else formData.append('youtubeLink', fileData.youtubeLink);
        formData.append('name', fileData.name || '');
        formData.append('moduleName', moduleName || '');
        formData.append('description', fileData.description);
        if (fileData.storeIds) formData.append('storeIds', JSON.stringify(fileData.storeIds));

        const result: any = await fetch(url, {
          method: 'POST',
          body: formData,
          referrer: '',
          headers,
        });

        if (result.status >= 200 && result.status < 300) {
          const smallResult = await result.json();
          succeeded.push(smallResult.data?.id);
        } else {
          failed.push(file.name);
        }
      }

      current++;
    }
    if (thisLoader) {
      await sleep(500);
      await thisLoader.dismiss();
    }

    if (succeeded.length && failed.length) {
      dispatch(
        toastWarning({
          text: intl('MESSAGE.FILE_RESULT', { numSuccess: succeeded.length, numFail: failed.length }),
        }),
      );
    } else if (succeeded.length) {
      dispatch(toastSuccess({ text: intl('MESSAGE.FILE_SUCCESS', { numSuccess: succeeded.length }) }));
    } else {
      dispatch(toastDanger({ text: intl('ERROR.FILE_UPLOAD_FAIL') }));
    }

    return Promise.resolve([succeeded, failed]);
  };
}

async function uploadLargeFile({ file, headers, thisLoader, data, current, max }) {
  return new Promise(function (resolve, reject) {
    const url = `${process.env.REACT_APP_API_BASE}/${configs.api.file.chunk}`;

    const chunkSize =
      file.size > CHUNK_SIZE_BIG * 10000
        ? CHUNK_SIZE_BIG
        : file.size > CHUNK_SIZE_MED * 10000
        ? CHUNK_SIZE_MED
        : CHUNK_SIZE_SMALL;
    const upload = UpChunk.createUpload({
      method: 'POST',
      endpoint: url,
      headers,
      attempts: 2,
      delayBeforeAttempt: 3,
      data,
      file,
      chunkSize, // Uploads the file in ~1 MB chunks
    });

    upload.on('error', (err) => {
      console.error('💥 🙀', err.detail);
      resolve(false);
    });

    upload.on('progress', (progress) => {
      const progressRate = parseFloat(progress.detail || 0).toFixed(1);
      if (progressRate === '100.0') {
        thisLoader.message = intl('PROGRESS.FILE_UPLOAD_CHUNK_SAVING', {
          current,
          max,
          progress: progressRate,
        });
      } else {
        thisLoader.message = intl('PROGRESS.FILE_UPLOAD_CHUNK', {
          current,
          max,
          progress: progressRate,
        });
      }

      //console.log(`So far we've uploaded ${progress.detail}% of this file.`);
    });

    upload.on('chunkSuccess', (result) => {
      const response = result.detail?.response;
      if (!response) return;
      const body = response.body ? JSON.parse(response.body) : false;
      if (!body || !body.data) return;
      resolve(body.data);
    });

    upload.on('success', (result) => {
      resolve(true);
    });
  });
}

export function updateFile(file): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.file.general + '/' + file.id,
      method: 'PUT',
      param: {
        name: file.name,
        description: file.description,
        storeIds: JSON.stringify(file.storeIds),
      },
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FILE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_FILE_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function deleteFile(id, noToast = false): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.file.general + (id ? '/' + id : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FILE_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_FILE_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
    }
  };
}

export function cleanupFiles(): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.file.clean,
      method: 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, FILE_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(
        dispatch,
        null,
        intl('MESSAGE.FILE_CLEAN_SUCCESS', { numFiles: response.total, numDeleted: response.deleted.length }),
      );
    }
  };
}

export function resetFinished(): FileActionTypes {
  return { type: RESET_FINISHED };
}

export function dehydrate(): FileActionTypes {
  return { type: CLEAN_FILE };
}
