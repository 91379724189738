import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import {
  ADD_INVOICE_PAYMENT,
  CLEAN_INVOICE,
  EMPTY_SUCCESS,
  FETCH_INVOICE_PAYMENTS,
  FETCH_INVOICE_SUCCESS,
  FETCH_PAYMENT_GROUP_SUCCESS,
  invoiceActionTypes,
  INVOICE_FAILURE,
  UPDATE_INVOICE_PAYMENT,
  UPDATE_INVOICE_SUCCESS,
} from './types';
import { deriveRawToInvoice } from '../../models/invoice';
import { ORDER_STATUS } from '../../enums/options/orderStatus';
import { deriveRawToinvoicePayment } from '../../models/invoicePayment';
import { toastSuccess } from '$gbusiness/redux/toaster/actions';
import { UPDATE_CREDIT_SUCCESS, UPDATE_OPEN_BALANCE } from '../store/types';
import { dialog } from '$gcomponents/reusables';
import { deriveRawToStore } from '../../models/store';
import { LOADED } from '$gbusiness/redux/loading/types';
import { deriveRawToinvoicePaymentGroup } from '../../models/invoicePaymentGroup';

export function fetchInvoice(id): any {
  return async (dispatch: Dispatch) => {
    if (!id) return;
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.invoice.general + '/' + id,
      method: 'GET',
      // mockData: mockInvoiceDetails,
    });

    if (!response || !response?.data) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    const invoice = deriveRawToInvoice(response.data);
    dispatch({
      type: FETCH_INVOICE_SUCCESS,
      invoice,
    });

    return Promise.resolve(invoice);
  };
}

export function fetchInvoices(param): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.invoice.list + '?',
      method: 'POST',
      param,
    });

    if (!response || !response?.list) {
      return;
    }

    dispatch({
      type: LOADED,
    });

    return Promise.resolve(response.list.map(deriveRawToInvoice));
  };
}

export function adjustItemQuantities(items): any {
  return async (dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.invoice.itemsUpdate,
      param: {
        items,
      },
      method: 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, null, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function updateInvoice(invoice): any {
  return async (dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.invoice.general + '/' + invoice.id,
      param: {
        ...invoice,
        ...(invoice.status && { status: invoice.status }),
        ...(invoice.statusFactory && { statusFactory: invoice.statusFactory }),
        ...(invoice.note && { note: invoice.note }),
        ...(invoice.noteFactory && { noteFactory: invoice.noteFactory }),
        ...(invoice.totalAdjusted && { totalAdjusted: invoice.totalAdjusted }),
        ...(invoice.detailedData && { detailedData: invoice.detailedData }),
      },
      method: 'PUT',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_INVOICE_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');

      const store = deriveRawToStore(response.data?.store);
      dispatch({
        type: UPDATE_OPEN_BALANCE,
        store,
      });
    }
  };
}

export function createInvoice(param, finish = false, silent = false): any {
  return async (dispatch: Dispatch) => {
    if (!silent) dispatchLoading(dispatch, 'PROGRESS.SAVING');
    const response = await fetchApi({
      url: configs.api.invoice.general,
      param,
      method: 'POST',
    });
    if (silent) return;

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      if (finish) handleApiSuccess(dispatch, null, 'MESSAGE.SAVE_SUCCESS', 'small');
      dispatch({
        type: UPDATE_INVOICE_SUCCESS,
        invoice: response.data,
      });

      const store = deriveRawToStore(response.data?.store);
      dispatch({
        type: UPDATE_OPEN_BALANCE,
        store,
      });

      if (response.message) {
        dialog.alert({
          title: 'MESSAGE.ERROR',
          text: response.message,
        });
      }
    }
  };
}

export function closeInvoice(id, items, isFlagged, noteStore): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.invoice.close,
      param: {
        id,
        noteStore,
        items,
        status: isFlagged ? ORDER_STATUS.FLAGGED : ORDER_STATUS.CLOSED,
      },
      method: 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_INVOICE_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function editInvoicePayment(invoicePayment, index) {
  if (index < 0) {
    return {
      type: ADD_INVOICE_PAYMENT,
      invoicePayment,
    };
  }
  return {
    type: UPDATE_INVOICE_PAYMENT,
    invoicePayment,
    index,
  };
}

export function deleteInvoicePayment(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.LOADING');

    const response = await fetchApi({
      url: configs.api.invoice.payment + '/' + id,
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      dispatch(toastSuccess({ key: 'MESSAGE.DELETE_SUCCESS', className: 'small' }));
      if (response.list) {
        dispatch({
          type: FETCH_INVOICE_PAYMENTS,
          invoicePayments: response.list.map(deriveRawToinvoicePayment),
        });
      }

      if (response.invoice) {
        const store = deriveRawToStore(response.invoice.store);
        dispatch({
          type: UPDATE_OPEN_BALANCE,
          store,
        });
      }
    }
  };
}

export function createInvoicePayment(param): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.invoice.payment,
      method: 'POST',
      param,
    });
    return Promise.resolve(response?.success);
  };
}

export function createInvoiceCharge(param): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.invoice.charge,
      method: 'POST',
      param,
    });
    return Promise.resolve(response?.success);
  };
}

export function updateInvoiceCharge(param): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.invoice.charge + `/${param.id || 0}`,
      method: 'PUT',
      param,
    });
    return Promise.resolve(response?.success);
  };
}

export function saveInvoicePayment(param): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);

    // const id = index === null ? 0 : payment?.id;
    const response = await fetchApi({
      url: configs.api.invoice.payments,
      method: 'POST',
      param,
      // mockData: mockInvoiceDetails,
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SERVER', true);
    }
    dispatch(
      toastSuccess({
        key: 'MESSAGE.SAVE_SUCCESS',
        // ...(response.leftover && {
        //   duration: 8000,
        //   text: `Payment saved with ${currency(response.leftover)} toward store credit`,
        // }),
        className: 'small',
      }),
    );

    if (response.store) {
      const store = deriveRawToStore(response.store);
      dispatch({
        type: UPDATE_OPEN_BALANCE,
        store,
      });
      // if (credits) {
      //   dispatch({
      //     type: UPDATE_CREDIT_SUCCESS,
      //     credits,
      //     storeId,
      //   });
      // }
      return Promise.resolve(response.store);
    }
  };
}

export function fetchInvoicePayments(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.invoice.payment + '?invoiceId=' + id,
      method: 'GET',
      // mockData: mockInvoiceDetails,
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_INVOICE_PAYMENTS,
      invoicePayments: response.list.map(deriveRawToinvoicePayment),
    });
  };
}

export function fetchPaymentGroup(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.invoice.paymentGroup + '/' + id,
      method: 'GET',
      // mockData: mockInvoiceDetails,
    });

    if (!response || !response?.data) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_PAYMENT_GROUP_SUCCESS,
      paymentGroup: deriveRawToinvoicePaymentGroup(response.data),
    });
  };
}

export function rejectPayment(paymentGroupId): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.invoice.rejectPayment + '/' + paymentGroupId,
      method: 'GET',
      // mockData: mockInvoiceDetails,
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_INVOICE_SUCCESS, 'MESSAGE.REJECT_SUCCESS', 'small');
    }
  };
}

export function getPaymentInvoices(paymentGroupId): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.invoice.paymentInvoices + '/' + paymentGroupId,
      method: 'GET',
      // mockData: mockInvoiceDetails,
    });

    if (!response || !response?.success) {
      return Promise.resolve(null);
    } else {
      return Promise.resolve(response?.list);
    }
  };
}

export function downloadInvoice(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.invoice.download + '/' + id,
      method: 'GET',
      // mockData: mockInvoiceDetails,
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    } else {
      handleApiSuccess(dispatch, UPDATE_INVOICE_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function voidInvoice(id): any {
  return async (dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.invoice.void + '/' + id,
      method: 'POST',
    });

    if (!response || !response?.success || !response?.store) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, null, 'MESSAGE.VOID_SUCCESS', 'small');
      const store = deriveRawToStore(response?.store);
      dispatch({
        type: UPDATE_OPEN_BALANCE,
        store,
      });
    }
  };
}

export function emailInvoice(invoiceId, email): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.invoice.email + '/' + invoiceId,
      param: {
        email,
      },
      method: 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, EMPTY_SUCCESS, 'MESSAGE.EMAIL_SENT');
    }
  };
}

export function applyRefund(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.invoice.applyRefund,
      method: 'POST',
      param: { id },
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    } else {
      handleApiSuccess(dispatch, null, 'MESSAGE.REFUND_APPLIED', 'small');
      dispatch({
        type: UPDATE_CREDIT_SUCCESS,
        credits: response.credits,
        storeId: response.storeId,
      });
    }
  };
}

export function deleteRefund(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.refund + '/' + id,
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, INVOICE_FAILURE, response, 'ERROR.SERVER', true);
      return;
    } else {
      handleApiSuccess(dispatch, null, 'MESSAGE.DELETE_SUCCESS', 'small');
      return Promise.resolve(true);
    }
  };
}

export function dehydrate(): invoiceActionTypes {
  return { type: CLEAN_INVOICE };
}
