import React, { useEffect, useState } from 'react';
import { IonContent } from '@ionic/react';
import { useDispatch, useSelector } from 'react-redux';

import FactoryHeader from '$fcomponents/header';
import { TableView2, Header as FixedHeader } from '$gcomponents/widgets';
import OptionModel from '$gbusiness/models/option';

import { INVOICES_TABLE_CONFIG, INVOICES_TABLE, defaultInvoiceColumns } from './invoicesTable';
import { INVOICES } from '$fbusiness/enums/columns';
import { IonPageWrapper } from './styles';
import InvoiceModel from '$fbusiness/models/invoice';
import ViewShippingModal from './viewShippingModal';
import InvoiceDetailsModal from './invoiceDetailsModal';
import CloseOrderModal from './closeOrderModal';
import FactoryModel from '$fbusiness/models/factory';
import { invoiceActions } from '$fbusiness/redux/invoice';
import { INVOICE_STATUS } from '$fbusiness/enums/options/invoiceStatus';
import { dialog } from '$gcomponents/reusables';
import intl from '$intl';
import CurrentStateModel from '$fbusiness/models/currentState';
import { configs } from '$configs';
import FilterSection from '$gcomponents/widgets/tableView2/filterSection';
import ReportFilter from '$fcomponents/filters/reportFilter';
import StoreModel from '$fbusiness/models/store';
import { INVOICE_PAYMENTS_TABLE_MIN } from './innerTable';

interface CommonScreenProps {
  isReceiving?: boolean;
  currentState: CurrentStateModel;
  ttabs?: Array<OptionModel>;
  columns?: Array<any>;
  defaultFilter: any;
}

const CommonScreen: React.FC<CommonScreenProps> = ({
  currentState,
  columns = [],
  isReceiving = false,
  ttabs = [],
  defaultFilter,
}) => {
  const [filter, setFilter] = useState<any>(defaultFilter);
  const [showCloseOrder, setShowCloseOrder] = useState(false);
  const [showViewInvoice, setShowViewInvoice] = useState(false);
  const [showViewShipping, setShowViewShipping] = useState(false);

  const dispatch = useDispatch();
  const invoice: InvoiceModel = useSelector((state: any) => state.invoice.invoice);
  const factory: FactoryModel = useSelector((state: any) => state.factory.factory);
  const stores: Array<StoreModel> = useSelector((state: any) => state.store.stores);
  const isFinished = useSelector((state: any) => state.invoice.isFinished);

  const title = isReceiving ? 'SCREEN.INVOICES.RECEIVING' : 'SCREEN.INVOICES.TITLE';
  const { settings } = factory;
  const { hidePrice, isSalesman } = currentState;

  useEffect(() => {
    onCloseModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinished]);

  const updateFilter = (newFilter) => {
    setFilter({
      ...filter,
      ...newFilter,
      forceRefresh: !filter.forceRefresh,
    });
  };

  const tableTabs = ttabs.length
    ? {
        TABS: ttabs,
        index: filter?.tableTabIndex,
        onChange: (newTableIndex) => {
          updateFilter({ ...filter, tableTabIndex: newTableIndex, status: ttabs[newTableIndex].value });
        },
        variant: 'outlined',
        color: 'primary' as 'primary' | 'secondary',
        itemWidth: '60px',
      }
    : undefined;

  const onFetchInvoice = async (id) => {
    await dispatch(invoiceActions.fetchInvoice(id));
  };
  const onCloseOrder = async (row) => {
    await onFetchInvoice(row.id);
    setShowCloseOrder(true);
  };
  const onCloseInvoice = () => {
    updateFilter({});
  };
  const onViewInvoice = async (row) => {
    await onFetchInvoice(row.id);
    setShowViewInvoice(true);
  };
  const onViewShipping = async (row) => {
    await onFetchInvoice(row.id);
    setShowViewShipping(true);
  };
  const onChangeStatus = (row, status) => {
    if (status === INVOICE_STATUS.PAYMENT_SENT) {
      onViewInvoice(row);
      return;
    }
    dialog.confirm({
      title: 'MESSAGE.WAIT',
      message: 'MESSAGE.INVOICE_STATUS_WARNING',
      key: { status: intl('INPUT.OPTION.INVOICE_STATUS.' + status) },
      cssClass: 'large',
      onPress: async () => {
        await dispatch(invoiceActions.updateInvoice({ id: row.id, status }));
        updateFilter({});
      },
    });
  };
  const onCloseModal = async (refresh = false) => {
    dispatch(invoiceActions.dehydrate());
    setShowViewShipping(false);
    setShowCloseOrder(false);
    setShowViewInvoice(false);
    if (refresh === true) updateFilter({});
  };

  const Header =
    configs.display.layout === 'FIXED_HEADER' ? (
      <FactoryHeader title={title} />
    ) : (
      <FixedHeader title={title} />
    );

  const onChangeStore = (store) => {
    updateFilter({ storeId: store?.id || '' });
  };

  // console.log('IS SALESMAN', isSalesman);
  const invoiceColumns = columns ? columns[INVOICES] || defaultInvoiceColumns : [];

  return (
    <IonPageWrapper>
      {Header}
      <IonContent>
        <TableView2
          filter={filter}
          tableConfig={INVOICES_TABLE_CONFIG}
          columns={invoiceColumns}
          columnKey={INVOICES}
          INNER_TABLE={INVOICE_PAYMENTS_TABLE_MIN()}
          TABLE={INVOICES_TABLE({
            onCloseOrder,
            onViewInvoice,
            onViewShipping,
            onChangeStatus,
            isReceiving,
            isPickupOnly: settings?.pickupOnly,
            hidePrice,
            isSalesman,
          })}
          tabs={tableTabs}>
          <FilterSection title={null}>
            <ReportFilter
              excludeDates
              parentFilter={filter}
              onChangeStore={onChangeStore}
              stores={isSalesman ? stores : undefined}
              onSearch={updateFilter}
              placeholder="INPUT.PLACEHOLDER.INVOICE_SEARCH"
            />
          </FilterSection>
        </TableView2>
        <CloseOrderModal
          invoice={invoice}
          onCloseInvoice={onCloseInvoice}
          show={invoice && showCloseOrder}
          onClose={onCloseModal}
        />
        <InvoiceDetailsModal
          factory={factory}
          invoice={invoice}
          currentState={currentState}
          show={invoice && showViewInvoice}
          onClose={onCloseModal}
        />
        <ViewShippingModal
          currentState={currentState}
          invoice={invoice}
          show={invoice && showViewShipping}
          onClose={onCloseModal}
        />
      </IonContent>
    </IonPageWrapper>
  );
};

export default CommonScreen;
