import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import { deriveRawToThread } from '../../models/thread';
import {
  ThreadActionTypes,
  FETCH_THREAD_SUCCESS,
  CLEAN_THREAD,
  THREAD_FAILURE,
  CREATE_THREAD_SUCCESS,
  DELETE_THREAD_SUCCESS,
  FETCH_THREADS_SUCCESS,
  REPLY_THREAD_SUCCESS,
} from './types';

export function fetchThreads(): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.thread.general,
      method: 'GET',
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, THREAD_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_THREADS_SUCCESS,
      threads: response.list.map((d) => deriveRawToThread(d)),
    });
  };
}

export function fetchThread(threadId, messageId = 0): any {
  return async (dispatch: Dispatch) => {
    if (!threadId && !messageId) {
      dispatch({
        type: FETCH_THREAD_SUCCESS,
        thread: null,
      });
      return;
    }

    dispatchLoading(dispatch);
    const url = threadId
      ? configs.api.thread.general + '/' + threadId
      : configs.api.thread.byMessage + '/' + messageId;
    const response = await fetchApi({
      url,
      method: 'GET',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, THREAD_FAILURE, response, 'ERROR.SAVE', true);
      return;
    }

    dispatch({
      type: FETCH_THREAD_SUCCESS,
      thread: deriveRawToThread(response.data),
    });

    return Promise.resolve(response.data);
  };
}

export function saveThread(param): any {
  return async (dispatch: Dispatch, getState) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.thread.general,
      method: 'POST',
      param,
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, THREAD_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, CREATE_THREAD_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
      return Promise.resolve(true);
    }
  };
}

export function updateThread(id, param): any {
  return async (dispatch: Dispatch, getState) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.thread.general + '/' + id,
      method: 'PUT',
      param,
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, THREAD_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, CREATE_THREAD_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
      return Promise.resolve(true);
    }
  };
}

export function replyThread(param): any {
  return async (dispatch: Dispatch, getState) => {
    dispatchLoading(dispatch, 'PROGRESS.SAVING');

    const response = await fetchApi({
      url: configs.api.thread.reply,
      method: 'POST',
      param,
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, THREAD_FAILURE, response, 'ERROR.SAVE', true);
      return Promise.resolve(false);
    } else {
      if (response.data) {
        dispatch({
          type: REPLY_THREAD_SUCCESS,
          thread: deriveRawToThread(response.data),
        });
        handleApiSuccess(dispatch, CREATE_THREAD_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
      }
      return Promise.resolve(true);
    }
  };
}

export function deleteThread(threadId): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.thread.general + (threadId ? '/' + threadId : ''),
      method: 'DELETE',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, THREAD_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_THREAD_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
    }
  };
}

export function markRead(threadId): any {
  return async () => {
    await fetchApi({
      url: configs.api.thread.markRead,
      method: 'POST',
      param: { threadId },
    });
    return Promise.resolve(true);
  };
}

export function dehydrate(): ThreadActionTypes {
  return { type: CLEAN_THREAD };
}
