import _ from 'lodash';
import CartItemModel from '../../models/cartItem';
import CartModel, { defaultCart } from '../../models/cart';
import { TAX_TYPE } from '../../enums/options/taxType';
import { initialPrice } from '../../models/price';
import { roundDecimal, toNumber } from '$gbusiness/helpers/util';
import { apiService } from '$gbusiness/services';
import { configs } from '$configs';
import { deriveRawToItem } from '../../models/item';

export function getFactoryCart(cart, factoryId) {
  const cartFactoryId = Number(cart?.factoryId || 0);
  return cartFactoryId === factoryId ? cart : defaultCart;
}

export function getModifiersFromCart(cart, productIndex) {
  if (!cart.products[productIndex]) return [];
  return cart.products[productIndex].modifiers || [];
}

export function getModifierFromCart(cart, productIndex, modifierIndex) {
  const modifiers = getModifiersFromCart(cart, productIndex);
  if (!modifiers.length || !modifiers[modifierIndex]) return undefined;
  return modifiers[modifierIndex];
}

export function getItemIds(cart) {
  return (cart?.products || []).reduce((acc, p) => {
    if (!acc.includes(p.id)) acc.push(p.id);
    return acc;
  }, []);
}

export function syncCartItem(product, item, store) {
  if (!item) return product;
  const {
    id,
    name,
    image,
    rawCost,
    wholesalePrice,
    retailPrice,
    sku,
    categories,
    description,
    barcode,
    tax,
    chargeSalesTax,
    settings,
    modifiers,
    quantity,
  } = item;
  const taxRate = getTaxPercent(tax, store);
  const unitPrice = getItemPrice(item, store);
  return {
    ...product,
    id,
    name,
    sku,
    barcode,
    category: categories.map((c) => c.name).join(),
    description,
    image,
    quantity,
    modifiers: modifiers || [],
    ...(item.quantity !== null && product.qty > item.quantity && { qty: item.quantity }),
    price: {
      retail: toNumber(retailPrice),
      original: unitPrice,
      chargeSalesTax: chargeSalesTax ? true : false,
      taxPercent: taxRate,
      rawCost,
      wholesalePrice,
      retailPrice,
    },
    pricing: {
      ...product.pricing,
      taxRate,
      unitPrice,
    },
    settings: {
      ...settings,
      isRefund: !!product.settings?.isRefund,
    },
  };
}

export function syncCartItems(cart, items, store) {
  const { products } = cart;
  return products.map((p) => {
    const item = items.find((i) => i.id === p.id);
    return syncCartItem(p, item, store);
  });
}

export function syncItemsQty(cart, items) {
  const { products } = cart;
  return products.reduce((acc, p) => {
    const item = items.find((i) => i.id === p.id);
    if (!item) return acc;
    acc[p.id || p.itemId] = item.quantity;
    return acc;
  }, {});
}

export function changeField(list, index, key, value) {
  if (!list.length) return list;

  return list.map((item, i) => {
    if (i !== index) return item;
    else
      return {
        ...item,
        [key]: value,
      };
  });
}

export function changeModifierField(list, msindex, mindex, key, value) {
  if (!list.length) return list;

  return list.map((m) => {
    if (m.modifierSetIndex !== msindex || m.modifierIndex !== mindex) return m;
    else
      return {
        ...m,
        [key]: value,
      };
  });
}

export function mergeObject(list, obj, defaultIndex) {
  if (!list.length) return list;

  const index = defaultIndex < 0 ? list.length - 1 : defaultIndex;
  return list.map((item, i) => {
    if (i !== index) return item;
    else return _.merge(item, obj);
  });
}

export function calculateItemPriceFromList(list) {
  if (!list.length) return list;

  const item = list[list.length - 1];
  return calculateItemPrice(item);
}

export function deriveModifierPrice(modifier, baseOnly = false) {
  if (!modifier) return 0;
  const { qty, price } = modifier;
  let total = 0;
  if (price > 0) {
    return total + (price || 0) * qty;
  }
  return total + 0;
}

export function calculateModifierSums(modifiers) {
  if (!modifiers || !modifiers.length) return 0;

  return modifiers.reduce((acc, modifier) => {
    const modifierPrice = deriveModifierPrice(modifier);
    acc = acc + toNumber(modifierPrice);
    return acc;
  }, 0);
}

export function calculateTaxAmount({ price = 0, discount = 0, discountRate = 0, taxRate = 0 }) {
  if (discount) return Math.round((price - discount) * taxRate) / 100;
  if (discountRate) return Math.round((price - (price * discountRate) / 100) * taxRate) / 100;
  return Math.round(price * taxRate) / 100;
}

export function calculateDiscountAmount({ price = 0, discountRate = 0 }) {
  return Math.round(price * discountRate) / 100;
}

export function calculateItemPrice(item: CartItemModel, dcRate = 0, store = null) {
  const { price, pricing, qty } = item;
  const { taxPercent, rawCost, wholesalePrice, retailPrice } = price;
  const disableDiscount = item.settings?.disableDiscount;
  const discountRate = disableDiscount ? 0 : dcRate;
  const modifierPrice = calculateModifierSums(item.modifiers);
  const original = store ? getItemPrice(price, store) : pricing.unitPrice || item.price.original || 0;
  const delivery = item.price.delivery || 0;
  const unitPrice: number = modifierPrice + original;
  const unitDiscount = calculateDiscountAmount({ price: unitPrice, discountRate });
  const subtotalSum: number = unitPrice * item.qty;
  const unitTax: number = calculateTaxAmount({
    price: unitPrice,
    discount: unitDiscount,
    taxRate: taxPercent,
  });
  const taxSum: number = unitTax * item.qty;
  const singleTotal = unitPrice - unitDiscount + unitTax + delivery;
  const discountSum = unitDiscount * item.qty;
  const deliverySum = delivery * item.qty;
  const totalSum = subtotalSum - discountSum + taxSum + deliverySum;

  // NEW PRICING
  const { taxRate } = pricing;
  const discount = unitDiscount * qty;
  const subtotal = unitPrice * qty;
  const taxable = subtotal - discount;
  const tax = (taxable * (taxRate || 0)) / 100;
  const total = taxable + tax;

  return {
    ...item,
    price: {
      taxPercent,
      discount: unitDiscount,
      delivery,
      original,
      subtotal: unitPrice,
      tax,
      taxable,
      singleTotal,
      subtotalSum,
      taxSum,
      discountSum,
      deliverySum,
      totalSum,
      rawCost,
      wholesalePrice,
      retailPrice,
    },
    pricing: {
      ...pricing,
      discountRate,
      unitTax,
      unitPrice,
      subtotal,
      discount,
      taxable,
      tax,
      total,
      qty,
    },
  };
}

export function isTwoSame(item, item2) {
  const distinguisher = modifierDistinguisher(item, item2);
  if (distinguisher) {
    item.description = distinguisher;
    return false;
  }
  if (item2?.settings?.isRefund !== item?.settings?.isRefund) {
    return false;
  }
  return true;
}

function findExactDuplicateIndex(list, item) {
  return list.findIndex((p) => {
    if ('itemId' in p) {
      if (!p.itemId && !item.itemId) return false;
      if (p.itemId !== item.itemId) return false;
    } else {
      if (p.id !== item.id) return false;
    }
    return isTwoSame(p, item);
  });
}

export function reconcileItems(products: any, qtyKey = 'qty') {
  const newProducts = products.reduce((acc: any[], p) => {
    const exactDupIndex = findExactDuplicateIndex(acc, p);

    if (exactDupIndex >= 0) {
      if (p.serials && p.serials.length) {
        const serial = p.serials[0];
        // Do nothing if serial already exists
        if (acc[exactDupIndex].serials.includes(serial)) return acc;
        acc[exactDupIndex].serials.push(serial);
      }
      acc[exactDupIndex][qtyKey] = acc[exactDupIndex][qtyKey] + p[qtyKey];
      return acc;
    }

    acc.push({ ...p, serials: p.serials || [] });
    return acc;
  }, []);

  return newProducts;
}

export function reconcileSerials(products: any) {
  const mySerials: any = [];
  const newProducts = products.reduce((acc: any[], p) => {
    if (p.serials && p.serials.length) {
      const thisItemSerials = p.serials.filter((s) => {
        if (mySerials.includes(s)) return false;
        mySerials.push(s);
        return true;
      });
      if (!thisItemSerials.length) return acc;
      acc.push({ ...p, serials: thisItemSerials });
      return acc;
    }
    acc.push(p);
    return acc;
  }, []);

  return newProducts;
}

function calculateOtherFees({
  price,
  taxRate,
  discount,
  delivery = 0,
  shipping = 0,
  tip = 0,
  otherDiscount: oDiscount = 0,
}) {
  const flatDiscount = discount.isFlat ? toNumber(discount.amount) || 0 : 0;
  const otherDiscount = flatDiscount + oDiscount;
  const adjustedOtherDiscount = Math.min(otherDiscount, price.itemTaxable);

  const shippingTax = Math.round(shipping * taxRate) / 100;
  const otherDiscountTax = Math.round(adjustedOtherDiscount * taxRate) / 100;

  const otherTax = shippingTax - otherDiscountTax;
  const taxable = price.itemTaxable + shipping - adjustedOtherDiscount;
  const totalDiscount = price.itemDiscount + adjustedOtherDiscount;
  const tax = price.itemTax + otherTax;
  const total = taxable + tax + tip + delivery;

  const savedRate = price.subtotal ? roundDecimal((totalDiscount / price.subtotal) * 100) : 0;

  return {
    ...price,
    delivery,
    shipping,
    otherDiscount,
    totalDiscount,
    taxable,
    otherTax,
    tax,
    tip,
    total,
    savedRate,
  };
}

function applyOtherFees(itemPrices, pricing, newTaxRate) {
  const { po, refund } = itemPrices;
  const { delivery, tip, otherDiscount, discount, shipping, refundDiscount } = pricing;
  const discountRate = discount.isFlat ? 0 : toNumber(discount.amount);
  const refundDiscountRate = refundDiscount.isFlat ? 0 : toNumber(refundDiscount.amount);
  const taxRate = (newTaxRate !== null ? newTaxRate : pricing.taxRate) || 0;

  const poPricing = calculateOtherFees({
    price: po,
    taxRate,
    discount,
    shipping,
    delivery,
    tip,
    otherDiscount,
  });
  const refundPricing = calculateOtherFees({
    price: refund,
    taxRate,
    discount: refundDiscount,
  });

  return {
    ...pricing,
    po: {
      ...poPricing,
      discountRate,
    },
    refund: {
      ...refundPricing,
      discountRate: refundDiscountRate,
    },
    final: {
      ...po,
      discountRate,
      subtotal: poPricing.subtotal - refundPricing.subtotal,
      itemDiscount: poPricing.itemDiscount - refundPricing.itemDiscount,
      itemTaxable: poPricing.taxable - refundPricing.itemTaxable,
      itemTax: poPricing.itemTax - refundPricing.itemTax,
      itemTotal: poPricing.itemTotal - refundPricing.itemTotal,
      taxable: poPricing.taxable - refundPricing.taxable,
      tax: poPricing.tax - refundPricing.tax,
      total: poPricing.total - refundPricing.total,
      savedRate:
        ((poPricing.totalDiscount - refundPricing.totalDiscount) /
          (poPricing.subtotal - refundPricing.subtotal)) *
        100,
    },
    taxRate,
    discountRate,
  };
}

export function calculateCartPrice(cart: CartModel, getState: any = null) {
  const store = getState ? getState().store?.store || getState().factory?.store : null;
  const taxRate = store ? Number(store.settings?.taxPercent || null) : null;

  const newProducts: Array<CartItemModel> = [];
  const { pricing = defaultCart.pricing } = cart;
  const { discount, refundDiscount } = pricing;
  const itemPrices = cart.products.reduce(
    (acc, item) => {
      if (!item.isAdded) return acc;
      const isRefund = !!item.settings?.isRefund;
      const discountRate = isRefund
        ? refundDiscount?.isFlat
          ? 0
          : toNumber(refundDiscount.amount)
        : discount?.isFlat
        ? 0
        : toNumber(discount.amount);
      const modifiedItem = calculateItemPrice(item, discountRate, store);
      newProducts.push(modifiedItem);
      const { discountSum, subtotalSum, taxSum, totalSum, taxable } = modifiedItem.price;

      const key = isRefund ? 'refund' : 'po';
      acc[key].qty = acc[key].qty + item.qty;
      acc[key].subtotal = acc[key].subtotal + subtotalSum;
      acc[key].itemDiscount = acc[key].itemDiscount + discountSum;
      acc[key].itemTaxable = acc[key].itemTaxable + taxable;
      acc[key].itemTax = acc[key].itemTax + taxSum;
      acc[key].itemTotal = acc[key].itemTotal + totalSum;

      return acc;
    },
    { po: { ...initialPrice }, refund: { ...initialPrice } },
  );
  const newPricing = applyOtherFees(itemPrices, pricing, taxRate);
  const updatedCart = {
    ...cart,
    products: reconcileItems(newProducts),
    pricing: newPricing,
  };
  return updateCartStatus(updatedCart);
}

export const modifierDistinguisher = (item1, item2) => {
  if (item1.id !== item2.id) return null;
  if (item1.modifiers?.length !== item2.modifiers?.length) return null;
  const diffModifier = item1.modifiers.find((m, i) => {
    const m2 = item2.modifiers[i];
    if (m.modifierId !== m2.modifierId || m.qty !== m2.qty || m.price !== m2.price) return true;
    return false;
  });
  if (diffModifier) return diffModifier?.name;
  if (item1.instruction !== item2.instruction) return 'instruction';
  return '';
};

export const getTaxPercent = (tax, store) => {
  if (!tax) return 0;
  const { amount, taxType } = tax;
  if (taxType === TAX_TYPE.STORE) return Number(store?.settings?.taxPercent || 0);
  return amount;
};

export const getItemPrice = (item, store, invoiceId = 0) => {
  if (!item) return 0;
  const { retailPrice, wholesalePrice, unitPrice } = item;
  if (invoiceId && unitPrice) return unitPrice;
  if (store?.settings?.wholesale === '1') return toNumber(wholesalePrice);
  return toNumber(retailPrice);
};

export async function syncCart(cart, store) {
  const ids = getItemIds(cart);
  let syncedCart = cart;
  if (ids.length) {
    const response = await apiService.fetchApi({
      url: configs.api.item.selected,
      param: { ids },
    });
    const items = (response?.list || []).map(deriveRawToItem);
    syncedCart = {
      ...cart,
      stock: syncItemsQty(cart, items),
      products: syncCartItems(cart, items, store),
    };
  }
  return syncedCart;
}

export const updateCartStatus = (cart) => {
  const { products } = cart;
  let hasError = false;
  const updatedProducts = products.map((p) => {
    if (checkItemStock(cart, p.id)) {
      const { error, ...rest } = p;
      return rest;
    }
    hasError = true;
    return {
      ...p,
      error: {
        stock: cart.stock[p.id],
      },
    };
  });
  return {
    ...cart,
    hasError,
    products: updatedProducts,
  };
};

export const checkCartStock = (cart) => {
  const updatedCart = updateCartStatus(cart);
  return !updatedCart.hasError;
};

export const checkItemStock = (cart, itemId) => {
  if (!cart?.stock) return true;
  return checkStockHelper(cart.products, itemId, cart.stock[itemId]);
};

export const checkStockHelper = (items, id, stock, qtyKey = 'qty') => {
  if (stock === null || stock === undefined) return true;
  if (stock <= 0) return false;
  const itemQtyOnCart = items.reduce((acc, p) => {
    return (acc += p?.itemId === id || (!p.itemId && p.id === id && !p?.settings?.isRefund) ? p[qtyKey] : 0);
  }, 0);
  if (itemQtyOnCart <= stock) return true;
  return false;
};

export const itemToCartItem = (item, customQuantity = 0, store): CartItemModel => {
  const qty = customQuantity || item.quantity;
  return {
    ...syncCartItem({}, item, store),
    qty,
    instruction: item.instruction || '',
    isAdded: true,
  };
};

// export const modifierToCartModifier = ({ mgId, id, name, qty, price }) => ({
//   mgId,
//   id,
//   name,
//   qty,
//   price: {
//     original: price,
//   },
// });
