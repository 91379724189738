import { toLocal } from '$gbusiness/helpers/date';
import UserModel, { deriveRawToUser } from '$gbusiness/models/user';
import { REFUND_STATUS } from '../enums/options/refundStatus';
import OrderInvoice, { deriveRawToOrderInvoice } from './orderInvoice';
import { deriveRawToRefundDetails } from './refundDetails';
import StoreModel from './store';

export default interface RefundModel extends OrderInvoice {
  qty: number;
  note: string;
  balance: number;
  paidAmount?: number;
  requestDate: string;
  refundedAt?: string;
  untouchable?: boolean;
  poNo?: string;
  isCredit?: boolean;
  isRc?: boolean;
  simple?: boolean;
  invoiceNumber?: string;
  payment?: string;
  user: UserModel;
  store: StoreModel;
  items: Array<any>;
}

const deriveStatus = (raw) => {
  if (raw.confirmedAt) return REFUND_STATUS.CANCELLED;
  if (raw.refundedAt) return REFUND_STATUS.PROCESSED;
  return REFUND_STATUS.PREPARING;
};

export const deriveRawToRefund = (raw) => {
  if (!raw) return null;

  return {
    ...deriveRawToOrderInvoice(raw),
    status: deriveStatus(raw),
    note: raw.note,
    requestDate: raw.requestDate,
    refundedAt: toLocal(raw.refundedAt),
    balance: raw.balance || 0,
    isCredit: !!raw.isCredit,
    isRc: !!raw.isRc,
    untouchable: (!!raw.isCredit && (raw.note || '').includes('REFUND #R')) || !raw.isRc,
    simple: !!raw.simple,
    payment: raw.payment || '',
    store: raw.store,
    poNo: raw.poNo,
    invoiceNumber: raw.invoiceNumber,
    user: deriveRawToUser(raw.user),
    paidAmount: raw.paidAmount || 0,
    totals: {
      finalTotal: raw.pricing?.finalTotal || 0,
      finalBalance: raw.pricing?.finalBalance || 0,
      refundCreditSum: raw.pricing?.refundCreditSum || 0,
      invoiceChargeSum: raw.pricing?.invoiceChargeSum || 0,
    },
    items: (raw.items || []).map((i) => deriveRawToRefundDetails(i)),
  };
};

export const toRefundId = (id) => {
  if (id) return `R${id}`;
  return '';
};

// DATA GENERATOR json-generator.com
//
// {
//   totalSize: 55,
//   list: [
//     '{{repeat(5, 20)}}',
//     {
//       id: "{{index() + 1}}",
//       orderNumber: "{{integer(10000, 99999)}}",
//       storeId: 1,
//       storeName: "Cloudhood Bakery",
//       factoryId: 1,
//       transactionId: 0,
//       orderTypeId: 1,
//       status: "{{random('SHIPPED', 'PREPARING')}}",
//       subtotal: "{{floating(100, 5100, 2)}}",
//       tax: function (t) { return Number((this.subtotal * 0.6).toFixed(2)); },
//       total: function (t) { return Number((this.subtotal + this.tax).toFixed(2)); },
//       qty: "{{integer(4, 200)}}",
//       qtySent: function (t) { return this.qty - t.integer(0, 2); },
//       qtyReceived: function (t) { return this.qtySent - t.integer(0, 2); },
//       discount: 0,
//       note: function (t) {
//         if (t.integer(0, 10) > 8) return t.lorem();
//         else return '';
//       },
//       userName: "{{firstName() + ' ' + surname()}}",
//       paymentType: "{{random('CREDIT_CARD', 'ACH')}}",
//       shipping: "{{random('OVERNIGHT', 'STANDARD')}}",
//       deliveryAddress: "{{integer(100, 9999)}} {{street()}}, {{city()}}, {{state()}} {{integer(10000, 99999)}}",
//       createdAt: "{{date(new Date(2021, 9, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       updatedAt: "{{date(new Date(2021, 9, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}"
//     }
//   ]
// }
