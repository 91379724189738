export const KEYS = {
  SEMI_MONTHLY: 'SEMI_MONTHLY',
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY',
};

export const PAYMENT_FREQ = KEYS;

const LIST = Object.keys(KEYS);

export default LIST.map((item) => ({
  label: `PERIOD_FREQ.${item}`,
  value: item,
  disabled: item === KEYS.WEEKLY,
}));

export type PAYMENT_FREQ_TYPE = typeof LIST[number] | undefined;
