// PENDING, CONFIRMED, SHIPPED, RECEIVED, APPROVED, COMPLETE
import { leadingZero, parseAddress } from '$gbusiness/helpers/util';
import { ORDER_STATUS } from '../enums/options/orderStatus';
import { deriveRawToOrderDetails } from './orderDetails';
import OrderInvoice, { deriveRawToOrderInvoice } from './orderInvoice';
import RefundModel, { deriveRawToRefund } from './refund';
import ShippingModel from './shipping';

export default interface OrderModel extends OrderInvoice {
  orderId: string;
  orderNumber: string;
  transactionId?: number;
  qty: number;
  isClosed: boolean;
  hasInvoice: boolean;
  deliveryAddress: string;
  deliveryDate: string;
  city?: string;
  refund?: RefundModel;
  paymentType: string;
  shippingOption: ShippingModel;
  termId?: number;
  items: Array<any>;
}

const deriveOrderNumber = (id) => {
  return leadingZero(id, 4);
};

const deriveCity = (formatted) => {
  const address = parseAddress(formatted);
  return address?.city || formatted;
};

const deriveStatus = (status) => {
  switch (status) {
    case null:
      return ORDER_STATUS.PREPARING;
    default:
      return status;
  }
};

const isClosed = (status) => {
  if (status === 'CLOSED') return true;
  else return false;
};

export const deriveHasInvoice = (status) => {
  const preStatus = [ORDER_STATUS.PREPARING, ORDER_STATUS.CANCELLED];
  if (preStatus.includes(status)) return false;
  return true;
};

const sortItemsByDept = (items) => {
  return items.sort((a, b) => a.categoryStr.localeCompare(b.categoryStr));
};

export const deriveRawToOrder = (raw) => {
  if (!raw) return null;

  return {
    ...deriveRawToOrderInvoice(raw),
    orderId: deriveOrderNumber(raw.id),
    orderNumber: raw.orderNumber,
    storeName: raw.storeName,
    termId: raw.termId,
    qty: raw.pricing?.qty || raw.qty || 0,
    qtySent: raw.qtySent || 0,
    qtyReceived: raw.qtyReceived || 0,
    deliveryAddress: raw.deliveryAddress,
    deliveryDate: raw.deliveryDate,
    ...(raw.refund && {
      refund: deriveRawToRefund(raw.refund),
    }),
    city: deriveCity(raw.deliveryAddress),
    status: deriveStatus(raw.status),
    isClosed: isClosed(raw.status),
    hasInvoice: deriveHasInvoice(raw.status),
    shippingOption: raw.shipping,
    paymentType: raw.paymentType,
    items: sortItemsByDept((raw.items || []).map((i) => deriveRawToOrderDetails(i))),
  };
};

// DATA GENERATOR json-generator.com
//
// {
//   totalSize: 55,
//   list: [
//     '{{repeat(5, 20)}}',
//     {
//       id: "{{index() + 1}}",
//       orderNumber: "{{integer(10000, 99999)}}",
//       storeId: 1,
//       storeName: "Cloudhood Bakery",
//       factoryId: 1,
//       transactionId: 0,
//       orderTypeId: 1,
//       status: "{{random('SHIPPED', 'PREPARING')}}",
//       subtotal: "{{floating(100, 5100, 2)}}",
//       tax: function (t) { return Number((this.subtotal * 0.6).toFixed(2)); },
//       total: function (t) { return Number((this.subtotal + this.tax).toFixed(2)); },
//       qty: "{{integer(4, 200)}}",
//       qtySent: function (t) { return this.qty - t.integer(0, 2); },
//       qtyReceived: function (t) { return this.qtySent - t.integer(0, 2); },
//       discount: 0,
//       note: function (t) {
//         if (t.integer(0, 10) > 8) return t.lorem();
//         else return '';
//       },
//       userName: "{{firstName() + ' ' + surname()}}",
//       paymentType: "{{random('CREDIT_CARD', 'ACH')}}",
//       shipping: "{{random('OVERNIGHT', 'STANDARD')}}",
//       deliveryAddress: "{{integer(100, 9999)}} {{street()}}, {{city()}}, {{state()}} {{integer(10000, 99999)}}",
//       createdAt: "{{date(new Date(2021, 9, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       updatedAt: "{{date(new Date(2021, 9, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}"
//     }
//   ]
// }
