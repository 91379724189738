import React from 'react';
import { Form, Formik } from 'formik';

import { input } from '$gbusiness/helpers';
import { FormSection } from '$gcomponents/reusables';
import { Button } from '$gcomponents/primitives';

import { DAILY_REPORT_ITEM_FORM } from './dailyReportForm';
import { IonContent } from '@ionic/react';
import Footer from '$gcomponents/widgets/footer';
import DailyReportItemModel from '$fbusiness/models/dailyReportItem';
import { PageWrapper } from '$gstyles/wrapper';
import { itemText } from '$gbusiness/helpers/util';
import intl from '$gintl';
import StoreModel from '$fbusiness/models/store';
import OptionModel from '$gbusiness/models/option';

interface DailyReportItemDetailsProps {
  item: DailyReportItemModel;
  regionOptions: Array<OptionModel>;
  storeOptions: Array<OptionModel>;
  store: StoreModel;
  onSaveItem: Function;
  onClose: Function;
}

const DailyReportItemDetails: React.FC<DailyReportItemDetailsProps> = ({
  item,
  storeOptions,
  store,
  regionOptions,
  onClose,
  onSaveItem,
}) => {
  const { total, yes, ...initialValues } = item;
  const onSubmit = (values) => {
    onSaveItem(values);
  };

  const dailyReportItemForm = DAILY_REPORT_ITEM_FORM({ regionOptions, storeOptions });

  const validateForm = (values) => {
    return input.validateError(dailyReportItemForm, values);
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...initialValues,
        storeId: store.id,
        regionId: store.regionId,
        yes: yes ? '1' : '0',
      }}
      validate={validateForm}
      onSubmit={(values) => {
        onSubmit(values);
      }}>
      {(formik) => (
        <>
          <IonContent>
            <PageWrapper>
              <Form>
                <FormSection FORM={dailyReportItemForm} formik={formik} />
              </Form>
            </PageWrapper>
          </IonContent>
          <Footer justifyContent="space-between">
            <Button className="third" variant="outlined" onClick={() => onClose()}>
              {intl('BUTTON.CANCEL')}
            </Button>
            <Button className="half" onClick={formik.handleSubmit} disabled={!formik.isValid}>
              {itemText(item?.id ? 'UPDATE' : 'ADD', 'DAILY_REPORT')}
            </Button>
          </Footer>
        </>
      )}
    </Formik>
  );
};

export default DailyReportItemDetails;
