import React from 'react';
import intl from '$intl';

import StoreModel from '$fbusiness/models/store';

import { ModalWrapper } from './styles';
import CreditDetails from './creditDetails';
import RefundModel from '$fbusiness/models/refund';

interface CreditModalProps {
  show: boolean;
  store: StoreModel;
  onClose: Function;
  onSaved: Function;
  credit: RefundModel;
}

const CreditModal: React.FC<CreditModalProps> = ({ show, store, credit, onClose, onSaved }) => {
  let titleText = intl('ITEM.' + (credit?.id ? 'EDIT' : 'ADD'), { item: intl('COMMON.STORE_CREDIT') });
  if (store?.name) titleText += ` (${store.name})`;

  return (
    <ModalWrapper titleText={titleText} show={show} onClose={() => onClose()} useCustom>
      {credit && <CreditDetails store={store} credit={credit} onSaved={onSaved} />}
    </ModalWrapper>
  );
};

export default CreditModal;
