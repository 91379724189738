import OrderInvoiceItemModel, { deriveRawToOrderInvoiceItem } from './orderInvoiceItem';
import TaxModel from './tax';

export default interface RefundDetailsModel extends OrderInvoiceItemModel {
  refundItemId: number;
  refundId: number;
  taxRule?: TaxModel;
  qtySent?: number;
  createdAt?: string;
  updatedAt?: string;
}

export const deriveRawToRefundDetails = (raw) => {
  return {
    ...deriveRawToOrderInvoiceItem(raw),
    refundItemId: raw.id,
    refundId: raw.refundId,
    taxRule: raw.taxRule,
    qtySent: raw.qty,
  };
};
