export const KEYS = {
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  ASSISTANT_MANAGER: 'ASSISTANT_MANAGER',
  EMPLOYEE: 'EMPLOYEE',
};

export const USER_LEVEL = KEYS;

const LIST = Object.keys(KEYS);

export default LIST.map((item) => ({
  label: `LEVELS.${item}`,
  value: item,
}));

export type USER_LEVEL_TYPE = typeof LIST[number] | undefined;
