import PATH from './paths';
import {
  LoginScreen,
  ForgotPasswordScreen,
  RegisterScreen,
  ResetPasswordScreen,
  RegisterCompleteScreen,
  VerifyEmailScreen,
  VerifyTokenScreen,
} from '$gbox/screens';
import RouterModel from '../models/route';

export { PATH };

export const LOGIN_ROUTE: RouterModel = {
  path: PATH.LOGIN,
  component: LoginScreen,
  exact: true,
};

export const FORGOT_ROUTE: RouterModel = {
  path: PATH.FORGOT,
  param: '',
  component: ForgotPasswordScreen,
  exact: true,
};

export const RESET_PASSWORD_ROUTE: RouterModel = {
  path: PATH.RESET_PASSWORD,
  param: ':token/:email',
  component: ResetPasswordScreen,
  exact: true,
};

export const REGISTER_ROUTE: RouterModel = {
  path: PATH.REGISTER,
  param: '',
  component: RegisterScreen,
  exact: true,
};

export const REGISTER_COMPLETE_ROUTE: RouterModel = {
  path: PATH.REGISTER_COMPLETE,
  component: RegisterCompleteScreen,
  exact: true,
};

export const VERIFY_EMAIL_ROUTE: RouterModel = {
  path: PATH.VERIFY_EMAIL,
  param: ':token/:email',
  component: VerifyEmailScreen,
  exact: true,
};

export const VERIFY_TOKEN_ROUTE: RouterModel = {
  path: PATH.VERIFY_TOKEN,
  param: ':token/:path?',
  component: VerifyTokenScreen,
};

export const PUBLIC_LIST = [
  LOGIN_ROUTE,
  FORGOT_ROUTE,
  RESET_PASSWORD_ROUTE,
  REGISTER_ROUTE,
  REGISTER_COMPLETE_ROUTE,
  VERIFY_EMAIL_ROUTE,
  VERIFY_TOKEN_ROUTE,
];

export const DEFAULT_PUBLIC_ROUTE = LOGIN_ROUTE;
export const DEFAULT_PUBLIC_PATH = DEFAULT_PUBLIC_ROUTE.path;
