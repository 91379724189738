import { INPUT } from '$gbusiness/enums';
import InputRowModel from '$gbusiness/models/inputRow';
import DATE_RANGE_OPTIONS, { DATE_RANGE } from '$fbusiness/enums/options/dateRange';
import { util } from '$fbusiness/helpers';
import { searchSharp } from 'ionicons/icons';
import { addMonths, addYears } from 'date-fns';
import REPORT_TYPE_OPTIONS from '$fbusiness/enums/options/reportType';
import { TOP_OPTIONS, BOTTOM_OPTIONS } from '$fbusiness/enums/options/topReport';

export const DATE_FILTER_ITEMS = [
  {
    label: 'INPUT.LABEL.DATE_RANGE',
    input: INPUT.SELECT,
    options: DATE_RANGE_OPTIONS,
    gridSize: 2,
    value: 'datePeriod',
    onChange: ({ value, setFieldValue }) => {
      const [startDate, endDate] = util.getDateRangeFromPeriod(value);
      setFieldValue('dateStart', startDate);
      setFieldValue('dateEnd', endDate);
    },
  },
  {
    label: 'INPUT.LABEL.START_DATE',
    value: 'dateStart',
    input: INPUT.DATE,
    maxDate: addMonths(new Date(), 1),
    onChange: ({ value, formValues, setFieldValue }) => {
      if (formValues.dateEnd && formValues.dateEnd < value) {
        //setFieldValue('dateEnd', value);
      }
      setFieldValue('datePeriod', DATE_RANGE.CUSTOM);
    },
    gridSize: 3,
  },
  {
    label: 'INPUT.LABEL.END_DATE',
    value: 'dateEnd',
    input: INPUT.DATE,
    maxDate: addYears(new Date(), 20),
    onChange: ({ setFieldValue }) => {
      setFieldValue('datePeriod', DATE_RANGE.CUSTOM);
    },
    gridSize: 3,
  },
];

export const TOP_FORM = (top) => [
  {
    label: 'SCREEN.REPORT.SHOW',
    input: INPUT.SELECT,
    disableFastField: true,
    gridSize: 3,
    options: top.includes('BOTTOM') ? BOTTOM_OPTIONS : TOP_OPTIONS,
    value: 'top',
  },
  {
    label: '',
    input: INPUT.HIDDEN,
    gridSize: 4,
    value: 'nothing',
  },
];

export const REPORT_TYPE_FORM = (onChangeReportType, extraForm) => [
  {
    items: [
      {
        label: 'SCREEN.REPORT.REPORT_TYPE',
        input: INPUT.RADIO,
        gridSize: 5,
        size: 'small',
        options: REPORT_TYPE_OPTIONS,
        className: 'report-type-input',
        value: 'reportType',
        onChange: ({ formValues, value }) => {
          onChangeReportType({
            ...formValues,
            reportType: value,
          });
        },
      },
      ...(extraForm
        ? extraForm
        : [
            {
              label: '',
              input: INPUT.HIDDEN,
              gridSize: 7,
              value: 'nothing',
            },
          ]),
    ],
  },
];

export const FILTER_FORM = ({
  placeholder,
  excludeDates,
  onChangeStore,
  stores,
  excludeQuery = false,
  extraForm,
  extraForm2,
}): Array<InputRowModel> => {
  const hasStores = stores && stores.length >= 0;
  return [
    {
      items: [
        ...(extraForm && extraForm?.length > 0 ? extraForm[0]?.items || [] : []),
        ...(hasStores
          ? [
              {
                input: INPUT.AUTOCOMPLETE,
                label: 'SCREEN.STORES.COLS.STORE',
                disableFastField: true,
                gridSize: excludeDates ? 4 : 3,
                value: 'store',
                className: 'store-selector',
                config: {
                  optionProp: {
                    label: 'name',
                    value: 'id',
                  },
                  data: stores,
                },
                onChange: ({ newValue }) => {
                  if (onChangeStore) onChangeStore(newValue);
                },
              },
            ]
          : []),
        ...(excludeQuery
          ? []
          : [
              {
                label: 'INPUT.LABEL.SEARCH',
                placeholder,
                input: INPUT.TEXT,
                gridSize: hasStores && !excludeDates ? 2 : !hasStores && excludeDates ? 5 : 3,
                icon: searchSharp,
                enterToSubmit: true,
                value: 'query',
              },
            ]),
        ...(!excludeDates
          ? DATE_FILTER_ITEMS.map((m, i) => {
              if (i > 0)
                return {
                  ...m,
                  gridSize: hasStores ? 2 : 2,
                };
              return m;
            })
          : []),
        ...(extraForm2 && extraForm2?.length > 0 ? extraForm2[0]?.items || [] : []),
      ],
    },
  ];
};

export const DATE_FILTER_FORM = (): Array<InputRowModel> => [
  {
    items: [...DATE_FILTER_ITEMS],
  },
];
