import React from 'react';
import GetAppIcon from '@mui/icons-material/GetApp';

import ExportModel from '$gbusiness/models/export';
import intl from '$intl';
import { fetchApi } from '$gbusiness/services/api';

import { CsvWrapper } from './styles';
import { Button } from '../../primitives';
import { useDispatch } from 'react-redux';
import { LOADING } from '$gbusiness/redux/loading/types';
import { removeProp } from '$gbusiness/helpers/util';
import { useSelector } from 'react-redux';

interface ExportButtonProps {
  filter?: any;
  exportSetting?: ExportModel;
  component?: React.ReactNode;
  fileName: string;
  color?: any;
  variant?: any;
  hideLoading?: boolean;
  data?: Array<any>;
  text?: string;
}

const ExportButton: React.FC<ExportButtonProps> = ({
  color,
  variant,
  exportSetting,
  fileName,
  filter,
  component,
  hideLoading = false,
  data,
  text = null,
}) => {
  const dispatch = useDispatch();
  const sorting = useSelector((state: any) => state.localStorage.sorting);

  const getList = async () => {
    if (data || !exportSetting) return data;

    const { url, sortKey, sortOrder, deriveTable, deriveToModel } = exportSetting;
    const param = removeProp({
      ...filter,
      pageSize: 10000,
      sortKey: sorting?.sortKey || sortKey,
      sortOrder: sorting?.sortOrder || sortOrder,
    });
    const finalUrl = exportSetting.method === 'GET' ? `${url}?${new URLSearchParams(param).toString()}` : url;

    if (!hideLoading)
      await dispatch({
        type: LOADING,
        loadingText: intl('PROGRESS.EXPORTING'),
      });
    const response = await fetchApi({
      url: finalUrl,
      method: exportSetting.method || 'POST',
      param,
    });
    if (!hideLoading)
      await dispatch({
        type: 'EXPORT_SUCCESS',
      });

    return response.list.map((item) => {
      const raw = deriveToModel(item);
      return deriveTable ? deriveTable(raw) : raw;
    });
  };

  const finalFileName = exportSetting?.getFileName
    ? exportSetting.getFileName(filter)
    : exportSetting?.fileName || fileName;

  return (
    <CsvWrapper filename={finalFileName} extension=".csv" separator="," wrapColumnChar='"' datas={getList}>
      {component ? (
        component
      ) : (
        <Button
          variant={variant || 'contained'}
          startIcon={<GetAppIcon />}
          onClick={() => {}}
          color={color || 'secondary'}>
          {text === null ? intl('BUTTON.EXPORT') : text}
        </Button>
      )}
    </CsvWrapper>
  );
};

export default ExportButton;
