import {
  ADD_INVOICE_PAYMENT,
  CLEAN_INVOICE,
  CLEAN_PAYMENT_GROUP,
  DELETE_INVOICE_PAYMENT,
  EMPTY_SUCCESS,
  FETCH_INVOICE_PAYMENTS,
  FETCH_INVOICE_SUCCESS,
  FETCH_PAYMENT_GROUP_SUCCESS,
  invoiceActionTypes,
  invoiceReducerType,
  INVOICE_INIT_STATE,
  UPDATE_INVOICE_PAYMENT,
  UPDATE_INVOICE_SUCCESS,
} from './types';

export default function invoiceReducer(
  state: invoiceReducerType = INVOICE_INIT_STATE,
  action: invoiceActionTypes,
): invoiceReducerType {
  switch (action.type) {
    case FETCH_INVOICE_SUCCESS:
      return {
        ...state,
        invoice: action.invoice,
      };
    case FETCH_INVOICE_PAYMENTS:
      return {
        ...state,
        invoicePayments: action.invoicePayments,
      };
    case FETCH_PAYMENT_GROUP_SUCCESS:
      return {
        ...state,
        paymentGroup: action.paymentGroup,
      };
    case UPDATE_INVOICE_SUCCESS:
      return {
        ...state,
        invoicePayments: [],
        invoice: action.invoice || state.invoice,
        isFinished: true,
      };
    case ADD_INVOICE_PAYMENT:
      return {
        ...state,
        invoicePayments: [...state.invoicePayments, action.invoicePayment],
      };
    case UPDATE_INVOICE_PAYMENT:
      return {
        ...state,
        invoicePayments: state.invoicePayments.map((p, i) =>
          action.index === i ? action.invoicePayment : p,
        ),
      };
    case DELETE_INVOICE_PAYMENT:
      return {
        ...state,
        invoicePayments: state.invoicePayments.filter((p, i) => action.index !== i),
      };
    case CLEAN_INVOICE:
      return {
        ...INVOICE_INIT_STATE,
        refresh: state.refresh,
      };
    case CLEAN_PAYMENT_GROUP:
      return {
        ...state,
        paymentGroup: null,
      };
    case EMPTY_SUCCESS:
      return state;
    default:
      return state;
  }
}
