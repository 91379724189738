import { Dispatch } from 'redux';

import { configs } from '$configs';
import { dispatchLoading, fetchApi, handleApiFail, handleApiSuccess } from '$gbusiness/services/api';
import { deriveRawToCategory, initialValue } from '../../models/category';
import {
  categoryActionTypes,
  FETCH_CATEGORY_SUCCESS,
  CLEAN_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  CATEGORY_FAILURE,
  DELETE_CATEGORY_SUCCESS,
  FETCH_CATEGORIES_SUCCESS,
  REORDER_CATEGORY,
  TOGGLE_PROP,
  TOGGLE_EXPAND,
} from './types';
import { getPublicFactoryId } from '$gbusiness/helpers/util';

export function fetchCategory(id): any {
  return async (dispatch: Dispatch) => {
    if (!id || isNaN(id)) {
      dispatch({
        type: FETCH_CATEGORY_SUCCESS,
        category: initialValue,
      });
      return;
    }

    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.category.general + '/' + id,
      method: 'GET',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, CATEGORY_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_CATEGORY_SUCCESS,
      category: deriveRawToCategory(response.data),
    });
  };
}

export function fetchCategoryDetails(id): any {
  return async (dispatch: Dispatch) => {
    if (!id || isNaN(id)) {
      dispatch({
        type: FETCH_CATEGORY_SUCCESS,
        category: initialValue,
      });
      return;
    }

    dispatchLoading(dispatch);
    const factoryId = getPublicFactoryId();
    const response = await fetchApi({
      url: configs.api.category.details + '/' + id + (factoryId ? '?factoryId=' + factoryId : ''),
      method: 'GET',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, CATEGORY_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_CATEGORY_SUCCESS,
      category: deriveRawToCategory(response.data),
    });
  };
}

export function fetchCategories(factoryId = ''): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch);
    const response = await fetchApi({
      url: configs.api.category.general + (typeof factoryId === 'string' ? '?factoryId=' + factoryId : ''),
      method: 'GET',
    });

    if (!response || !response?.list) {
      handleApiFail(dispatch, CATEGORY_FAILURE, response, 'ERROR.SERVER', true);
      return;
    }

    dispatch({
      type: FETCH_CATEGORIES_SUCCESS,
      categories: response.list.map((d) => deriveRawToCategory(d)),
    });
  };
}

export function toggleProp(id, param): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.category.general + '/' + id,
      param: {
        id,
        ...param,
      },
      method: 'PUT',
    });

    if (response && response?.success) {
      dispatch({
        type: TOGGLE_PROP,
      });
    }
  };
}

export function alphabetize(): any {
  return async (dispatch: Dispatch) => {
    const response = await fetchApi({
      url: configs.api.category.alphabetize,
      param: {},
      method: 'POST',
    });

    if (!response && !response?.success) {
      handleApiFail(dispatch, CATEGORY_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    }
    handleApiSuccess(dispatch, null, 'MESSAGE.SAVE_SUCCESS', 'small');
  };
}

export function saveCategory(id, category, isRanking = false): any {
  return async (dispatch: Dispatch) => {
    if (!isRanking) dispatchLoading(dispatch, 'PROGRESS.SAVING');
    else {
      dispatch({
        type: REORDER_CATEGORY,
        categoryId: id,
        rank: category.rank,
      });
    }

    const response = await fetchApi({
      url: configs.api.category.general + (id ? '/' + id : ''),
      param: {
        ...(id && { ...id }),
        ...category,
      },
      method: id ? 'PUT' : 'POST',
    });

    if (!response || !response?.success) {
      handleApiFail(dispatch, CATEGORY_FAILURE, response, 'ERROR.SAVE', true);
      return;
    } else {
      if (!isRanking) handleApiSuccess(dispatch, UPDATE_CATEGORY_SUCCESS, 'MESSAGE.SAVE_SUCCESS', 'small');
    }
  };
}

export function deleteCategory(id): any {
  return async (dispatch: Dispatch) => {
    dispatchLoading(dispatch, 'PROGRESS.PROCESSING');

    const response = await fetchApi({
      url: configs.api.category.general + (id ? '/' + id : ''),
      method: 'DELETE',
    });

    if (response?.err) {
      handleApiFail(dispatch, CATEGORY_FAILURE, response, 'ERROR.OPERATION', true);
      return;
    } else {
      handleApiSuccess(dispatch, DELETE_CATEGORY_SUCCESS, 'MESSAGE.DELETE_SUCCESS');
    }
  };
}

export function toggleExpand(categoryId): categoryActionTypes {
  return {
    type: TOGGLE_EXPAND,
    categoryId,
  };
}

export function dehydrate(): categoryActionTypes {
  return { type: CLEAN_CATEGORY };
}
