export const KEYS = {
  CASH: 'CASH',
  CHECK: 'CHECK',
  ACH: 'ACH',
  CREDIT: 'CREDIT',
  OTHER: 'OTHER',
};

export const PAYMENT_TYPE = KEYS;

export const INVOICE_PAYMENTS = {
  ...KEYS,
  DISCOUNT: 'DISCOUNT',
  CREDITS: 'CREDITS',
};

const convertToOption = (item) => ({
  label: `PAYMENT_TYPE.${item}`,
  value: item,
});

export const LIST = Object.keys(KEYS);

export default LIST.map(convertToOption);

export type PAYMENT_TYPE_TYPES = typeof LIST[number] | undefined;
