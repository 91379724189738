import React from 'react';
import { connect } from 'react-redux';
import { IonPage } from '@ionic/react';

import intl from '$gintl';
import UserModel from '$gbusiness/models/user';
import { userActions } from '$fbusiness/redux/user';
import { screen } from '../../../components/hoc';
import Header from '../../../components/header';

import FormView from './formView';
import PATH from '$gbusiness/enums/paths';

interface UserScreenProps {
  onHydrate: Function;
  onDehydrate: Function;
  saveUser: Function;
  match: any;
  myUserId: number;
  user: UserModel;
}

const UserScreen: React.FC<UserScreenProps> = React.memo(
  ({ user, saveUser, myUserId, match }) => {
    const {
      params: { userId },
    } = match;
    const isNewUser = !parseInt(userId);
    const title = isNewUser
      ? intl('SCREEN.USER.TITLE_NEW')
      : intl('SCREEN.USER.TITLE', { firstName: user?.firstName, lastName: user?.lastName });

    const onSaveUser = (userId, values) => {
      const { password, firstName, lastName, userLevel, email } = values;
      const params = {
        firstName,
        lastName,
        userLevel,
        email,
        ...(password && { password }),
      };
      saveUser(userId, params);
    };

    return (
      <IonPage>
        <Header titleText={title} backRoute={PATH.USERS} />
        <FormView user={user} isMe={user?.userId === myUserId} onSubmit={onSaveUser} />
      </IonPage>
    );
  },
  (pp, np) => pp.user === np.user,
);

const mapStateToProps = (state) => ({
  user: state.user.user,
  myUserId: state.currentUser.userId,
});

const mapDispatchToProps = {
  onHydrate: (params) => userActions.fetchUser(parseInt(params.userId)),
  saveUser: (userId, params) => userActions.saveUser(parseInt(userId), params),
  onDehydrate: () => userActions.dehydrate(),
};

const connected = connect(mapStateToProps, mapDispatchToProps);

export default connected(screen(UserScreen));
