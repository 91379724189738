import { toLocal } from '$gbusiness/helpers/date';
import FileModel, { deriveRawToFile } from './file';
import StoreModel, { deriveRawToStore } from './store';
import UserModel, { deriveRawToUser } from './user';

export default interface ThreadModel {
  id: number;
  user: UserModel;
  store: StoreModel;
  ticketNumber: string;
  contact: any;
  statue: string;
  replies: number;
  category: string;
  title: string;
  unreads?: Array<number>;
  hasAttachment?: boolean;
  messages?: Array<MessageModel>;
  settings?: any;
  createdAt?: string;
  updatedAt?: string;
}

export interface MessageModel {
  id: number;
  threadId: number;
  user: UserModel;
  isRead: boolean;
  admin: UserModel;
  content: string;
  files?: Array<FileModel>;
  settings?: string;
  createdAt?: string;
}

export const deriveRawToThread = (raw) => {
  return {
    id: raw.id,
    user: deriveRawToUser(raw.user),
    store: deriveRawToStore(raw.store),
    contact: raw.contact,
    ticketNumber: raw.ticketNumber,
    status: raw.status,
    replies: raw.replies,
    isRead: raw.isRead,
    category: raw.category,
    title: raw.title,
    unreads: raw.unreads || [0, 0],
    hasAttachment: raw.hasAttachment || false,
    messages: (raw.messages || []).map(deriveRawToMessage),
    settings: raw.settings,
    createdAt: toLocal(raw.createdAt),
    updatedAt: toLocal(raw.updatedAt),
  };
};

export const deriveRawToMessage = (raw) => {
  return {
    id: raw.id,
    threadId: raw.threadId,
    user: raw.user ? deriveRawToUser(raw.user) : null,
    admin: raw.admin ? deriveRawToUser(raw.admin) : null,
    isRead: raw.isRead,
    content: raw.content,
    settings: raw.settings,
    files: (raw.files || []).map(deriveRawToFile),
    createdAt: toLocal(raw.createdAt),
  };
};
