import React, { useEffect } from 'react';
import Slider from 'react-slick';

import { itemActions } from '$fbusiness/redux/item';
import { useDispatch, useSelector } from 'react-redux';
import { Triangle } from '$gstyles/triangles';
import useWindowDimensions from '$gbox/hooks/useWindowDimensions';
import { ENUMS } from '$gstyles';
import { configs } from '$configs';
import { ItemFeaturedView } from '$fcomponents';
import { CarouselWrapper } from './styles';

interface FeaturedCarouselProps {
  onAddCart: Function;
  ebrochure: boolean;
  onClickItem: Function;
  isWholesale: boolean;
  hidePrice: boolean;
}

const FeaturedCarousel: React.FC<FeaturedCarouselProps> = ({
  ebrochure,
  onAddCart,
  isWholesale,
  onClickItem,
  hidePrice,
}) => {
  const items = useSelector((state: any) => state.item.items);
  const dispatch = useDispatch();

  const { width } = useWindowDimensions();
  const FEATURED_HEADER_WIDTH = 150;
  const FEATURED_WIDTH = 240;
  const FEATURED_HEIGHT = 240;
  const splitWidth = Number(ENUMS.SPLIT_PANE_WIDTH.replace('px', ''));
  const bodyWidth =
    (width >= splitWidth ? width - configs.display.menuWidth : width) - FEATURED_HEADER_WIDTH - 100;
  const slidesToShow = Math.min(Math.floor(bodyWidth / FEATURED_WIDTH) + 0, items.length);

  const sliderSetting = {
    infinite: true,
    speed: 500,
    swipeToSlide: true,
    centerMode: false,
    slidesToShow: Math.max(slidesToShow, 1),
    slidesToScroll: Math.max(slidesToShow, 1),
    className: 'featured-items',
  };

  useEffect(() => {
    dispatch(itemActions.fetchItems({ featured: true }, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {items.length > 0 && (
        <CarouselWrapper fheaderWidth={FEATURED_HEADER_WIDTH} fheight={FEATURED_HEIGHT}>
          <div className="featured-header">
            <Triangle className="pointer" direction="right" color="var(--ion-color-primary)" size={10} />
            <div className="featured-title">Kong Dog Highlights</div>
          </div>
          <div className="slider-wrapper">
            <Slider {...sliderSetting}>
              {items &&
                items.map(item => (
                  <div className="slider-item" key={item.id}>
                    <ItemFeaturedView
                      item={item}
                      hidePrice={hidePrice}
                      ebrochure={ebrochure}
                      width={FEATURED_WIDTH}
                      height={FEATURED_HEIGHT}
                      onAddCart={onAddCart}
                      onClick={onClickItem}
                      isWholesale={isWholesale}
                    />
                  </div>
                ))}
            </Slider>
          </div>
        </CarouselWrapper>
      )}
    </>
  );
};

export default FeaturedCarousel;
