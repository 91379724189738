import { today, toLocal } from '$gbusiness/helpers/date';
import { createUuid, pickRandomArr, randomInt, roundDecimal } from '$gbusiness/helpers/util';
import { deriveRawToSimple, SimpleModel } from '$gbusiness/models/simple';
import UserModel, { deriveRawToUser } from '$gbusiness/models/user';
import { ORDER_STATUS } from '../enums/options/orderStatus';
import { INVOICE_STATUS } from '../enums/options/invoiceStatus';
import { reconcileItems, reconcileSerials } from '../redux/cart/utils';
import InvoiceItemModel, { deriveRawItemToInvoiceItem, deriveRawToInvoiceItem } from './invoiceItem';
import InvoicePaymentModel, { deriveRawToinvoicePayment } from './invoicePayment';
import OrderModel, { deriveRawToOrder } from './order';
import OrderInvoice, { deriveRawToOrderInvoice } from './orderInvoice';
import { recalculateItemPrice } from './orderInvoiceItem';
import { deriveRawToPrice } from './price';
import RefundModel, { deriveRawToRefund } from './refund';
import StoreModel, { deriveRawToStore } from './store';
import TermModel from './term';
import { generateRandomSentence } from '../helpers/randomGenerator';
import { generateInvoiceNo } from '../helpers/util';
import { addDays, parseISO } from 'date-fns';

export const PAID_LIST = [INVOICE_STATUS.PAID];
export const OPEN_LIST = [
  INVOICE_STATUS.OPEN,
  INVOICE_STATUS.PAYMENT_SENT,
  INVOICE_STATUS.VOID_PAYMENT,
  INVOICE_STATUS.REFUNDED,
];
export const TOTAL_LIST = [...OPEN_LIST, ...PAID_LIST];

export default interface InvoiceModel extends OrderInvoice {
  orderId: number;
  refund?: RefundModel;
  uuid: string;
  invoiceNumber?: string;
  revision: number;
  qtyReceived: number;
  qtySent: number;
  termId: number;
  term?: TermModel;
  isClosed: boolean;
  isInvoiceCharge?: boolean;
  noteFactory: string;
  noteStore: string;
  pastDue: boolean;
  dueDate?: string;
  discountDate?: string;
  detailedData?: any;
  overdue: boolean;
  paidAmount: number;
  creditAmount: number;
  refundAmount: number;
  credFunds?: Array<any>;
  paymentDiscount: number;
  balance?: number;
  paymentMemo: string;
  payments: Array<InvoicePaymentModel>;
  shippingMethod?: SimpleModel;
  sentAt?: string;
  paidAt?: string;
  clawBack?: boolean;
  flaggedAt?: string;
  confirmedAt?: string;
  closedAt?: string;
  createdAt?: string;
  order?: OrderModel;
  store?: StoreModel;
  user?: UserModel;
  author?: UserModel;
  isCharge?: boolean;
  chargeId?: number;
  charge?: SimpleModel;
  totals?: any;
  history?: Array<History>;
  items: Array<InvoiceItemModel>;
}

const derivePastDue = (raw) => {
  const { pastDue, dueDate } = raw;
  if (pastDue) return pastDue;
  if (!dueDate || today() <= dueDate) return 'NO';
  return 'YES';
};

const deriveInvoiceStatus = (raw) => {
  return raw.status;
  // if (raw.statusFactory) return raw.statusFactory;
  // else return raw.status;
};

const deriveOverdue = (raw) => {
  return today() > raw.dueDate;
};

const deriveBalance = (raw) => {
  if (OPEN_LIST.includes(raw.status)) return raw.balance || 0;
  return 0;
};

const deriveInnerCombo = (payments: any, refunds: any) => {
  const combo = [
    ...(payments || []).map((p) => ({
      type: p.type || 'OTHER',
      paymentDate: p.paymentDate,
      originalAmount: p.originalAmount,
      isRefund: false,
      settings: p.settings || null,
      id: p.id,
      paymentGroupId: p.paymentGroupId || 0,
      rejected: !!p.rejectedAt,
      ...(p.isRc && { isRc: true }),
      amount: p.amount,
    })),
    ...(refunds || []).map((r) => ({
      type: !!r.isCredit ? 'CRED' : 'REFUND',
      isRefund: true,
      isRc: r.isRc,
      paymentDate: r.refundedAt || r.requestDate,
      originalAmount: r.isRc ? r.pricing.total : null,
      settings: r.settings || null,
      id: r.id,
      paymentGroupId: 0,
      rejected: 0,
      ...(r.isRc && { isRc: true }),
      amount: r.isRc ? r.pricing?.rcApplied : r.pricing?.total || 0,
    })),
  ];
  return combo.sort((a, b) => a.paymentDate.localeCompare(b.paymentDate));
};

export const deriveRawToInvoiceForInner = (raw) => {
  return {
    ...deriveRawToInvoice(raw),
    payments: deriveInnerCombo(raw.payments, raw.credfunds),
  };
};

export const deriveRawToInvoice = (raw) => {
  if (raw.overdue !== undefined) return raw;
  return {
    id: raw.id,
    storeId: raw.storeId,
    uuid: raw.uuid,
    ...deriveRawToOrderInvoice(raw),
    ...(raw.refund && { refundId: raw.refund?.refundId, refund: deriveRawToRefund(raw.refund) }),
    qty: Number(raw.qty),
    qtyReceived: Number(raw.qtyReceived),
    qtySent: Number(raw.qtySent),
    invoiceNumber: raw.invoiceNumber,
    revision: raw.revision,
    overdue: deriveOverdue(raw),
    noteFactory: raw.noteFactory,
    noteStore: raw.noteStore,
    isClosed: raw.status === ORDER_STATUS.CLOSED,
    status: deriveInvoiceStatus(raw),
    pastDue: derivePastDue(raw),
    dueDate: raw.dueDate,
    discountDate: raw.discountDate,
    paymentDiscount: raw.paymentDiscount || raw.pricing.paymentDiscount || 0,
    ...(raw.sentAt && { sentAt: toLocal(raw.sentAt) }),
    ...(raw.shippingMethod && { shippingMethod: deriveRawToSimple(raw.shippingMethod) }),
    paidAmount: raw.paidAmount || 0,
    payments: (raw.payments || []).map(deriveRawToinvoicePayment),
    refundAmount: raw.refundAmount || 0,
    creditAmount: raw.creditAmount || 0,
    ...(raw.credFunds && { credfunds: raw.credFunds.map(deriveRawToRefund) }),
    balance: deriveBalance(raw),
    total: raw.status === INVOICE_STATUS.CANCELLED ? 0 : raw.pricing.total || 0,
    totals: {
      finalTotal: raw.pricing?.finalTotal || 0,
      finalBalance: raw.pricing?.finalBalance || 0,
      refundCreditSum: raw.pricing?.refundCreditSum || 0,
      invoiceChargeSum: raw.pricing?.invoiceChargeSum || 0,
    },
    history: raw.history || [],
    paymentMemo: raw.paymentMemo || '',
    termId: raw.termId,
    ...(raw.term && { term: raw.term }),
    clawBack: raw.cb,
    isCharge: !!raw.chargeId,
    ...(raw.chargeId && { chargeId: raw.chargeId, charge: raw.charge }),
    createdAt: toLocal(raw.createdAt),
    shippedAt: toLocal(raw.createdAt),
    confirmedAt: toLocal(raw.confirmedAt),
    paidAt: toLocal(raw.paidAt),
    flaggedAt: toLocal(raw.flaggedAt),
    closedAt: toLocal(raw.closedAt),
    ...(raw.order && { order: deriveRawToOrder(raw.order) }),
    ...(raw.store && { store: deriveRawToStore(raw.store) }),
    ...(raw.user && { user: deriveRawToUser(raw.user) }),
    ...(raw.author && { author: deriveRawToUser(raw.author) }),
    detailedData: raw.detailedData || {},
    items: (raw.items || []).map((item) => deriveRawToInvoiceItem(item, true)),
  };
};

// export const deriveClawback = (raw) => {
//   if (raw.clawBack < 0) return CLAWBACK_STATUS.NO;
//   if (raw.clawBack > 0) return CLAWBACK_STATUS.YES;
//   const balance = deriveBalance(raw);
//   if (balance === 0) return CLAWBACK_STATUS.NO;
//   return CLAWBACK_STATUS.NA;
// };

export const recalculateInvoice = (
  invoice,
  {
    fixedItems = [],
    fixedQty = false,
    mutable = false,
    noTax = false,
    skipItems = false,
    preventCombine = false,
  },
) => {
  const {
    otherDiscount = 0,
    shipping = 0,
    taxRate: originalTaxRate,
    delivery = 0,
    refund = 0,
    tip = 0,
  } = invoice;
  const taxRate = noTax ? 0 : originalTaxRate;
  const items = fixedItems.length
    ? fixedItems
    : preventCombine
    ? reconcileSerials(invoice.items)
    : reconcileItems(invoice.items, 'qtySent');

  const price =
    skipItems || invoice.isCharge
      ? {
          items: [],
          subtotal: invoice.subtotal,
          discount: invoice.itemDiscount,
          taxable: invoice.itemTaxable,
          tax: invoice.itemTax,
          total: invoice.itemTotal,
          qty: invoice.qty || 0,
          qtySent: invoice.qtySent || invoice.qty || 0,
          qtyReceived: invoice.qtyReceived || 0,
        }
      : items.reduce(
          (acc, item) => {
            const modifiedItem = fixedItems.length ? item : recalculateItemPrice(item, null, fixedQty, noTax);
            acc.items.push(modifiedItem);
            const {
              subtotal,
              taxable,
              tax,
              discount,
              total,
              qty,
              qtySent,
              qtyReceived,
              totalSent,
              totalReceived,
            } = modifiedItem;
            acc.subtotal = acc.subtotal + subtotal;
            acc.discount = acc.discount + discount;
            acc.taxable = acc.taxable + taxable;
            acc.tax = acc.tax + tax;
            acc.total = acc.total + total;
            acc.qty = acc.qty + qty;
            acc.qytSent = acc.qtySent + qtySent;
            acc.qtyReceived = acc.qtyReceived + qtyReceived;
            acc.totalSent = acc.totalSent + totalSent;
            acc.totalReceived = acc.totalReceived + totalReceived;
            return acc;
          },
          {
            items: [],
            subtotal: 0,
            discount: 0,
            taxable: 0,
            tax: 0,
            total: 0,
            qty: 0,
            qtySent: 0,
            qtyReceived: 0,
            totalReceived: 0,
            totalSent: 0,
          },
        );

  const totalOtherDiscount = otherDiscount; // + paymentDiscount;
  const adjustedOtherDiscount = Math.min(totalOtherDiscount, price.taxable);
  const totalDiscount = adjustedOtherDiscount + price.discount;
  const taxable = price.taxable - adjustedOtherDiscount + shipping;
  const shippingTax = Math.round(shipping * taxRate) / 100;
  const otherDiscountTax = Math.round(adjustedOtherDiscount * taxRate) / 100;
  const tax = Math.max(0, shippingTax - otherDiscountTax + price.tax);
  const total = invoice?.isCharge ? invoice.total : taxable + tax + delivery + tip;
  const refundAmt = Math.min(!refund?.isRc && !refund?.isCredit ? refund?.balance || 0 : 0, total);
  const savedRate = price.subtotal ? roundDecimal((totalDiscount * 100) / price.subtotal) : 0;
  const { paidAmount, creditAmount, refundAmount } = invoice;

  return {
    ...invoice,
    ...(!invoice.qtySent && {
      qty: price.qty,
      qtySent: price.qtySent,
      qtyReceived: price.qtyReceived,
    }),
    ...(mutable && !skipItems && { items: price.items }),
    subtotal: price.subtotal,
    itemTaxable: price.taxable,
    itemTax: price.tax,
    itemTotal: price.taxable + price.tax,
    itemDiscount: price.discount,
    otherDiscount: totalOtherDiscount,
    totalDiscount,
    taxable,
    balance: total - paidAmount - creditAmount - refundAmount,
    refundAmt,
    tax: taxable === 0 || tax < 0 ? 0 : tax,
    total,
    savedRate,
  };
};

export function calculatePriceFromInvoice(invoice) {
  return {
    ...deriveRawToPrice(invoice),
    itemDiscount: invoice.subtotal - invoice.itemTaxable || 0,
  };
}

export const generateRandomItems = (items, store, discountRate) => {
  if (!items || !items.length) return [];
  const totalItems = items.length;
  const maxItems = Math.min(totalItems, 20);
  const numItems = randomInt(1, maxItems);

  const newItems: Array<any> = [];
  for (let i = 0; i < numItems; i++) {
    const item = pickRandomArr(items);
    const newItem = {
      ...deriveRawItemToInvoiceItem(item, store, discountRate),
      id: 0,
    };
    newItems.push(newItem);
  }
  return newItems;
};

export const genearteRandomOrder = (factory, store, items) => {
  const { discounts, salesmen, userIds, id: storeId } = store;
  const user = pickRandomArr(salesmen);
  const userId = user ? user?.id : pickRandomArr(userIds) || null;
  const discount = pickRandomArr(discounts.filter((d) => !d.isFlat));
  const discountRate = discount?.amount || 0;

  return {
    id: 0,
    shippingId: null,
    paymentTypeId: null,
    storeId,
    qty: 0,
    userId,
    ...(user && { user }),
    deliveryAddress: '165 Indiana Ave, Fort Washington, PA 19462, USA',
    items: generateRandomItems(items, store, discountRate),
  };
};

export const generateRandomInvoice = (factory, store, items, nextInvoice = ''): InvoiceModel => {
  const { discounts, salesmen, id: storeId } = store;
  const { terms } = factory;
  const term = pickRandomArr(terms);
  const user = pickRandomArr(salesmen);
  const discount = pickRandomArr(discounts.filter((d) => !d.isFlat));
  const discountRate = discount?.amount || 0;

  return {
    orderId: 0,
    uuid: createUuid(),
    invoiceNumber: generateInvoiceNo({ factory, storeId, orderId: null }, '', nextInvoice),
    revision: 0,
    qtyReceived: 0,
    qtySent: 0,
    termId: term?.id || null,
    ...(term && { term }),
    isClosed: false,
    noteFactory: pickRandomArr(['', '', '', 'Just a random factory note', generateRandomSentence()]),
    noteStore: '',
    pastDue: false,
    overdue: false,
    paidAmount: 0,
    creditAmount: 0,
    refundAmount: 0,
    paymentDiscount: 0,
    paymentMemo: 0,
    payments: [],
    store: store,
    storeId: store.id,
    userId: user?.id || null,
    ...(user && { user }),
    ...(discount && { discountId: discount.id }),
    discountRate,
    items: generateRandomItems(items, store, discountRate),
  };
};

export const convertInvoiceToOrder = (factory, invoice) => {
  const {
    storeId,
    discountRate,
    taxRate,
    commRate,
    subtotal,
    itemTax,
    itemDiscount,
    itemTaxable,
    itemTotal,
    otherDiscount,
    totalDiscount,
    taxable,
    tax,
    total,
    qty,
    created_at,
  } = invoice;
  const shipping = pickRandomArr(factory.shippings) || null;
  return {
    items: invoice.items.map((item) => {
      const {
        id,
        itemId,
        itemName,
        qty,
        note,
        subtotal,
        tax,
        total,
        taxRate,
        unitPrice,
        discountRate,
        unitTax,
        discount,
        taxable,
      } = item;
      return {
        id,
        itemId,
        itemName,
        qty,
        note,
        subtotal,
        tax,
        total,
        taxRate,
        unitPrice,
        discountRate,
        unitTax,
        discount,
        taxable,
      };
    }),
    paymentTypeId: 0,
    shippingId: shipping?.id || null,
    storeId: storeId,
    refundId: null,
    discountRate,
    taxRate,
    commRate,
    subtotal,
    itemTax,
    itemDiscount,
    itemTaxable,
    itemTotal,
    otherDiscount,
    totalDiscount,
    taxable,
    tax,
    total,
    qty,
    deliveryAddress: invoice.store.formatted,
    deliveryDate: shipping ? addDays(parseISO(created_at), shipping.days) : created_at,
    created_at,
    note: pickRandomArr(['', '', '', 'Just a random factory note', generateRandomSentence()]),
    textConfirmation: false,
    emailConfirmation: false,
  };
};

// DATA GENERATOR json-generator.com
//
// {
//   totalSize: 63,
//   list: [
//     '{{repeat(5, 20)}}',
//     {
//       id: "{{index() + 1}}",
//       orderId: "{{index() + 1}}",
//       storeId: 1,
//       factoryId: 1,
//       uuid: "{{guid()}}",
//       status: "{{random('PENDING', 'PAID', 'SENT')}}",
//       invoiceNumber: "{{integer(1000000, 9999999)}}",
//       totalAmount: "{{floating(100, 5100, 2)}}",
//       noteFactory: function (t) {
//         if (t.integer(0, 10) > 8) return t.lorem();
//         else return '';
//       },
//       noteStore: function (t) {
//         if (t.integer(0, 10) > 8) return t.lorem();
//         else return '';
//       },
//       dueDate: "{{date(new Date(), new Date(2021, 10, 10), 'YYYY-MM-dd')}}",
//       createdAt: "{{date(new Date(2021, 7, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       sentAt: "{{date(new Date(2021, 9, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}",
//       paidAt: "{{date(new Date(2021, 9, 1), new Date(), 'YYYY-MM-dd HH:mm:ss')}}"
//     }
//   ]
// }
