import { INVOICE_PAYMENT_TYPE, CASH } from '../enums/invoicePayment';
import BankModel from './bank';

export default interface InvoicePaymentModel {
  id: number;
  invoiceId: number;
  invoiceNumber?: string;
  storeId: number;
  paymentGroupId: number;
  fromName: string;
  type: INVOICE_PAYMENT_TYPE;
  amount: number;
  originalAmount?: number;
  originalBalance?: number;
  bankId: number;
  bank?: BankModel;
  rejectedAt?: string;
  paymentDate: string | null;
  memo: string;
  settings?: any;
}

export const deriveRawToinvoicePayment = (raw) => {
  return {
    id: raw.id,
    invoiceId: raw.invoiceId,
    invoiceNumber: raw.invoiceNumber,
    storeId: raw.storeId,
    paymentGroupId: raw.paymentGroupId,
    fromName: raw.fromName,
    type: raw.type || 'OTHER',
    originalAmount: raw.originalAmount,
    originalBalance: raw.originalBalance,
    amount: raw.amount,
    memo: raw.memo,
    rejectedAt: raw.rejectedAt,
    bankId: raw.bankId || 0,
    ...(raw.bank && { bank: raw.bank }),
    paymentDate: raw.paymentDate,
    settings: {
      ...raw.settings,
    },
  };
};

export const initialInvoicePayment: InvoicePaymentModel = {
  id: 0,
  invoiceId: 0,
  storeId: 0,
  paymentGroupId: 0,
  fromName: '',
  memo: '',
  type: CASH,
  amount: 0,
  originalAmount: 0,
  originalBalance: 0,
  bankId: 0,
  paymentDate: null,
};
